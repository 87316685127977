/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react'
import {
  IonGrid, IonRow, IonCol, IonRange, IonRadioGroup, IonRadio,
  IonLabel, IonItem, IonButton, useIonAlert,
} from '@ionic/react'
import { Icon } from '@iconify/react'
import { Dropdown } from 'react-bootstrap'
import moment from 'moment'
import { useAnalystAppData } from '../../hooks/analyst/useAnalystData'
import { SelectView, DateSliderContainer, SelectYear } from '../maps/StyledContainers'
import {
  incidentIndicator, updateCountyColors,
} from '../maps/apps/AppMap'
import { SherpaButton } from '../GlobalContainers'
import { updateSecurityColors } from '../maps/apps/SecurityConditions'
import { incidentCategories } from '../maps/apps/incident_types.json'
import { currentlySelected } from '../consultant/ConsultantControls'
import { ConsultantFilter } from '../consultant/StyledContainers'
import IncidentsFilter from '../maps/Filters/IncidentsFilter'

/**
 *  ======================================
 *       CONTROLS FOR THE ANALYST APP
 *  ======================================
 */
const AnalystControls = () => {
  /* Data from context hook */
  const {
    view, setView, mode, setMode, drawLayers, mapRef, asFilters, incidents,
    setCounties, counties, regions, setRegions, securityIncidents,
    timeFilter, setTimeFilter, scYearOptions, setShowMultiAS,
    countryList, setCountyList, setIncidentList, selected,
  } = useAnalystAppData()
  const [incidentFilters, setIncidentFilters] = useState({
    'Violent Conflict': true, 'Violent Crime': true, Criminality: true, 'Social Unrest': true, Travel: true, other: true,
  })
  const [scSelection, setScSelection] = useState('all')
  const [numberDays, setNumberDays] = useState(moment().month() * 30)
  const [scTime, setScTime] = useState({ year: moment().year(), month: moment().month() + 1, monthNumber: moment().month() * 30 })
  const [securityConditionTime, setSecurityConditionTime] = useState(moment().format())
  const [showDropdown, setShowDropdown] = useState(false)
  const [showCountries, setShowCountries] = useState(false)
  const [crowdFilter, setCrowdFilter] = useState('all')
  const [showRegions, setShowRegions] = useState(false)
  const [selectedCountry, setSelectedCountry] = useState('All')
  const [selectedRegion, setSelectedRegion] = useState('All')

  const [ionAlert] = useIonAlert()

  /* Security Conditions time Filters */
  const views = ['Alert States', 'Security Conditions', 'Incidents']
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec']

  const filterIncidents = (filters, time) => {
    const dbIncidents = incidents
    let filteredIncidents = dbIncidents
    if (time !== 'All Time') {
      filteredIncidents = filteredIncidents.filter(({ reported }) => moment(reported).isSameOrAfter(moment().subtract(time, 'days').format()))
    }
    /* Incidents to show in the map */
    const selectedFilters = Object.entries(filters).reduce((memo, [k, v]) => (v ? [...memo, k] : memo), [])
    const filterdIncidentCategories = Object.entries(incidentCategories).reduce((m, [k, v]) => (selectedFilters.includes(k) ? [...m, ...v] : m), [])
    return filteredIncidents.filter((i) => (filterdIncidentCategories.includes(i.incident_type)))
  }

  const updateCounties = (geojson) => {
    const visibleCounties = geojson.features.filter(({ properties }) => properties.Alert_color !== 'rgba(255,255,255, 0)').map(({ properties }) => properties)
    setCountyList(visibleCounties)
    setCounties(geojson)
  }

  const getScFilters = (selection = scSelection) => {
    let filters = {}
    /* Turn selection into filters object */
    if (selection === 'all') {
      filters = Object.fromEntries(Object.keys(incidentCategories).map((category) => [category, true]))
    } else {
      filters = Object.fromEntries(Object.keys(incidentCategories).map((category) => [category, false]))
      filters[selection] = true
    }

    return filters
  }

  const updateSecurityConditions = (selection) => {
    setScSelection(selection)
    /* Get filter object from selection */
    const filters = getScFilters(selection)
    /* If security conditions then update map */
    updateSecurityColors(mapRef, setCounties, counties, filters, securityIncidents, securityConditionTime, regions, setRegions)
  }

  const showIncidents = (show, filters, newTime) => {
    if (newTime) { setTimeFilter(newTime) }
    if (filters) { setIncidentFilters(filters) }
    if (show) {
      const filteredIncidents = filterIncidents(filters, newTime || timeFilter)
      setIncidentList(filteredIncidents)
      mapRef.current.getSource('incidents').setData({
        type: 'FeatureCollection',
        features: filteredIncidents.map((val) => ({
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: [
              val.longitude, val.latitude,
            ],
          },
          properties: {
            incident_type: val.incident_type,
            fatalities: val.fatalities,
            date: val.reported || val.date,
            description: val.description,
            popupdata: incidentIndicator(val),
            id: val.id,
          },
        })),
      })
    } else {
      mapRef.current.getSource('incidents').setData({
        type: 'FeatureCollection',
        features: [],
      })
    }
  }

  const handleModeChange = (e) => {
    setMode(e.target.value)

    if (e.target.value !== 'Alert States' && mapRef.current.getLayer('counties-layer')) {
      mapRef.current.removeLayer('counties-layer')
      mapRef.current.removeLayer('regions-layer')
    }

    if (e.target.value !== 'Incidents') {
      showIncidents(false)
    }

    if (e.target.value !== 'Security Conditions' && mapRef.current.getLayer('security-conditions')) {
      mapRef.current.removeLayer('security-conditions')
      mapRef.current.removeLayer('security-conditions-regions')
    }

    if (e.target.value === 'Alert States') {
      updateCountyColors(asFilters, updateCounties, counties, mapRef, drawLayers, regions, setRegions, selectedCountry, selectedRegion, crowdFilter)
    }

    if (e.target.value === 'Incidents') {
      showIncidents(true, incidentFilters)
    }

    if (e.target.value === 'Security Conditions') {
      setView('Map')
      const filters = getScFilters(scSelection)
      updateSecurityColors(mapRef, setCounties, counties, filters, securityIncidents, securityConditionTime, regions, setRegions)
    }
  }

  const updateScYear = (e) => {
    const year = e.target.value
    const today = moment()
    let { month } = scTime

    if (year < today.year()) {
      setNumberDays(11 * 30)
    } else {
      setNumberDays(today.month() * 30)

      if (scTime.month > today.month() + 1) {
        month = today.month() + 1
      }
    }

    let day
    // eslint-disable-next-line eqeqeq
    if (today.month() + 1 === month && today.year() == year) {
      day = moment().date()
    } else {
      day = moment({ year, month: month - 1 }).daysInMonth()
    }
    const newDate = moment(`${year}/${month}/${day} 23:59`, 'YYYY/M/DD hh:mm').format('YYYY-MM-DD hh:mm')

    setScTime({ ...scTime, year, month })
    setSecurityConditionTime(newDate)
    const filters = getScFilters(scSelection)
    updateSecurityColors(mapRef, setCounties, counties, filters, securityIncidents, newDate, regions, setRegions)
  }

  const updateScMonth = (e) => {
    const month = (e.detail.value / 30) + 1
    const today = moment()

    let day
    if (today.month() + 1 === month && today.year() === scTime.year) {
      day = moment().date()
    } else {
      day = moment({ year: scTime.year, month: month - 1 }).daysInMonth()
    }
    const newDate = moment(`${scTime.year}/${month}/${day} 23:59`, 'YYYY/M/DD hh:mm').format('YYYY-MM-DD hh:mm')

    setScTime({ ...scTime, month, monthNumber: (month - 1) * 30 })
    setSecurityConditionTime(newDate)
    const filters = getScFilters(scSelection)
    updateSecurityColors(mapRef, setCounties, counties, filters, securityIncidents, newDate, regions, setRegions)
  }

  const filterCountries = (country) => {
    updateCountyColors(asFilters, updateCounties, counties, mapRef, drawLayers, regions, setRegions, country, null, crowdFilter)
    setSelectedRegion('All')
    setSelectedCountry(country)
  }

  const filterRegions = (region) => {
    updateCountyColors(asFilters, updateCounties, counties, mapRef, drawLayers, regions, setRegions, selectedCountry, region, crowdFilter)
    setSelectedRegion(region)
  }

  const filterCrowd = (e) => {
    const selection = e.target.value
    setCrowdFilter(selection)
    updateCountyColors(asFilters, setCounties, counties, mapRef, drawLayers, regions, setRegions, selectedCountry, selectedRegion, selection)
  }

  const handleTimeChange = (e) => {
    showIncidents(true, incidentFilters, e.target.value)
  }

  const onToggleHandler = (isOpen, metadata, update) => {
    if (metadata.source !== 'select') {
      update(isOpen)
    }
  }
  return (
    <>
      <IonGrid
        style={{
          backgroundColor: '#FFFFFF',
          padding: '10px 30px',
          marginBottom: '20px',
          boxShadow: '0px 4px 4px 0px #00000040',
          position: 'relative',
          borderRadius: '5px',
          flex: '0 1 auto',
          width: '100%',
        }}
      >
        <IonRow className='justify-content-between'>
          <SelectView
            value={mode}
            onChange={handleModeChange}
          >
            {
              views.map((val) => (
                <option key={val} value={val}>{val}</option>
              ))
            }
          </SelectView>
          <IonRow />
          {
            mode === 'Security Conditions' && (
              <ConsultantFilter>
                <h4>Category:</h4>
                <Dropdown style={{ margin: '0 5px' }} align='end' show={showDropdown} onToggle={(isOpen, metadata) => onToggleHandler(isOpen, metadata, setShowDropdown)}>
                  <Dropdown.Toggle className='map-button remove-arrow'>{ (scSelection === 'all') ? 'Show All' : scSelection }</Dropdown.Toggle>
                  <Dropdown.Menu>
                    <IonRadioGroup value={scSelection} onIonChange={(e) => updateSecurityConditions(e.detail.value)}>
                      <IonItem style={{ '--background': 'white' }}>
                        <IonLabel>Show All</IonLabel>
                        <IonRadio slot='end' value='all' />
                      </IonItem>
                      {
                          Object.keys(incidentFilters).map((category) => (
                            <IonItem key={category} style={{ '--background': 'white' }}>
                              <IonLabel>{ category }</IonLabel>
                              <IonRadio slot='end' value={category} />
                            </IonItem>
                          ))
                        }
                    </IonRadioGroup>
                  </Dropdown.Menu>
                </Dropdown>
              </ConsultantFilter>
            )
          }
          {
            mode === 'Alert States' && view === 'List' && (
              <IonButton
                onClick={() => {
                  if (selected.counties.length > 0) {
                    setShowMultiAS(true)
                  } else {
                    ionAlert({
                      header: 'Error - No Counties selected.',
                      message: 'You need to pick at least one county.',
                      buttons: [
                        { text: 'Ok' },
                      ],
                    })
                  }
                }}
                style={{ '--background': '#326771', color: 'white' }}
              >Edit
              </IonButton>
            )
          }
        </IonRow>
        {
        mode !== 'Security Conditions' && (
          <IonRow className='ion-justify-content-between ion-align-items-center'>
            <SherpaButton
              style={{
                fontSize: '1.3rem',
                alignItems: 'center',
                display: 'flex',
              }}
              onClick={() => setView((view === 'List') ? 'Map' : 'List')}
            >
              {
                (view === 'Map') ? (
                  <><Icon style={{ margin: '0 5px' }} icon='ant-design:unordered-list-outlined' />List View</>
                ) : (
                  <><Icon style={{ margin: '0 5px' }} icon='carbon:map' />Map View</>
                )
              }
            </SherpaButton>
              {
                (mode === 'Incidents') ? (
                  <>
                    <ConsultantFilter>
                      <h4>Incident Type: </h4>
                      <Dropdown>
                        <Dropdown.Toggle>{ currentlySelected(incidentFilters) }</Dropdown.Toggle>
                        <Dropdown.Menu
                          style={{ maxHeight: '200px', overflowY: 'auto' }}
                        >
                          <IncidentsFilter
                            update={(filters) => showIncidents(true, filters)}
                            incidentFilters={incidentFilters}
                            toggle={(Object.values(incidentFilters).findIndex((param) => !param) < 0)}
                          />
                        </Dropdown.Menu>
                      </Dropdown>
                    </ConsultantFilter>
                    <ConsultantFilter>
                      <h4>Time: </h4>
                      <SelectView value={timeFilter} onChange={handleTimeChange}>
                        <option value={7}>Last Week</option>
                        <option value={30}>Last month</option>
                        <option value={183}>Last 6 months</option>
                        <option value={365}>Last year</option>
                        <option value='All Time'>All Time</option>
                      </SelectView>
                    </ConsultantFilter>
                  </>
                ) : (
                  <>
                    <ConsultantFilter>
                      <h4>Country:</h4>
                      <Dropdown style={{ margin: '0 5px' }} align='end' show={showCountries} onToggle={(isOpen, metadata) => onToggleHandler(isOpen, metadata, setShowCountries)}>
                        <Dropdown.Toggle className='map-button remove-arrow'>{ (selectedCountry === 'All') ? 'Show All' : selectedCountry }</Dropdown.Toggle>
                        <Dropdown.Menu>
                          <IonRadioGroup value={selectedCountry} onIonChange={(e) => filterCountries(e.detail.value)}>
                            <IonItem style={{ '--background': 'white' }}>
                              <IonLabel>Show All</IonLabel>
                              <IonRadio slot='end' value='All' />
                            </IonItem>
                            {
                              Object.keys(countryList).map((category) => (
                                <IonItem key={category} style={{ '--background': 'white' }}>
                                  <IonLabel>{ category }</IonLabel>
                                  <IonRadio slot='end' value={category} />
                                </IonItem>
                              ))
                            }
                          </IonRadioGroup>
                        </Dropdown.Menu>
                      </Dropdown>
                    </ConsultantFilter>
                    <ConsultantFilter>
                      <h4>Region:</h4>
                      {
                        selectedCountry !== 'All' ? (
                          <Dropdown style={{ margin: '0 5px' }} align='end' show={showRegions} onToggle={(isOpen, metadata) => onToggleHandler(isOpen, metadata, setShowRegions)}>
                            <Dropdown.Toggle className='map-button remove-arrow'>
                              { (selectedRegion === 'All') ? 'Show All' : countryList[selectedCountry].regions.find(({ id }) => id === selectedRegion).name }
                            </Dropdown.Toggle>
                            <Dropdown.Menu style={{ maxHeight: '300px', overflowY: 'auto' }}>
                              <IonRadioGroup value={selectedRegion} onIonChange={(e) => filterRegions(e.detail.value)}>
                                <IonItem style={{ '--background': 'white' }}>
                                  <IonLabel>Show All</IonLabel>
                                  <IonRadio slot='end' value='All' />
                                </IonItem>
                                {
                                  countryList[selectedCountry].regions.map(({ name, id }) => (
                                    <IonItem key={id} style={{ '--background': 'white' }}>
                                      <IonLabel>{ name }</IonLabel>
                                      <IonRadio slot='end' value={id} />
                                    </IonItem>
                                  ))
                                }
                              </IonRadioGroup>
                            </Dropdown.Menu>
                          </Dropdown>
                        ) : (
                          <p style={{ margin: 0 }}>No Country Selected</p>
                        )
                      }
                    </ConsultantFilter>
                    <ConsultantFilter>
                      <h4>Crowd Insights</h4>
                      <select onChange={filterCrowd} value={crowdFilter}>
                        <option value='all'>Show All</option>
                        <option value='higher'>Higher</option>
                        <option value='lower'>Lower</option>
                        <option value='unchanged'>Unchanged</option>
                      </select>
                    </ConsultantFilter>
                  </>
                )
              }
          </IonRow>
        )
      }
      </IonGrid>
      {
        mode === 'Security Conditions' && (
          <DateSliderContainer style={{ padding: '0' }} className='hide-insights-widget'>
            <IonRow className='ion-align-items-center ion-justify-content-between' style={{ borderRadius: '2.5px', background: 'white', boxShadow: '0px 4px 4px rgb(0 0 0 / 25%)' }}>
              <IonCol size={2}>
                <IonRow className='ion-justify-content-center'>
                  <SelectYear onChange={updateScYear} value={scTime.year}>
                    {
                      scYearOptions.map((year) => (
                        <option key={year} value={year}>{ year }</option>
                      ))
                    }
                  </SelectYear>
                </IonRow>
              </IonCol>
              <IonCol size={8}>
                <IonRange
                  min={0}
                  max={numberDays}
                  step={30}
                  snaps
                  value={scTime.monthNumber}
                  onIonChange={updateScMonth}
                  style={{ padding: '0 10px' }}
                />
                <IonRow className='ion-justify-content-between'>
                  {
                    [...Array((numberDays / 30) + 1)].map((val, index) => (
                      <p key={index} style={{ margin: '0' }}>{ months[index] }</p>
                    ))
                  }
                </IonRow>
              </IonCol>
              <IonCol size={2}>
                <IonRow className='ion-justify-content-center'>
                  <SelectYear onChange={updateScYear} value={scTime.year}>
                    {
                      scYearOptions.map((year) => (
                        <option key={year} value={year}>{ year }</option>
                      ))
                    }
                  </SelectYear>
                </IonRow>
              </IonCol>
            </IonRow>
          </DateSliderContainer>
        )
      }
    </>
  )
}

export default AnalystControls
