import * as React from 'react'
import * as mapboxgl from 'mapbox-gl'
import * as turf from '@turf/turf'
import {
  defaultFilters, filtersReducer, FilterActions, InsightFiltersActionKind,
} from './helpers/FilterReducers'
import {
  AlertStateFilters, AsFilterUpdate, defaultASFilters, InsightViews,
} from './helpers/StateReducers'
import { InsightsInterface } from './types/HookInterface'
import { CountyProperties } from '../../components/types/GeoJSONTypes'
import {
  CountryAreas, Incident, IncidentTimeFilter, SafetyUser,
} from '../../components/types/OptimizedMaps'
import {
  assetsToGeoJSON,
  drawIncidents,
  pulsepointsToGeoJSON,
} from './helpers/MapUtils'
import { Asset } from '../../components/riskregister/types/Assets'
import { renderAlertStates } from '../../components/utils/MapboxRendering'
import { fetchIncidentsReducer } from '../../components/utils/Reducers'
import { filterIncidents } from '../../components/utils/MapboxUtils'
import { User } from '../../components/types/GlobalTypes'

const defaultAssetGeoJSON : turf.FeatureCollection<turf.Point, Asset> = { type: 'FeatureCollection', features: [] }
const defaultRoutesGeoJSON : turf.FeatureCollection<turf.LineString> = { type: 'FeatureCollection', features: [] }
const defaultPulsepointGeoJSON : turf.FeatureCollection<turf.LineString> = { type: 'FeatureCollection', features: [] }

/**
 *  ================================================
 *        HANDLE STATES FOR INSIGHTS APP
 *  ================================================
 *
 * Implements the business logic and react states for
 * the insights  app.
 */

export const insightsContext = React.createContext<InsightsInterface>(null)
export const useInsightsData = () => React.useContext(insightsContext)

const useProvideInsightsData = () : InsightsInterface => {
  /* Incident filters */
  const [incidentFilters, dispatchIncidentFilters] = React.useReducer(filtersReducer, { ...defaultFilters })
  const [timeFilter, setTimeFilter] = React.useState<IncidentTimeFilter>(IncidentTimeFilter.LAST_WEEK)

  /* Assets and routes toggles */
  const [showAssets, setShowAssets] = React.useState<boolean>(false)
  const [showRoutes, setShowRoutes] = React.useState<boolean>(false)

  /* Mobile version of the app */
  const [showMobileLV, setShowMobileLV] = React.useState(null)

  /* Alert state filters -> (Toggle on of alternative data) */
  const [asFilters, setAsFilters] = React.useState<AlertStateFilters>({ ...defaultASFilters })
  const [popup, setPopup] = React.useState<mapboxgl.Popup>(null)
  const [view, setView] = React.useState<InsightViews>(InsightViews.INCIDENTS)

  /* Data for map layers */
  const [areas, setAreas] = React.useState<CountryAreas[]>([])
  const [incidents, setIncidents] = React.useState<Incident[]>([])
  const mapRef = React.useRef<mapboxgl.Map>(null)
  const [assets, setAssets] = React.useState<turf.FeatureCollection<turf.Point, Asset>>({ ...defaultAssetGeoJSON })
  const [allAssets, setAllAssets] = React.useState<any>()
  const [routes, setRoutes] = React.useState<turf.FeatureCollection<turf.LineString>>({ ...defaultRoutesGeoJSON })
  const [selectedAsset, setSelectedAsset] = React.useState<any>()
  const [users, setUsers] = React.useState<User[]>()
  const [showSelectedAsset, setShowSelectedAsset] = React.useState<boolean>(false)

  const [incidentFetch, dispatchIncidentFetch] = React.useReducer(fetchIncidentsReducer, { loading: true, oldestFetch: IncidentTimeFilter.LAST_MONTH, serverTime: '' })
  const [routePins, setRoutePins] = React.useState<turf.FeatureCollection<turf.Point>>({ ...defaultAssetGeoJSON })

  /* Display  Forms */
  const [showInsightF, setShowInsightF] = React.useState<boolean>(false)
  const [showIncidentF, setShowIncidentF] = React.useState<boolean>(false)
  const [showSafetyChecks, setShowSafetyChecks] = React.useState<boolean>(false)
  const [showFieldSitrepRequest, setFieldSitrepRequest] = React.useState<boolean>(false)
  const [loading, setLoading] = React.useState<boolean>(true)
  const [submittedMessage, setSubmittedMessage] = React.useState<string>(null)

  /* County situation report */
  const [focusedCounty, setFocusedCounty] = React.useState<CountyProperties>(null)
  const [mobile, setMobile] = React.useState<boolean>(false)

  /* Confirm states have been set */
  const [loaded, setLoaded] = React.useState<boolean>(false)

  const [showMobileFilters, setShowMobileFilters] = React.useState<boolean>(false)

  /* Workspace users for safety checks functionality */
  const [workspaceUsers, setWorkspaceUsers] = React.useState<SafetyUser[]>([])
  const [mapSafeties, setMapSafeties] = React.useState<turf.FeatureCollection<turf.Point>>({
    type: 'FeatureCollection',
    features: [],
  })

  const [pulsepoints, setPulsepoints] = React.useState<any[]>([])

  const updateIncidents = (filterAction: FilterActions | null, newTimeFilter: IncidentTimeFilter, incidentList: Incident[]) => {
    /* get new filters */
    if (filterAction) {
      dispatchIncidentFilters(filterAction)
    }
    const newFilters = (filterAction) ? filtersReducer(incidentFilters, filterAction) : incidentFilters

    if (newTimeFilter) { setTimeFilter(newTimeFilter) }

    const filtered = filterIncidents(newFilters, incidentList || incidents, newTimeFilter || timeFilter)
    drawIncidents(mapRef, filtered)
  }

  /* Fired everytime a view/mode is selected */
  const handleViewChange = (newView: InsightViews) : void => {
    /* Set new view */
    setView(newView)
    if (popup) popup.remove()

    /* Clean previous state */
    if (view === InsightViews.ALERT_STATES) {
      renderAlertStates(mapRef, areas, false)
    }

    if (view === InsightViews.INCIDENTS && !asFilters.showIncidents) {
      (mapRef.current.getSource('incidents') as mapboxgl.GeoJSONSource).setData({
        type: 'FeatureCollection',
        features: [],
      })
    }

    if (newView !== InsightViews.INCIDENTS && asFilters.showIncidents) {
      updateIncidents({ type: InsightFiltersActionKind.TOGGLE_ALL_TRUE }, null, null)
    }

    if (view === InsightViews.TEAM_SAFETY) {
      (mapRef.current.getSource('safety-checks') as mapboxgl.GeoJSONSource).setData({
        type: 'FeatureCollection',
        features: [],
      })
    }

    /* Render new states */
    if (newView === InsightViews.ALERT_STATES) {
      renderAlertStates(mapRef, areas, true)
    }

    if (newView === InsightViews.INCIDENTS) {
      const filtered = filterIncidents(incidentFilters, incidents, timeFilter)
      drawIncidents(mapRef, filtered)
    } else if (newView === InsightViews.TEAM_SAFETY) {
      (mapRef.current.getSource('safety-checks') as mapboxgl.GeoJSONSource).setData(mapSafeties)
    }
  }

  const updateAsFilters = (option : AsFilterUpdate) => {
    /* Updated filter object */
    const updated = { ...asFilters, [option]: !asFilters[option] }
    setAsFilters(updated)
    console.log('asfilters', updated)

    if (option === AsFilterUpdate.SHOW_INCIDENTS) {
      const filtered = filterIncidents(defaultFilters, incidents, timeFilter)
      drawIncidents(mapRef, (updated[option]) ? filtered : [])
      return
    }

    if (option === AsFilterUpdate.SHOW_ASSETS) {
      if (mapRef.current) {
        // Type assertions and null checks for sources
        const assetsSource = mapRef.current.getSource('assets-source') as mapboxgl.GeoJSONSource | undefined
        const linesSource = mapRef.current.getSource('assets-lines') as mapboxgl.GeoJSONSource | undefined
        const polygonsSource = mapRef.current.getSource('assets-polygons') as mapboxgl.GeoJSONSource | undefined
        // Ensure sources are not undefined before calling setData
        if (assetsSource) {
          assetsSource.setData(updated[option] ? assets : defaultAssetGeoJSON)
        }
        const { featureLines, featurePolygons } = assetsToGeoJSON(allAssets)
        if (linesSource) {
          linesSource.setData(updated[option] ? featureLines : defaultAssetGeoJSON)
        }
        if (polygonsSource) {
          polygonsSource.setData(updated[option] ? featurePolygons : defaultAssetGeoJSON)
        }
      }
      return
    }

    if (option === AsFilterUpdate.SHOW_PULSEPOINTS) {
      if (mapRef.current) {
        const pulsepointsSource = mapRef.current.getSource('pulsepoints-source') as mapboxgl.GeoJSONSource | undefined
        if (pulsepointsSource) {
          pulsepointsSource.setData(updated[option] ? pulsepointsToGeoJSON(pulsepoints) : defaultPulsepointGeoJSON)
        }
      }
    }

    if (option === AsFilterUpdate.SHOW_ROUTES) {
      (mapRef.current.getSource('routes-source') as mapboxgl.GeoJSONSource).setData((updated[option]) ? routes : { ...defaultRoutesGeoJSON });
      (mapRef.current.getSource('route-symbols') as mapboxgl.GeoJSONSource).setData((updated[option]) ? routePins : { ...defaultRoutesGeoJSON })
    }
  }

  const pushNewIncident = (newIncident: Incident) : void => {
    const updated = [...incidents, newIncident]
    const filtered = filterIncidents(incidentFilters, updated, timeFilter)
    drawIncidents(mapRef, filtered)
    setIncidents(updated)
  }

  return {
    incidentFilters,
    dispatchIncidentFilters,
    timeFilter,
    setTimeFilter,
    showAssets,
    setShowAssets,
    showRoutes,
    setShowRoutes,
    showMobileLV,
    setShowMobileLV,
    asFilters,
    setAsFilters,
    popup,
    setPopup,
    view,
    setView,
    mapRef,
    showInsightF,
    setShowInsightF,
    incidents,
    setIncidents,
    showIncidentF,
    setShowIncidentF,
    setAreas,
    areas,
    loading,
    setLoading,
    handleViewChange,
    updateIncidents,
    updateAsFilters,
    setAssets,
    setAllAssets,
    allAssets,
    focusedCounty,
    setFocusedCounty,
    routes,
    setRoutes,
    setWorkspaceUsers,
    workspaceUsers,
    showSafetyChecks,
    setShowSafetyChecks,
    submittedMessage,
    setSubmittedMessage,
    setMapSafeties,
    mobile,
    setMobile,
    pushNewIncident,
    showMobileFilters,
    setShowMobileFilters,
    loaded,
    setLoaded,
    setRoutePins,
    routePins,
    incidentFetch,
    dispatchIncidentFetch,
    showFieldSitrepRequest,
    setFieldSitrepRequest,
    selectedAsset,
    setSelectedAsset,
    showSelectedAsset,
    setShowSelectedAsset,
    setUsers,
    users,
    pulsepoints,
    setPulsepoints,
  }
}

export const ProvideInsightsData = ({ children }) => {
  const data = useProvideInsightsData()
  return (
    <insightsContext.Provider value={data}>
      {
        children
      }
    </insightsContext.Provider>
  )
}
