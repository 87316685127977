/* eslint-disable import/no-unresolved */
import React, { useEffect, useRef, useState } from 'react'
import {
  IonRow, IonButton, IonPopover, IonList, IonItem, useIonAlert,
} from '@ionic/react'
import { Icon } from '@iconify/react'
// eslint-disable-next-line import/no-webpack-loader-syntax
import * as mapboxgl from '!mapbox-gl'
import Map from '../Map'
import MapFormInsights from '../forms/MapFormInsights'

import InsightsForm from '../forms/InsightsForm'
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css'

import testHook from '../../../hooks/testHooks'
import { useWorkspace } from '../../../hooks/useWorkspace'
import { useGeolocation } from '../../../hooks/useGeolocation'
import { useAuth } from '../../../hooks/useAuth'
import AssetProfileInsights from '../../insights/popups/AssetProfileInsights'
import Incident from '../features/Incident2'

import axios from '../../../utils/axios'

import {
  incidentIndicator, mapPopup,
} from './AppMap'

import { ControlContainer, PopoverIcon } from '../StyledContainers'

import { useInsightsData } from '../../../hooks/insights/useInsightsData'
import {
  createAssetShapeLayers,
  createAssetsLayer,
  createPulsePointLayer,
  createRoutesLayer,
  createRouteSymbolLayer,
  createSafetyChecksLayer,
  displayIncidentPopup,
  displaySafetyPopup,
} from '../../../hooks/insights/helpers/MapUtils'
import MapLegend from '../../insights/popovers/MapLegend'
import {
  processRoutes,
} from '../../../hooks/insights/helpers/Utils'

import { useSituationReport } from '../../../hooks/insights/useSituationReport'
import MapPopup from '../../insights/safety-checks/MapPopup'
import MobileInsightControls from '../../mobile/insights/MobileInsightControls'
import MobileFiltersPopup from '../../mobile/insights/MobileFiltersPopup'
import {
  assetsToGeoJSON,
  findCountyInAreas, findRegionsInAreas, getFlatRegionsAndCounties, getMapboxLayers, getMapSafeties, processAreas,
} from '../../utils/MapboxUtils'
import {
  createIncidentsLayer, createSources, displayAlertStatePopup,
} from '../../utils/MapboxRendering'

/**
 *  =============================
 *         Journey App map
 *  =============================
 */

const InsightsMap = () => {
  /* Form type */
  const [showPopover, setShowPopover] = useState({ show: false, event: undefined })
  const [showSafetyPopover, setShowSafetyPopover] = useState({ show: false, event: undefined })
  const [showAddButton, setShowAddButton] = useState(true)
  const [incidentMarker, setIncidentMarker] = useState(null)
  const [presentAlert] = useIonAlert()

  const {
    showInsightF, setShowInsightF, setPopup, setIncidents, updateIncidents,
    showIncidentF, setShowIncidentF, handleViewChange, areas, setPulsepoints,
    setLoading, view, mapRef, setAssets, timeFilter, dispatchIncidentFetch, setShowSelectedAsset,
    setFocusedCounty, setRoutes, setWorkspaceUsers, setMapSafeties, setMobile, setSelectedAsset, setUsers,
    showMobileFilters, loaded, setLoaded, setRoutePins, setAreas, setAllAssets, allAssets, showSelectedAsset,
  } = useInsightsData()

  const [, setIsShowing,, setCountyId] = useSituationReport()

  const apiHook = testHook()
  const { workspace } = useWorkspace()
  const geoData = useGeolocation()
  const { user } = useAuth()

  // url variable
  const queryParams = new URLSearchParams(window.location.search)
  const incidentId = queryParams.get('incident')
  const showInsights = queryParams.get('fsitrep')

  /* Show popups when alert state view */
  const handleClick = (e) => {
    if (showIncidentF) { return }

    const displayPopup = (data) => {
      // Copy coordinates array.
      const coordinates = e.lngLat
      const description = e.features[0].properties[data]

      // Ensure that if the map is zoomed out such that multiple
      // copies of the feature are visible, the popup appears
      // over the copy being pointed to.
      while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
        coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360
      }
      setPopup(new mapboxgl.Popup()
        .setLngLat(coordinates)
        .setHTML(description)
        .addTo(mapRef.current))
    }
    displayPopup('description')
  }

  const handleClickRef = useRef(handleClick)
  handleClickRef.current = handleClick

  const incidentsPopup = (e) => {
    setPopup(mapPopup(e, mapRef))
  }

  const incidentsPopupRef = useRef(incidentsPopup)
  incidentsPopupRef.current = incidentsPopup

  const handleCountiesClick = (e) => {
    if (showIncidentF) {
      return
    }
    const feature = e.features[0]

    /* Skip if no feature */
    if (!feature) { return }
    const county = findCountyInAreas(areas, feature.properties.shapeID)
    if (!county) { return }

    const callback = () => {
      setFocusedCounty(county)
      setCountyId(county.id)
      setIsShowing(true)
    }
    displayAlertStatePopup(mapRef, county, e.lngLat, () => callback())
  }

  const handleCountiesClickRef = useRef(handleCountiesClick)
  handleCountiesClickRef.current = handleCountiesClick

  const handleRegionsClick = (e) => {
    if (showIncidentF) {
      return
    }
    const feature = e.features[0]

    /* Skip if no feature */
    if (!feature) { return }
    const region = findRegionsInAreas(areas, feature.properties.shapeID)
    if (!region) { return }

    displayAlertStatePopup(mapRef, region, e.lngLat)
  }

  const handleRegionsClickRef = useRef(handleRegionsClick)
  handleRegionsClickRef.current = handleRegionsClick

  const handleAssetPointClick = (event) => {
    if (event.features.length === 0) { return }
    const { properties } = event.features[0]
    const selected = allAssets.find(({ id }) => properties.id === id)
    console.log(selected)
    setSelectedAsset(selected)
    setShowSelectedAsset(true)

    // eslint-disable-next-line no-useless-return
    if (!selected) { return }
  }

  const handleAssetPointClickRef = useRef(null)
  handleAssetPointClickRef.current = handleAssetPointClick

  const setUpListeners = () => {
    mapRef.current.on('load', async () => {
      const [securityData,
        safetyChecks,
        countyData, assetsCounty, paths, assets, usersData, pulsepointData] = await Promise.all([
        axios.post('/api/v2/incident/incidents', { domain_id: workspace.id, time_from: '1month', time_to: 'today' }),
        apiHook.getAllSafeties(workspace.id),
        axios.post('/api/v2/county/counties', { domain_id: workspace.id }),
        apiHook.getAssetsByCounty({ domain_id: workspace.id }),
        apiHook.getPaths(workspace.id),
        apiHook.getAssets(workspace.id),
        apiHook.getUsers(workspace.id),
        axios.post('api/v2/pulsepoint/getPulsepoints', { domain_id: workspace?.id }),
      ])
      const { counties } = countyData.data
      const formattedAreas = processAreas(counties)
      setUsers(usersData.users)
      setPulsepoints(pulsepointData.data.pulsepoints)

      /* Store state and render them counties */
      setAreas(formattedAreas)
      createSources(mapRef, formattedAreas)
      createAssetsLayer(mapRef)
      createRouteSymbolLayer(mapRef)
      createRoutesLayer(mapRef)
      createSafetyChecksLayer(mapRef)
      createPulsePointLayer(mapRef)
      createAssetShapeLayers(mapRef, assets.assets)

      /* Create incident's layer and draw them */
      const { incidents } = securityData.data
      createIncidentsLayer(mapRef)
      setIncidents(incidents)
      updateIncidents(null, timeFilter, incidents)

      dispatchIncidentFetch({ type: 'RECEIVED', payload: { serverTime: securityData.data.server_from } })

      const safetiesMap = getMapSafeties(safetyChecks.safeties)
      setMapSafeties(safetiesMap)
      setWorkspaceUsers(safetyChecks.safeties)

      const routesGeojson = processRoutes(paths.routes)
      setAssets(assetsToGeoJSON(assetsCounty.assets))
      setAllAssets(assets.assets)
      setRoutes(routesGeojson)

      const routeSymbols = routesGeojson.features.map(({ properties, geometry }) => ({
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: geometry.coordinates[0],
        },
        properties: {
          icon: properties.type,
        },
      }))

      setRoutePins({
        type: 'FeatureCollection',
        features: routeSymbols,
      })

      /* Default view is incidents so draw them all */
      setLoading(false)

      if (incidentId) {
        const incident = incidents.find((val) => val.id === Number(incidentId))
        // popup for directed incident
        if (incident) {
          handleViewChange('Incidents', incidents)
          setPopup(new mapboxgl.Popup()
            .setLngLat({ lat: incident.latitude, lng: incident.longitude })
            .setHTML(incidentIndicator(incident))
            .addTo(mapRef.current))
          mapRef.current.easeTo({ center: { lat: incident.latitude, lng: incident.longitude }, zoom: 12 })
        }
      }

      if (showInsights === 'true') {
        setShowInsightF(true)
      }

      const queryParam = new URLSearchParams(window.location.search)
      const countyUrlId = queryParam.get('sc_county')
      if (countyUrlId) {
        setCountyId(countyUrlId)
        setIsShowing(true)
      }
      mapRef.current.resize()
      setLoaded(true)

      mapRef.current.on('click', 'incident-markers', (e) => {
        displayIncidentPopup(e, mapRef)
        e.originalEvent.preventDefault()
      })

      mapRef.current.on('click', 'assets-polygon-layer', (e) => {
        if (!e.originalEvent.defaultPrevented) {
          // displayAssetPopup(e, mapRef)
          handleAssetPointClickRef.current(e)
          console.log('clicked assets-polygons')
          e.originalEvent.preventDefault() // Prevent default behavior
          e.originalEvent.stopPropagation() // Stop propagation to lower layers
        }
      })

      mapRef.current.on('click', 'asset-markers', (e) => {
        if (!e.originalEvent.defaultPrevented) {
          handleAssetPointClickRef.current(e)
          e.originalEvent.preventDefault()
        }
      })

      mapRef.current.on('click', 'safety-check-markers', (e) => {
        if (!e.originalEvent.defaultPrevented) {
          displaySafetyPopup(e, mapRef, workspace.id)
          e.originalEvent.preventDefault()
        }
      })

      mapRef.current.on('click', 'pulsepoint-markers', (e) => {
        if (!e.originalEvent.defaultPrevented) {
          console.log('pulsepoint clicked')
          e.originalEvent.preventDefault()
        }
      })

      const { regionLayers, countyLayers } = getMapboxLayers(formattedAreas)

      /* Add on click events for countries and regions alert state layers */
      mapRef.current.on('click', countyLayers, (e) => {
        if (!e.originalEvent.defaultPrevented) {
          handleCountiesClickRef.current(e)
        }
      })

      mapRef.current.on('click', regionLayers, (e) => {
        if (!e.originalEvent.defaultPrevented) {
          handleRegionsClickRef.current(e)
        }
      })
    })
  }

  useEffect(() => {
    const query = new URLSearchParams(window.location.search)
    const alert = Number(query.get('alert'))
    const safety = Number(query.get('safety'))
    if (alert && loaded) {
      handleViewChange('Alert States')
      setCountyId(alert)
      setIsShowing(true)
    }
    if (safety && loaded) {
      handleViewChange('Safety Checks')
    }
  }, [loaded])

  const startMobileIncident = () => {
    setMobile(true)
    setShowIncidentF(true)
    setShowPopover({ show: false, event: undefined })
  }

  const startMobileInsight = () => {
    setShowInsightF(true)
    setShowAddButton(false)
    setShowPopover({ show: false, event: undefined })
  }

  const handlePopover = (e) => {
    if (showIncidentF) {
      if (incidentMarker) {
        incidentMarker.remove()
        setIncidentMarker(null)
      }
      setShowIncidentF(false)
    } else {
      e.persist()
      setShowPopover({ show: true, event: e })
    }
  }

  const handleSCPopover = (e) => {
    e.persist()
    setShowSafetyPopover({ show: true, event: e })
  }

  const submitSafetyCheck = (level) => {
    apiHook.submitOneSafety(user.id, geoData.geolocation.lat, geoData.geolocation.lng, level).then((data) => console.log(data))
    setShowSafetyPopover({ show: false, event: null })
  }

  const finishInsightsForm = () => {
    //
    presentAlert({
      header: 'Cancel Field Sitrep',
      subHeader: 'You have unsaved changes. Do you wish to continue?',
      buttons: [
        {
          text: 'Back',
          role: 'cancel',
          handler: () => {
          },
        },
        {
          text: 'Yes, Continue',
          role: 'confirm',
          handler: () => {
            setShowInsightF(false)
            setShowAddButton(true)
          },
        },
      ],
    })
    // setShowInsightF(false)
    // setShowAddButton(true)
  }

  useEffect(() => {
    mapRef.current.resize()
  }, [showIncidentF])

  return (
    <>
      <IonRow style={{ flex: '1 1 auto' }} className='ion-justify-content-center'>
        <Map
          mapRef={mapRef}
          listeners={setUpListeners}
          zoom={5}
          style={{ height: '100%' }}
          className='insights-map-2'
        >
          <ControlContainer className='mapkeys-container'>
            <IonRow className='ion-justify-content-between'>
              <MapLegend />
              {
                view === 'Safety Checks' && (
                  <MapPopup />
                )
              }
            </IonRow>
          </ControlContainer>
          <MobileInsightControls />
          {
            showIncidentF
            && (
              <Incident setShowAddButton={setShowAddButton} incidentMarker={incidentMarker} setIncidentMarker={setIncidentMarker} />
            )
          }
          {
            showInsightF
            && (
              <MapFormInsights className='mobile-insights-form'>
                <InsightsForm
                  onFinish={() => {
                    setShowInsightF(false)
                    setShowAddButton(true)
                  }}
                  onClose={finishInsightsForm}
                  setLoading={setLoading}
                  areas={getFlatRegionsAndCounties(areas)}
                />
              </MapFormInsights>
            )
          }
          {
            showSelectedAsset && (
              <AssetProfileInsights />
            )
          }
        </Map>
        {
          showMobileFilters && (
            <MobileFiltersPopup />
          )
        }
        <IonPopover
          cssClass='my-custom-class'
          event={showPopover.event}
          isOpen={showPopover.show}
          onDidDismiss={() => setShowPopover({ showPopover: false, event: undefined })}
        >
          <IonList>
            <IonItem onClick={startMobileIncident} button>Incident</IonItem>
            <IonItem onClick={startMobileInsight} button>Insight Report</IonItem>
          </IonList>
        </IonPopover>
        {
          showAddButton && view !== 'Safety Update' && (
          <IonButton
            onClick={handlePopover}
            className='insights-map-popover-button'
            style={{ '--background': (showIncidentF) ? '#C30101' : '#0C9500' }}
          >
            {
              (showIncidentF) ? (
                <Icon icon='bytesize:close' />
              ) : (
                <Icon icon='akar-icons:plus' />
              )
            }
          </IonButton>
          )
        }
        <IonPopover
          cssClass='my-custom-class'
          event={showSafetyPopover.event}
          isOpen={showSafetyPopover.show}
          onDidDismiss={() => setShowSafetyPopover({ showSafetyPopover: false, event: undefined })}
        >
          <IonList>
            <IonItem onClick={() => submitSafetyCheck('Green')} button>I feel safe
              <PopoverIcon className='option-green'>
                <Icon icon='charm:face-smile' />
              </PopoverIcon>
            </IonItem>
            <IonItem onClick={() => submitSafetyCheck('Amber')} button>I feel cautious
              <PopoverIcon className='option-yellow'>
                <Icon icon='charm:face-neutral' />
              </PopoverIcon>
            </IonItem>
            <IonItem onClick={() => submitSafetyCheck('Red')} button>I feel unsafe
              <PopoverIcon className='option-red'>
                <Icon icon='charm:face-frown' />
              </PopoverIcon>
            </IonItem>
          </IonList>
        </IonPopover>
        {
          showAddButton && view === 'Safety Update' && (
          <IonButton
            onClick={handleSCPopover}
            className='insights-map-popover-button'
            style={{ '--background': (showSafetyPopover.show) ? '#C30101' : '#0C9500' }}
          >
            {
              (showSafetyPopover.show) ? (
                <Icon icon='bytesize:close' />
              ) : (
                <Icon icon='bi:broadcast' />
              )
            }
          </IonButton>
          )
        }
      </IonRow>
    </>
  )
}

export default InsightsMap
