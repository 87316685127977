import { useIonAlert } from '@ionic/react'
import moment from 'moment'
import React, { FC, useEffect, useState } from 'react'
import Datetime from 'react-datetime'
import CreatableSelect from 'react-select/creatable'
import { FocusedActionKind } from '../../../hooks/terrain-mapping/helpers/StateReducers'
import { useTerrainMapping } from '../../../hooks/terrain-mapping/useTerrainMapping'
import { ListContainer } from '../../consultant/StyledContainers'
import { TagButton } from '../../incident-management/StyledContainers'
import axios from '../../../utils/axios'
import { IncidentCause } from '../../types/GlobalTypes'
import { TerrainType } from '../../types/OptimizedMaps'
import { Details, TerrainTypes } from '../forms/Issue'
import { BackButton, PopoverContainer, PopoverField } from '../StyledContainer'
import { useWorkspace } from '../../../hooks/useWorkspace'

const IssueScreen : FC = () => {
  const {
    focusedElems, dispatchFocused, setEditing, editing, setSubmittedMessage,
    setSaving, saving, stakeholders, communities, updateIssue, setLoading, causes,
  } = useTerrainMapping()
  const { workspace } = useWorkspace()
  const [selectedCauses, setSelectedCauses] = useState<(IncidentCause & { selected: boolean })[]>([])
  const [ionAlert] = useIonAlert()
  const [input, setInput] = useState<Details>({
    title: '',
    type: '',
    date: moment(),
    description: '',
    stakeholders: [],
    areas: [],
  })
  const { focusedIssue } = focusedElems

  const selectCause = (index: number) => {
    const copy = selectedCauses.slice()
    copy.splice(index, 1, { ...copy[index], selected: !copy[index].selected })

    setSelectedCauses(copy)
  }

  const errorMessages = {
    title: 'No title provided',
    type: 'No type provided',
    description: 'Please, briefly describe the issue',
  }

  const getAddRemove = (newRecords: ({ id: number, name: string } | IncidentCause)[], oldRecords:
  ({ id: number, name: string } | IncidentCause)[]) : { toRemove: number[], toAdd: number[] } => {
    const toAdd = newRecords.filter(({ id }) => !oldRecords.find((val) => val.id === id))
    const toRemove = oldRecords.filter(({ id }) => !newRecords.find((val) => val.id === id))

    return {
      toAdd: toAdd.map(({ id }) => id),
      toRemove: toRemove.map(({ id }) => id),
    }
  }

  const handleSubmit = () => {
    const keys = Object.keys(errorMessages)
    const errorKey = keys.find((val) => !input[val] || input[val] === '')

    if (errorKey) {
      ionAlert({
        header: 'Form Error',
        message: errorMessages[errorKey],
        buttons: [{ text: 'Ok' }],
      })
      return
    }

    const selectedStakeholders = input.stakeholders.map(({ value, label }) => ({ id: value, name: label }))
    const selectedCommunities = input.areas.map(({ value, label }) => ({ name: label, id: value }))

    const filteredCauses = selectedCauses.filter(({ selected }) => selected)

    const communityRecords = getAddRemove(selectedCommunities, focusedIssue.communities)
    const stakeholderRecords = getAddRemove(selectedStakeholders, focusedIssue.stakeholders)
    const causeRecords = getAddRemove(filteredCauses, focusedIssue.causes)

    setLoading(true)
    axios.put('/api/v2/issue/edit_issue', {
      domain_id: workspace.id,
      issue_id: focusedIssue.id,
      title: input.title,
      received: input.date.format(),
      description: input.description,
      type: input.type,
      issues_add: causeRecords.toAdd,
      issues_remove: causeRecords.toRemove,
      community_add: communityRecords.toAdd,
      community_remove: communityRecords.toRemove,
      stakeholders_add: stakeholderRecords.toAdd,
      stakeholders_remove: stakeholderRecords.toRemove,
    }).then(({ data }) => {
      if (!data.ok) {
        ionAlert({
          header: 'Server Error',
          message: data.message,
          buttons: [{ text: 'Ok' }],
        })
        return
      }
      updateIssue({
        id: focusedIssue.id,
        date: input.date.format(),
        title: input.title,
        type: input.type as TerrainType,
        description: input.description,
        stakeholders: selectedStakeholders,
        causes: filteredCauses,
        communities: selectedCommunities,
      })
      setSubmittedMessage('Issue updated successfully')
    }).catch(() => {
      ionAlert({
        header: 'Server error',
        message: 'Unknown Server error',
        buttons: [{ text: 'Ok' }],
      })
    }).finally(() => {
      setEditing(false)
      setSaving(false)
      setLoading(false)
    })
  }

  useEffect(() => {
    if (!saving) { return }
    handleSubmit()
  }, [saving])

  useEffect(() => {
    if (!editing) { return }
    setInput({
      title: focusedIssue.title,
      type: focusedIssue.type,
      stakeholders: focusedIssue.stakeholders.map(({ id, name }) => ({ label: name, value: id })),
      areas: focusedIssue.communities.map(({ id, name }) => ({ value: id, label: name })),
      date: moment(focusedIssue.date),
      description: focusedIssue.description,
    })
    setSelectedCauses(causes.map((val) => ({ ...val, selected: !!focusedIssue.causes.find(({ id }) => val.id === id) })))
  }, [editing])

  return (
    <ListContainer style={{ height: '100%', background: '#DCDCDC' }}>
      <div style={{ height: '100%', overflow: 'hidden' }} className='risk-assessment-list'>
        <BackButton onClick={() => {
          dispatchFocused({ type: FocusedActionKind.FORGET_SELECTION })
          setEditing(false)
        }}
        >Back to List
        </BackButton>
        <PopoverContainer>
          <PopoverField>
            <h6>Title</h6>
            {
              editing ? (
                <input
                  placeholder='Give this issue a title'
                  type='text'
                  value={input.title}
                  onChange={(e) => setInput({ ...input, title: e.target.value })}
                />
              ) : (
                <p>{ focusedIssue.title }</p>
              )
            }
          </PopoverField>
          <PopoverField>
            <h6>Issue Type</h6>
            {
              editing ? (
                <select
                  value={input.type}
                  onChange={(e) => setInput({ ...input, type: e.target.value as TerrainType })}
                  style={{ padding: '5px 10px' }}
                >
                  <option value='' disabled>Select a type</option>
                  {
                    TerrainTypes.map((val) => (
                      <option key={val} value={val}>{ val }</option>
                    ))
                  }
                </select>
              ) : (
                <p>{ focusedIssue.type }</p>
              )
            }
          </PopoverField>
          <PopoverField>
            <h6>Date Received</h6>
            {
              editing ? (
                <Datetime
                  dateFormat='YYYY-MM-DD'
                  inputProps={{
                    placeholder: 'Select Date',
                    readOnly: true,
                    style: { backgroundColor: 'white' },
                  }}
                  value={input.date}
                  onChange={(e) => setInput({ ...input, date: e as moment.Moment })}
                />
              ) : (
                <p>{ moment(focusedIssue.date).format('DD/MM/YY') }</p>
              )
            }
          </PopoverField>
          <PopoverField className='terrain-form-field form-desc'>
            <h6>Description</h6>
            {
              editing ? (
                <textarea value={input.description} onChange={(e) => setInput({ ...input, description: e.target.value })} />
              ) : (
                <p>{ focusedIssue.description }</p>
              )
            }
          </PopoverField>
          <PopoverField>
            <h6>Underlying Causes</h6>
            {
              editing ? (
                <>
                  {
                    selectedCauses.map((val, index) => (
                      <TagButton
                        key={val.id}
                        onClick={() => selectCause(index)}
                        style={{ backgroundColor: (val.selected) ? '#326771' : 'white', color: (val.selected) ? 'white' : '#326771' }}
                      >
                        { val.name }
                      </TagButton>
                    ))
                  }
                </>
              ) : (
                <>
                  {
                    focusedIssue.causes.length === 0 ? (
                      <p>No Causes</p>
                    ) : (
                      <ul>
                        {
                          focusedIssue.causes.map(({ name, id }) => (
                            <li key={id}><p>{ name}</p></li>
                          ))
                        }
                      </ul>
                    )
                  }
                </>
              )
            }
          </PopoverField>
          <PopoverField>
            <h6>Attending Stakeholders</h6>
            {
              editing ? (
                <CreatableSelect
                  isMulti
                  placeholder='Select Stakeholders'
                  name='colors'
                  className='select-container'
                  id='journey-passengers'
                // eslint-disable-next-line no-undef
                  menuPortalTarget={document.body}
                  options={stakeholders.map(({ id, name }) => ({ value: id, label: name }))}
                  value={input.stakeholders}
                  onChange={(selected) => setInput({ ...input, stakeholders: selected })}
                />
              ) : (
                <>
                  {
                    focusedIssue.stakeholders.length === 0 ? (
                      <p>No stakeholders</p>
                    ) : (
                      <ul>
                        {
                          focusedIssue.stakeholders.map(({ name, id }) => (
                            <li key={id}><p>{ name}</p></li>
                          ))
                        }
                      </ul>
                    )
                  }
                </>
              )
            }
          </PopoverField>
          <PopoverField>
            <h6>Relevant Areas</h6>
            {
              editing ? (
                <CreatableSelect
                  isMulti
                  placeholder='Select Areas'
                  name='colors'
                  className='select-container'
                  id='journey-passengers'
                // eslint-disable-next-line no-undef
                  menuPortalTarget={document.body}
                  options={communities.map(({ id, name }) => ({ label: name, value: id }))}
                  value={input.areas}
                  onChange={(selected) => setInput({ ...input, areas: selected })}
                />
              ) : (
                <>
                  {
                    focusedIssue.communities.length === 0 ? (
                      <p>No Areas</p>
                    ) : (
                      <ul>
                        {
                          focusedIssue.communities.map(({ name, id }) => (
                            <li key={id}><p>{ name}</p></li>
                          ))
                        }
                      </ul>
                    )
                  }
                </>
              )
            }
          </PopoverField>
        </PopoverContainer>
      </div>
    </ListContainer>
  )
}

export default IssueScreen
