/* eslint-disable no-param-reassign */
import {
  Feature, LineString, Point, Polygon, Position,
} from '@turf/turf'
import React, {
  FC, useState, useEffect, useRef,
} from 'react'
import mapboxgl from 'mapbox-gl'
import { IonButton, IonRow, useIonAlert } from '@ionic/react'
import CreatableSelect from 'react-select/creatable'
import moment from 'moment'
import Select from 'react-select'
import { useTerrainMapping } from '../../../hooks/terrain-mapping/useTerrainMapping'
import { FormContainer } from '../forms/MapForm'
import { ObscureBackground, OverlayContainer } from '../../GlobalContainers'
import { ZoneTypeButton } from '../../riskregister/StyledContainers'
import Pin from '../../terrain-mapping/icons/poi.svg'
import LinePOI from '../../terrain-mapping/icons/line-poi.svg'
import PolygonPOI from '../forms/images/restriction-zone-amber.svg'
import { POIType } from '../../types/GlobalTypes'
import axios from '../../../utils/axios'
import { createMB } from '../apps/AppMap'
import { Community, Stakeholder, TerrainPOI } from '../../types/OptimizedMaps'
import { useWorkspace } from '../../../hooks/useWorkspace'
import { SimpleButton } from '../StyledContainers'
import { Asset, ASSETTYPE } from '../../riskregister/types/Assets'
import { useRiskRegister } from '../../../hooks/risks/useRiskRegister'
import { SettingField } from '../../settings/StyledContainers'
import useApi from '../../../hooks/testHooks'
import { EditAssetActionKind } from '../../../hooks/risks/helpers/StateReducers'
import { AssetInputActionKind } from '../../../hooks/risks/helpers/AddAssetReducers'
import { createAsset, CreateAssetResponse, formatNewAsset } from '../../../hooks/risks/helpers/utils'
import { useAddAsset } from '../../../hooks/risks/useAddAsset'
import ImportAsset from '../../riskregister/forms/ImportAsset'

enum FlowView {
  PICK_LOCATION = 'location',
  POINT_DETAILS = 'details',
  CHOOSE_TYPE = 'type',
}

const AssetV2 : FC = () => {
  const [currentView, setCurrentView] = useState(FlowView.CHOOSE_TYPE)
  const [coordinates, setCoordinates] = useState<Position[]>([])
  const [markers, setMarkers] = useState<mapboxgl.Marker[]>([])
  const layerDrawn = useRef<boolean>(false)
  const { workspace } = useWorkspace()
  const {
    setShowPOIForm, pushNewPOI,
  } = useTerrainMapping()
  const [displayAlert] = useIonAlert()

  const {
    setShowAssetForm, mapRef, pushNewAsset, dispatchAssetInput,
    setSubmittedMessage, setLoading, addAssetInput, pushAsset,
  } = useRiskRegister()

  const { imported } = useAddAsset()

  const { showFilePopup, setShowFilePopup, setImported } = useAddAsset()

  const apiHook = useApi()

  const [type, setType] = useState<ASSETTYPE>()

  const getFeature = () : Feature<Point> | Feature<LineString> | Feature<Polygon> => {
    if (type === ASSETTYPE.LINE_MARKER) {
      return {
        type: 'Feature',
        properties: {},
        geometry: {
          type: 'LineString',
          coordinates,
        },
      }
    }

    if (type === ASSETTYPE.PIN) {
      return {
        type: 'Feature',
        properties: {},
        geometry: {
          type: 'Point',
          coordinates: coordinates[0],
        },
      }
    }

    return {
      type: 'Feature',
      properties: {},
      geometry: {
        type: 'Polygon',
        coordinates: [coordinates],
      },
    }
  }

  const handleSubmit = (name: string, category: string, description: string, assetType: string) => {
    let feature = getFeature()

    setLoading(true)

    console.log(`assetType: ${assetType}`)
    console.log(`name: ${name}`)
    console.log(`geodata: ${feature}`)

    let long
    let lat
    let marker_type

    if (imported) {
      feature = imported[0]
      const firstPoint = imported[0].geometry.coordinates[0][0]
      const [lng, lt] = firstPoint
      long = lng
      lat = lt
      marker_type = 'polygon'
    } else {
      long = coordinates[0][0]
      lat = coordinates[0][1]
      marker_type = type
    }

    apiHook.createAsset({
      domain_id: workspace.id,
      description,
      name,
      longitude: long,
      latitude: lat,
      asset_type: assetType,
      marker_type,
      geodata: feature,
    })
      .then((data: CreateAssetResponse) => {
        try {
          if (data.asset_id) {
            setSubmittedMessage('Asset created successfully')
            // pushAsset({ ...formatNewAsset(addAssetInput, workspace.id), id: data.asset_id })
          }
        } catch (innerError) {
          console.error('Error in then block:', innerError)
          throw innerError // Re-throw to be caught by the outer catch
        }
      })
      .catch((e) => {
        console.error('Error in catch block:', e)
        displayAlert({
          header: 'Unexpected Error',
          message: 'An error has ocurred while processing your request.',
        })
        setLoading(false)
      }).finally(() => {
        try {
          markers.forEach((val) => val.remove())
          setShowAssetForm(false)
        } catch (finalError) {
          console.error('Error in finally block:', finalError)
        } finally {
          setLoading(false)
        }
      })
  }

  const handleType = (selected: ASSETTYPE) => {
    setType(selected)
    setCurrentView(FlowView.PICK_LOCATION)
  }

  const handleCancel = () => {
    markers.map((val) => val.remove())
    setShowAssetForm(false)
  }

  const setCurrentPoint = ([lon, lat] : Position, index: number) => {
    if (!coordinates.length) return

    const positions = coordinates.slice()

    /* Change last markers position */
    markers[index].setLngLat([lon, lat])

    if (index === 0 && type === ASSETTYPE.POLYGON && layerDrawn.current) positions.splice(coordinates.length - 1, 1, [lon, lat])

    /* Update location */
    positions.splice(index, 1, [lon, lat])

    setCoordinates(positions);
    (mapRef.current.getSource('new-restriction-box') as mapboxgl.GeoJSONSource).setData({
      type: 'Feature',
      properties: {},
      geometry: {
        type: 'LineString',
        coordinates: positions,
      },
    })
  }

  /* Use reference to keep the state updated */
  const setCurrentPointRef = useRef(setCurrentPoint)
  setCurrentPointRef.current = setCurrentPoint

  useEffect(() => {
    createMB(mapRef, [])
    return () => {
      mapRef.current.removeLayer('new-restriction-box')
      if (mapRef.current.getLayer('new-layer')) mapRef.current.removeLayer('new-layer')
      mapRef.current.removeSource('new-restriction-box')
    }
  }, [])

  const handleSubmitImages = (files: FileList) => {
    const formData = new FormData()
    Array.from(files).forEach((val) => {
      formData.append('files[]', val)
    })
    setLoading(true)
    axios.post('/api/v1/asset/upload_asset', formData)
      .then(({ data }) => {
        console.log('coords: ', data.coords)
        const coordinates = [1, 2]
        dispatchAssetInput({ type: AssetInputActionKind.EDIT, payload: { ...addAssetInput, coordinates } })
        setImported(data.coords)
        setShowFilePopup(false)
        setLoading(false)
      })
  }

  if (currentView === FlowView.CHOOSE_TYPE) {
    return (
      <>
        <ObscureBackground style={{ zIndex: 20 }} />
        <ChooseType
          onSelect={handleType}
          onCancel={handleCancel}
        />
      </>
    )
  }

  if (currentView === FlowView.PICK_LOCATION && type === ASSETTYPE.POLYGON) {
    return (
      <DrawPolygon
        coordinates={coordinates}
        setCoordinates={setCoordinates}
        markers={markers}
        setMarkers={setMarkers}
        onCancel={handleCancel}
        onNext={() => setCurrentView(FlowView.POINT_DETAILS)}
        setCurrentPointRef={setCurrentPointRef}
        type={type}
        layerDrawn={layerDrawn}
        setCurrentView={setCurrentView}
      />
    )
  }

  if (currentView === FlowView.PICK_LOCATION) {
    return (
      <PickLocation
        coordinates={coordinates}
        setCoordinates={setCoordinates}
        markers={markers}
        setMarkers={setMarkers}
        onCancel={handleCancel}
        onNext={() => setCurrentView(FlowView.POINT_DETAILS)}
        setCurrentPointRef={setCurrentPointRef}
        type={type}
        setCurrentView={setCurrentView}
      />
    )
  }

  if (currentView === FlowView.POINT_DETAILS && showFilePopup) {
    return (
      <>
        <ObscureBackground />
        <ImportAsset
          onClose={() => { setShowFilePopup(false); setShowAssetForm(false) }}
          onSubmit={handleSubmitImages}
        />
      </>
    )
  }

  return (
    <>
      <ObscureBackground style={{ zIndex: 20 }} />
      <AssetDetailsV2
        type={type}
        onSubmit={handleSubmit}
        onCancel={handleCancel}
      />
    </>
  )
}

export default AssetV2

interface TypeProps {
  onSelect: (type: POIType) => void,
  onCancel: () => void,
}

const ChooseType: FC<TypeProps> = ({ onCancel, onSelect }) => (
  <OverlayContainer style={{ zIndex: 25, width: '650px' }} className='terrain-add-popup'>
    <h5>Choose POI Type</h5>
    <p>Choose the type of point of interest you wish to add to the map.</p>
    <div>
      <ZoneTypeButton onClick={() => onSelect(POIType.PIN)}>
        <img src={Pin} alt='Blue Polygon' />
        <div className='zone-type-text'>
          <h6>Pin</h6>
          <p>A pin can be dropped to indicate a point of interest. Useful for making single points on the map.
          </p>
        </div>
      </ZoneTypeButton>
      <ZoneTypeButton onClick={() => onSelect(POIType.LINE_MARKER)}>
        <img src={LinePOI} alt='Blue Polygon' />
        <div className='zone-type-text'>
          <h6>Line Marker</h6>
          <p>A line can be drawn on the map to indicate a point of interest that is not specific to a singlecoordinate.
          </p>
        </div>
      </ZoneTypeButton>
      <ZoneTypeButton onClick={() => onSelect(POIType.POLYGON)}>
        <img src={PolygonPOI} alt='Blue Polygon' />
        <div className='zone-type-text'>
          <h6>Polygon</h6>
          <p>A line can be drawn on the map to indicate a point of interest that is not specific to a singlecoordinate.
          </p>
        </div>
      </ZoneTypeButton>
    </div>
    <IonButton onClick={onCancel} style={{ '--background': '#8E151F' }}>Cancel</IonButton>
  </OverlayContainer>
)

interface Props {
  onCancel: () => void,
  onNext: () => void,
  coordinates: Position[],
  setCoordinates: React.Dispatch<React.SetStateAction<Position[]>>,
  type: ASSETTYPE,
  markers: mapboxgl.Marker[],
  setMarkers: React.Dispatch<React.SetStateAction<mapboxgl.Marker[]>>,
  setCurrentPointRef: React.MutableRefObject<([lon, lat]: Position, index: number) => void>
  setCurrentView: React.Dispatch<React.SetStateAction<string>>,
}

const PickLocation : FC<Props> = ({
  onCancel, onNext, coordinates, setCoordinates, markers, setMarkers, type, setCurrentPointRef, setCurrentView,
}) => {
  const { mapRef } = useRiskRegister()

  const { setShowFilePopup } = useAddAsset()

  const updateLayer = (positions: Position[]) => {
    /* Update layer */
    (mapRef.current.getSource('new-restriction-box') as mapboxgl.GeoJSONSource).setData({
      type: 'Feature',
      properties: {},
      geometry: {
        type: 'LineString',
        coordinates: positions,
      },
    })
  }

  const getPinColor = () : string => {
    if (type === ASSETTYPE.PIN) {
      return '#FF8A00'
    }

    if (coordinates.length === 0) {
      return 'rgb(109, 0, 235)'
    }

    return '#00B2FF'
  }

  const handleClick = (e: mapboxgl.MapMouseEvent & mapboxgl.EventData) => {
    if (type === POIType.PIN && coordinates.length > 0) { return }
    mapRef.current.easeTo({ center: [e.lngLat.lng, e.lngLat.lat], zoom: (mapRef.current.getZoom() < 9) ? 9 : mapRef.current.getZoom() })

    const mbMarker = new mapboxgl.Marker({ draggable: true, color: getPinColor() })
    /* Add Draggable listener */
    mbMarker.on('drag', () => {
      setCurrentPointRef.current([mbMarker.getLngLat().lng, mbMarker.getLngLat().lat], coordinates.length)
    })

    /* Add coordiantes and update states */
    mbMarker.setLngLat(e.lngLat).addTo(mapRef.current)
    setMarkers([...markers, mbMarker])

    const updated = [...coordinates, [e.lngLat.lng, e.lngLat.lat]]
    setCoordinates(updated)

    updateLayer(updated)
  }
  const handleClickRef = useRef(null)
  handleClickRef.current = handleClick

  const handleUndoPin = () => {
    if (coordinates.length === 0) { return }
    const newCoordinates = coordinates.slice()
    newCoordinates.splice(newCoordinates.length - 1, 1)

    setCoordinates(newCoordinates)

    updateLayer(newCoordinates)

    /* Remove marker from map */
    const marker = markers.pop()
    marker.remove()
    setMarkers([...markers])
  }

  const handleImport = () => {
    console.log('importing')
    setCurrentView(FlowView.POINT_DETAILS)
    setShowFilePopup(true)
  }

  useEffect(() => {
    const onClick = (e: mapboxgl.MapMouseEvent & mapboxgl.EventData) => {
      handleClickRef.current(e)
    }
    mapRef.current.on('click', onClick)
    return () => {
      mapRef.current.off('click', onClick)
    }
  }, [])

  return (
    <FormContainer className='add-community-popup' style={{ height: 'auto', backgroundColor: 'white' }}>
      <IonRow style={{ textTransform: 'capitalize' }} className='ion-align-items-center'>
        <h5>Add { type } Asset</h5>
      </IonRow>
      <p>Click the map to drop a pin to mark the point of interest’s location. You can drag the pin to move its position.</p>
      <p>Once your pin is in place, click ‘Next’ to continue.</p>
      <p>
        <button type='button' onClick={handleImport} style={{ background: 'none', border: 'none', color: 'blue', cursor: 'pointer' }}>
          Import Asset
        </button>
      </p>
      <IonRow
        style={{ marginTop: '30px' }}
        className={`${type === ASSETTYPE.LINE_MARKER ? 'ion-justify-content-between' : 'ion-justify-content-end'} ion-align-items-center`}
      >
        {
          type === ASSETTYPE.LINE_MARKER && (
            <IonButton onClick={handleUndoPin} disabled={coordinates.length === 0} style={{ '--background': '#4197A9' }}>Undo Pin</IonButton>
          )
        }
        <IonRow>
          <IonButton onClick={onCancel} style={{ '--background': '#8E151F' }}>Cancel</IonButton>
          <IonButton onClick={onNext} style={{ '--background': '#326771' }}>Next</IonButton>
        </IonRow>
      </IonRow>
    </FormContainer>
  )
}

interface PolygonDetails {
  coordinates: Position[],
  setCoordinates: React.Dispatch<React.SetStateAction<Position[]>>,
  markers: mapboxgl.Marker[],
  setMarkers: React.Dispatch<React.SetStateAction<mapboxgl.Marker[]>>,
  layerDrawn: React.MutableRefObject<boolean>,
  setCurrentPointRef: React.MutableRefObject<([lon, lat]: Position, index: number) => void>,
  type: POIType,
  onCancel: () => void,
  onNext: () => void,
  setCurrentView: React.Dispatch<React.SetStateAction<string>>,
}

const DrawPolygon : FC<PolygonDetails> = ({
  coordinates,
  setCoordinates,
  setMarkers,
  markers,
  layerDrawn,
  setCurrentPointRef,
  type,
  onCancel,
  onNext,
  setCurrentView,
}) => {
  const { mapRef } = useRiskRegister()
  const [alert] = useIonAlert()

  const handleUndoPin = () => {
    if (coordinates.length === 0) { return }
    const newCoordinates = coordinates.slice()
    newCoordinates.splice(newCoordinates.length - 1, 1)

    if (layerDrawn.current) {
      newCoordinates.splice(newCoordinates.length - 1, 1)
      mapRef.current.removeLayer('new-layer')
      layerDrawn.current = false
    }

    setCoordinates(newCoordinates);

    /* Redraw layer */
    (mapRef.current.getSource('new-restriction-box') as mapboxgl.GeoJSONSource).setData({
      type: 'Feature',
      properties: {},
      geometry: {
        type: 'LineString',
        coordinates: newCoordinates,
      },
    })

    /* Remove marker from map */
    const marker = markers.pop()
    marker.remove()
    setMarkers([...markers])
  }

  const drawLayer = () => {
    if (layerDrawn.current) { return }
    if (coordinates.length < 3) {
      alert({
        header: 'Error',
        message: 'You need to draw a polygon with at least 3 vertices',
        buttons: [
          { text: 'Ok' },
        ],
      })

      return
    }

    const newCoordinates = [...coordinates, coordinates[0]];

    (mapRef.current.getSource('new-restriction-box') as mapboxgl.GeoJSONSource).setData({
      type: 'Feature',
      properties: {},
      geometry: {
        type: 'LineString',
        coordinates: newCoordinates,
      },
    })

    mapRef.current.addLayer({
      id: 'new-layer',
      type: 'fill',
      source: 'new-restriction-box',
      layout: {},
      paint: {
        'fill-color': '#02C221', // blue color fill
        'fill-opacity': 0.5,
      },
    })

    setCoordinates(newCoordinates)
    layerDrawn.current = true
  }
  const handleUndoPinRef = useRef(null)
  handleUndoPinRef.current = handleUndoPin

  /* Use of reference to keep the state updated */
  const drawLayerRef = useRef(drawLayer)
  drawLayerRef.current = drawLayer

  const addNewLocation = (position: mapboxgl.LngLat) : Position[] => {
    const marker = (markers.length === 0) ? new mapboxgl.Marker({ draggable: true, color: 'rgb(109, 0, 235)' }) : new mapboxgl.Marker({ draggable: true })
    marker.setLngLat(position).addTo(mapRef.current)

    /* Add Draggable listener */
    marker.on('drag', () => {
      setCurrentPointRef.current([marker.getLngLat().lng, marker.getLngLat().lat], coordinates.length)
    })

    /* If first marker add listener to close polygon */
    if (coordinates.length === 0) {
      marker.getElement().onclick = (event) => {
        event.stopPropagation()
        drawLayerRef.current()
      }
    }

    const updated = [...coordinates, [position.lng, position.lat]]
    setCoordinates(updated)
    setMarkers([...markers, marker])

    return updated
  }

  const handleClick = (e: mapboxgl.MapMouseEvent & mapboxgl.EventData) => {
    if (layerDrawn.current) { return }
    mapRef.current.easeTo({ center: [e.lngLat.lng, e.lngLat.lat], zoom: (mapRef.current.getZoom() < 9) ? 9 : mapRef.current.getZoom() })
    const newCoordinates = addNewLocation(e.lngLat);

    (mapRef.current.getSource('new-restriction-box') as mapboxgl.GeoJSONSource).setData({
      type: 'Feature',
      properties: {},
      geometry: {
        type: 'LineString',
        coordinates: newCoordinates,
      },
    })
  }

  const { setShowFilePopup } = useAddAsset()

  const handleClickRef = useRef(handleClick)
  handleClickRef.current = handleClick

  useEffect(() => {
    const onClick = (e: mapboxgl.MapMouseEvent & mapboxgl.EventData) => {
      handleClickRef.current(e)
    }

    mapRef.current.on('click', onClick)
    return () => {
      mapRef.current.off('click', onClick)
    }
  }, [])

  const handleImport = () => {
    console.log('importing')
    setCurrentView(FlowView.POINT_DETAILS)
    setShowFilePopup(true)
  }

  return (
    <FormContainer className='add-community-popup' style={{ height: 'auto', backgroundColor: 'white' }}>
      <IonRow style={{ textTransform: 'capitalize' }} className='ion-align-items-center'>
        <h5>Add { type } Asset</h5>
      </IonRow>
      <p>Click the map to drop a pin to mark the point of interest’s location. You can drag the pin to move its position.</p>
      <p>Once your pin is in place, click ‘Next’ to continue.</p>
      <p>
        <button type='button' onClick={handleImport} style={{ background: 'none', border: 'none', color: 'blue', cursor: 'pointer' }}>
          Import Asset
        </button>
      </p>
      <IonRow
        style={{ marginTop: '30px' }}
        className='ion-justify-content-between ion-align-items-center'
      >
        <IonButton onClick={handleUndoPinRef.current} disabled={coordinates.length === 0} style={{ '--background': '#4197A9' }}>Undo Pin</IonButton>
        <IonRow>
          <IonButton onClick={onCancel} style={{ '--background': '#8E151F' }}>Cancel</IonButton>
          <IonButton disabled={!layerDrawn} onClick={onNext} style={{ '--background': '#326771' }}>Next</IonButton>
        </IonRow>
      </IonRow>
    </FormContainer>
  )
}

interface DetailProps {
  type: POIType,
  onSubmit: (name: string, category: string, description: string, assetType: string) => void,
  onCancel: () => void,
  defaultName?: string,
  defaultDescription?: string,
  defaultCategory?: string,
  defaultAssetType?: string,
}

export const AssetDetailsV2 : FC<DetailProps> = ({
  onCancel, onSubmit, type, defaultName, defaultCategory, defaultDescription, defaultAssetType,
}) => {
  const [name, setName] = useState<string>(defaultName)
  const [description, setDescription] = useState<string>(defaultCategory)
  const [category, setCategory] = useState<string>(defaultDescription)
  const [assetType, setAssetType] = useState<string>(defaultAssetType)
  const [owner, setOwner] = useState<number>()

  const { editing, assetTypes, addAssetInput, editAssetInput, dispatchAssetInput, dispatchEditingAsset, users } = useRiskRegister()
  const [ionAlert] = useIonAlert()
  const { imported } = useAddAsset()

  const handleSubmit = () => {
    if (name === '') {
      ionAlert({
        header: 'No Name',
        message: 'You need to specify the name of this Asset.',
        buttons: [{ text: 'ok' }],
      })
      return
    }

    if (assetType === '') {
      ionAlert({
        header: 'Error: No Type',
        message: 'You need to specify the Type of this Asset.',
        buttons: [{ text: 'ok' }],
      })
      return
    }

    onSubmit(name, category, description, assetType)
  }

  return (
    <>
      <ObscureBackground style={{ zIndex: 20 }} />
      <OverlayContainer
        style={{
          zIndex: 25, height: '80%', overflow: 'hidden', width: '60%',
        }}
        className='terrain-add-popup'
      >
        <div style={{ maxHeight: '100%', overflow: 'hidden' }} className='risk-assessment-list'>
          <IonRow style={{ marginBottom: '10px' }} className='ion-justify-content-between ion-align-items-center'>
            <h5 style={{ marginBottom: 0 }}>Add Key Asset</h5>
            <SimpleButton onClick={onCancel}>Close X</SimpleButton>
          </IonRow>

          <div style={{ flex: 1, overflow: 'auto' }}>
            <div className='terrain-form-field'>
              <h6>Name</h6>
              <input type='text' value={name} onChange={(e) => setName(e.target.value)} />
            </div>
            <div>
              <h6 className='blue'>Asset Type</h6>
              <select
                value={assetType}
                placeholder='Asset Type'
                onChange={(e) => setAssetType(e.target.value)}
                id='add-asset-type'
                style={{ marginLeft: '20%' }}
              >
                <option value='' disabled>Select Asset Type</option>
                {
                  assetTypes.map((name) => (
                    <option key={name} value={name}>{ name }</option>
                  ))
                }
              </select>
            </div>
          </div>
          <IonRow className='ion-justify-content-end ion-align-items-center'>
            <IonButton onClick={onCancel} style={{ '--background': '#8E151F' }}>Cancel</IonButton>
            <IonButton onClick={handleSubmit} style={{ '--background': '#0C9500' }}>Submit</IonButton>
          </IonRow>
        </div>
      </OverlayContainer>
    </>
  )
}

AssetDetailsV2.defaultProps = {
  defaultName: '',
  defaultCategory: '',
  defaultDescription: '',
  defaultAssetType: '',
}
