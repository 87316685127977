import { Position } from '@turf/turf'

export const getLocationByCountry = (countryCode: string) : Position => (
  {
    CM: [12.6279709551, 6.0271775760],
    ET: [39.52301808604, 8.8482841121573],
    PK: [69.11406410641, 29.604603638223],
    SS: [30.41187661666, 7.1611785818170],
    DRC: [24.71741725570, -1.634222859791],
    MZ: [36.140196491478, -17.600490125620],
  }[countryCode] as Position
)

export const centerMapAt = (workspace: any) : Position => {
  if (workspace.power === 'God' || workspace.power === 'ArchAngel' || !getLocationByCountry(workspace.country)) {
    return [25.788444525355, 3.131719618115]
  }

  return getLocationByCountry(workspace.country)
}

export const zoomInMap = (workspace: any) : number => {
  if (workspace.power === 'God' || workspace.power === 'ArchAngel' || !getLocationByCountry(workspace.country)) {
    return 4
  }

  return 5
}
