import React, { FC, useState } from 'react'
import Select from 'react-select'
import { useTerrainMapping } from '../../hooks/terrain-mapping/useTerrainMapping'
import TerrainMap from '../maps/apps/TerrainMap'
import LoadingModal from '../modals/LoadingModal'
import MapControls from './MapControls'
import { ObscureBackground, OverlayContainer, SherpaButton } from '../GlobalContainers'
import { CenterForm } from '../riskregister/StyledContainers'
import FormPopup from '../maps/forms/FormPopup'
import AddPopup from './popups/AddPopup'
import { AppView } from '../../hooks/terrain-mapping/types/HookInterface'
import POIPopup from './popups/POIPopup'
import CommunityProfile from './popups/CommunityProfile'
import { IonButton, IonCheckbox, IonCol, IonLabel, IonRow, IonSelect, IonSelectOption } from '@ionic/react'
import { Icon } from '@iconify/react'
import { size, update } from 'cypress/types/lodash'
import { Dropdown } from 'react-bootstrap'
import { FilterUpdate } from '../../hooks/terrain-mapping/helpers/StateReducers'
import { useWorkspace } from '../../hooks/useWorkspace'
import { assetsToGeoJSON } from '../../hooks/insights/helpers/MapUtils'
import { communitiesToGeoJSON, poisToGeoJSON } from '../../hooks/terrain-mapping/helpers/Utils'

const AppWrapper : FC = () => {
  const {
    loading, submittedMessage, setSubmittedMessage, showAddPopup, focusedElems, view, editing,
    showFilterPopup, setShowFilterPopup, setStakeholderFilters, stakeholderFilters, mapFilters, setMapFilters,
    communities, stakeholders, showMapFilterPopup, updateFilters, filters, setShowMapFilterPopup, mapRef,
    assets, poiList, setFilters,
  } = useTerrainMapping()

  const { setMapView } = useWorkspace()

  const [selectedTypes, setSelectedTypes] = useState([])
  const [selectedParent, setSelectedParent] = useState()
  const [selectedComms, setSelectedComms] = useState()
  const [selectedGroup, setSelectedGroup] = useState()
  const [selectedOrgs, setSelectedOrgs] = useState()

  const [selectedMapAssets, setSelectedMapAssets] = useState([])
  const [selectedMapPois, setSelectedMapPois] = useState([])
  const [selectedMapComms, setSelectedMapComms] = useState([])

  const [selectedMapViewer, setSelectedMapViewer] = useState()

  const groups = [
    { id: 1, name: 'Advocacy group' },
    { id: 2, name: 'Affected landholder' },
    { id: 3, name: 'ASM' },
    { id: 4, name: 'Business / cooperative' },
    { id: 5, name: 'Community group / association' },
    { id: 6, name: 'Education / social services' },
    { id: 7, name: 'Elder / traditional leadership' },
    { id: 8, name: 'Farmers' },
    { id: 9, name: 'Government / political party' },
    { id: 10, name: 'Informal workers / daily labour' },
    { id: 11, name: 'Local scientists / professionals / elites' },
    { id: 12, name: 'Media' },
    { id: 13, name: 'Militia / insurgent group' },
    { id: 14, name: 'NGO / INGO'},
    { id: 15, name: 'Organised labour / Trade union'},
    { id: 16, name: 'Project Affected Person (PAP)'},
    { id: 17, name: 'Public sector organisation'},
    { id: 18, name: 'Regulatory agency' },
    { id: 19, name: 'Religious group' },
    { id: 20, name: 'Security forces' },
    { id: 21, name: 'Students / Youth' },
    { id: 22, name: 'Suppliers / service providers' },
    { id: 23, name: 'Women' },
  ]

  const applyFilters = () => {
    setStakeholderFilters({
      ...stakeholderFilters,
      types: selectedTypes,
      parents: selectedParent,
      orgs: selectedOrgs,
      groups: selectedGroup,
      comms: selectedComms,
    })
    setShowFilterPopup(false)
  }

  const applyMapFilters = () => {
    const commsSource = mapRef.current.getSource('community-areas') as mapboxgl.GeoJSONSource | undefined
    if (commsSource) {
      console.log('comms: ', commsSource)
      // commsSource.setData(updated[option] ? communitiesToGeoJSON(communities) : { type: 'FeatureCollection', features: [] })
    }

    if (selectedMapAssets.length > 0) {
      if (mapRef.current) {
        if (filters.showAssets) {
          const updated = { ...filters, showAssets: !filters.showAssets }
          setFilters(updated)
        }
        // Type assertions and null checks for sources
        const linesSource = mapRef.current.getSource('assets-lines') as mapboxgl.GeoJSONSource | undefined
        const polygonsSource = mapRef.current.getSource('assets-polygons') as mapboxgl.GeoJSONSource | undefined
        // Ensure sources are not undefined before calling setData
        const filteredAssets = assets.filter((asset) => selectedMapAssets.some((selected) => selected.value === asset.id))
        const { featureLines, featurePolygons } = assetsToGeoJSON(filteredAssets)
        if (linesSource) {
          linesSource.setData(featureLines)
        }
        if (polygonsSource) {
          polygonsSource.setData(featurePolygons)
        }
      }
    }

    if (selectedMapComms.length > 0) {
      if (mapRef.current) {
        if (filters.showCommunities) {
          const updated = { ...filters, showCommunities: !filters.showCommunities }
          setFilters(updated)
        }
        // Type assertions and null checks for sources
        const polygonsSource = mapRef.current.getSource('community-areas') as mapboxgl.GeoJSONSource | undefined
        // Ensure sources are not undefined before calling setData
        const filteredComms = communities.filter((comm) => selectedMapComms.some((selected) => selected.value === comm.id))
        const geoJSONCommunities = communitiesToGeoJSON(filteredComms)
        if (polygonsSource) {
          polygonsSource.setData(geoJSONCommunities)
        }
      }
    }

    if (selectedMapPois.length > 0) {
      if (mapRef.current) {
        if (filters.showPOIs) {
          const updated = { ...filters, showPOIs: !filters.showPOIs }
          setFilters(updated)
        }

        const pointsSource = mapRef.current.getSource('poi-points') as mapboxgl.GeoJSONSource | undefined
        const linesSource = mapRef.current.getSource('poi-lines') as mapboxgl.GeoJSONSource | undefined
        const polygonsSource = mapRef.current.getSource('poi-polygons') as mapboxgl.GeoJSONSource | undefined

        const filteredPOIs = poiList.filter((poi) => selectedMapPois.some((selected) => selected.value === poi.id))
        const { featurePoints, featureLines, featurePolygons } = poisToGeoJSON(filteredPOIs)
        if (pointsSource) {
          pointsSource.setData(featurePoints)
        }
        if (linesSource) {
          linesSource.setData(featureLines)
        }
        if (polygonsSource) {
          polygonsSource.setData(featurePolygons)
        }
      }
    }

    setShowMapFilterPopup(false)
  }

  const customStyles = {
    multiValue: (provided) => ({
      ...provided,
      backgroundColor: '#326771', // Set background color to #326771
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      color: '#fff', // Set text color to white
    }),
    multiValueRemove: (provided) => ({
      ...provided,
      color: '#fff', // Set remove icon color to white
      ':hover': {
        backgroundColor: '#ff0000', // Change this to your desired hover background color
        color: '#fff', // Set hover text color to white
      },
    }),
  }

  const handleToggle = (event) => {
    setSelectedMapViewer(event.target.value)
    // Add your function logic here
    setMapView(event.target.value)
  }

  const handleAllAssets = () => {
    // Add your function logic here
    if (!filters.showAssets) {
      setSelectedMapAssets([])
    }
    updateFilters(FilterUpdate.SHOW_ASSETS)
  }

  const handleAllPois = () => {
    // Add your function logic here
    if (!filters.showPOIs) {
      setSelectedMapPois([])
    }
    updateFilters(FilterUpdate.SHOW_POIS)
  }

  const handleAllComms = () => {
    // Add your function logic here
    if (!filters.showCommunities) {
      setSelectedMapPois([])
    }
    updateFilters(FilterUpdate.SHOW_COMMUNITIES)
  }

  return (
    <>
      <LoadingModal isOpen={loading} />
      <MapControls />
      <TerrainMap />
      {
        view === AppView.TERRAIN_MAP && focusedElems.focusedPOI && (
          <POIPopup />
        )
      }
      {
        view === AppView.TERRAIN_MAP && focusedElems.focusedCommunity && !editing && (
          <CommunityProfile />
        )
      }
      {
        view === AppView.REGISTERS && showFilterPopup && (
          <>
            <ObscureBackground style={{ zIndex: 20 }} />
            <OverlayContainer style={{ zIndex: 25, width: '35%', height: '65%', borderRadius: '1em', overflowY: 'auto', display: 'flex', flexDirection: 'column' }} className='terrain-add-popup'>
              <IonRow>
                <IonCol size='9'>
                  <h5 style={{ fontSize: 14 }}>Stakeholder Register Filter:</h5>
                </IonCol>
                <IonCol size='3' style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <SherpaButton style={{ fontSize: '1rem' }} onClick={() => setShowFilterPopup(false)}>
                    Close X
                  </SherpaButton>
                </IonCol>
              </IonRow>
              <IonRow style={{ paddingTop: '10px', margin: '0', padding: '0' }}>
                <IonCol size='4'>
                  <IonLabel style={{ marginRight: '10px', fontSize: 14, color: '#326771' }}>Type</IonLabel>
                </IonCol>
                <IonCol size='8'>
                  <Select
                    isMulti
                    placeholder='Select Type'
                    name='colors'
                    className='select-container'
                    id='stakeholder-type'
                    options={[{ value: 'Household', label: 'Household' }, { value: 'Organisation', label: 'Organisation' }, { value: 'Individual', label: 'Individual' }] as any}
                    value={selectedTypes}
                    onChange={(selected) => setSelectedTypes(selected)}
                    isCreatable={false}
                    menuPortalTarget={document.body}
                    styles={customStyles}
                  />
                </IonCol>
              </IonRow>
              <IonRow style={{ paddingTop: '10px', margin: '0', padding: '0' }}>
                <IonCol size='4'>
                  <IonLabel style={{ marginRight: '10px', fontSize: 14, color: '#326771' }}>Parent Body</IonLabel>
                </IonCol>
                <IonCol size='8'>
                  <Select
                    isMulti
                    placeholder='Select Organisation Parent Body'
                    name='colors'
                    className='select-container'
                    id='parent'
                    options={stakeholders.map(({ id, name }) => ({ value: id, label: name }))}
                    value={selectedParent}
                    onChange={(selected) => setSelectedParent(selected)}
                    isCreatable={false}
                    menuPortalTarget={document.body}
                    styles={customStyles}
                  />
                </IonCol>
              </IonRow>
              <IonRow style={{ paddingTop: '10px', margin: '0', padding: '0' }}>
                <IonCol size='4'>
                  <IonLabel style={{ marginRight: '10px', fontSize: 14, color: '#326771' }}>Associated Org.</IonLabel>
                </IonCol>
                <IonCol size='8'>
                  <Select
                    isMulti
                    placeholder='Select Associated Organisations(s)'
                    name='colors'
                    className='select-container'
                    id='stakeholder-type'
                    options={stakeholders.sort((a, b) => a.name.localeCompare(b.name)).map(({ id, name }) => ({ value: id, label: name }))}
                    value={selectedOrgs}
                    onChange={(selected) => setSelectedOrgs(selected)}
                    isCreatable={false}
                    menuPortalTarget={document.body}
                    styles={customStyles}
                  />
                </IonCol>
              </IonRow>
              <IonRow style={{ paddingTop: '10px', margin: '0', padding: '0' }}>
                <IonCol size='4'>
                  <IonLabel style={{ marginRight: '10px', fontSize: 14, color: '#326771' }}>Stakeholder Group</IonLabel>
                </IonCol>
                <IonCol size='8'>
                  <Select
                    isMulti
                    placeholder='Select Stakeholder Group(s)'
                    name='colors'
                    className='select-container'
                    id='stakeholder-group'
                    options={groups.map((group) => ({ value: group.id, label: `${group.name}` }))}
                    value={selectedGroup}
                    onChange={(selected) => setSelectedGroup(selected)}
                    isCreatable={false}
                    menuPortalTarget={document.body}
                    styles={customStyles}
                  />
                </IonCol>
              </IonRow>
              <IonRow style={{ paddingTop: '10px', margin: '0', padding: '0' }}>
                <IonCol size='4'>
                  <IonLabel style={{ marginRight: '10px', fontSize: 14, color: '#326771' }}>Communities</IonLabel>
                </IonCol>
                <IonCol size='8'>
                  <Select
                    isMulti
                    placeholder='Select Associated Communities'
                    name='colors'
                    className='select-container'
                    id='associated-communities'
                    options={communities.map((community) => ({ value: community.id, label: `${community.name}` }))}
                    value={selectedComms}
                    onChange={(selected) => setSelectedComms(selected)}
                    isCreatable={false}
                    menuPortalTarget={document.body}
                    styles={customStyles}
                  />
                </IonCol>
              </IonRow>
              <IonRow style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: 'auto' }}>
                <IonCol>
                  <IonButton onClick={() => setShowFilterPopup(false)} style={{ '--background': '#8E151F' }}>
                    Cancel
                  </IonButton>
                </IonCol>
                <IonCol style={{ textAlign: 'right' }}>
                  <IonButton onClick={applyFilters} style={{ '--background': '#4197A9' }}>
                    Filter
                  </IonButton>
                </IonCol>
              </IonRow>
            </OverlayContainer>
          </>
        )
      }
      {
        view === AppView.TERRAIN_MAP && showMapFilterPopup && (
          <>
            <ObscureBackground style={{ zIndex: 20 }} />
            <OverlayContainer style={{ zIndex: 25, width: '35%', height: '65%', borderRadius: '1em', overflowY: 'auto', display: 'flex', flexDirection: 'column' }} className='terrain-add-popup'>
              <IonRow>
                <IonCol size='9'>
                  <h5 style={{ fontSize: 14 }}>Map Filter</h5>
                </IonCol>
                <IonCol size='3' style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <SherpaButton style={{ fontSize: '1rem' }} onClick={() => setShowMapFilterPopup(false)}>
                    Close X
                  </SherpaButton>
                </IonCol>
              </IonRow>
              <IonRow style={{ paddingTop: '10px', margin: '0', padding: '0' }}>
                <IonCol size='4'>
                  <IonLabel style={{ marginRight: '10px', fontSize: 14, color: '#326771' }}>Map Type</IonLabel>
                </IonCol>
                <IonCol size='8'>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <label style={{ marginRight: '20px' }}>
                      <input
                        type='radio'
                        value='map'
                        checked={selectedMapViewer === 'map'}
                        onChange={handleToggle}
                        style={{ marginRight: '10px', color: '#326771' }}
                      />
                      Default
                    </label>
                    <label style={{ marginRight: '20px' }}>
                      <input
                        type='radio'
                        value='satellite'
                        checked={selectedMapViewer === 'satellite'}
                        onChange={handleToggle}
                        style={{ marginRight: '10px', color: '#326771' }}
                      />
                      Satellite
                    </label>
                  </div>
                </IonCol>
              </IonRow>
              <IonRow style={{ paddingTop: '10px', margin: '0', padding: '0' }}>
                <IonCol size='4'>
                  <IonLabel style={{ marginRight: '10px', fontSize: 14, color: '#326771' }}>Communities</IonLabel>
                </IonCol>
                <IonCol size='8'>
                  <Select
                    isMulti
                    placeholder='Select Associated Communities'
                    name='colors'
                    className='select-container'
                    id='associated-communities'
                    options={communities.map((community) => ({ value: community.id, label: `${community.name}` }))}
                    value={selectedMapComms}
                    onChange={(selected) => setSelectedMapComms(selected)}
                    isCreatable={false}
                    menuPortalTarget={document.body}
                    styles={customStyles}
                  />
                </IonCol>
              </IonRow>
              <IonRow style={{ paddingTop: '10px', margin: '0', padding: '0' }}>
                <IonCol size='4'>
                  <IonLabel style={{ marginRight: '10px', fontSize: 14, color: '#326771' }}>Key Assets</IonLabel>
                </IonCol>
                <IonCol size='8'>
                  <Select
                    isMulti
                    placeholder='Select Key Asset(s)'
                    name='colors'
                    className='select-container'
                    id='stakeholder-type'
                    options={assets.sort((a, b) => a.name.localeCompare(b.name)).map(({ id, name }) => ({ value: id, label: name }))}
                    value={selectedMapAssets}
                    onChange={(selected) => setSelectedMapAssets(selected)}
                    isCreatable={false}
                    menuPortalTarget={document.body}
                    styles={customStyles}
                  />
                </IonCol>
              </IonRow>
              <IonRow style={{ paddingTop: '10px', margin: '0', padding: '0' }}>
                <IonCol size='4'>
                  <IonLabel style={{ marginRight: '10px', fontSize: 14, color: '#326771' }}>Points of Interest</IonLabel>
                </IonCol>
                <IonCol size='8'>
                  <Select
                    isMulti
                    placeholder='Select Points of Interest(s)'
                    name='colors'
                    className='select-container'
                    id='pois'
                    options={poiList.map((poi) => ({ value: poi.id, label: `${poi.name}` }))}
                    value={selectedMapPois}
                    onChange={(selected) => setSelectedMapPois(selected)}
                    isCreatable={false}
                    menuPortalTarget={document.body}
                    styles={customStyles}
                  />
                </IonCol>
              </IonRow>
              <hr />
              <Dropdown.Item onClick={() => updateFilters(FilterUpdate.SHOW_ASSETS)} className='asset-filter-option'>
                <IonLabel style={{ padding: '0 10px' }}> Show All Assets </IonLabel>
                <IonCheckbox
                  name='Assets'
                  checked={filters.showAssets}
                  onClick={(e) => {
                    if (e.stopPropagation) e.stopPropagation()
                    handleAllAssets()
                  }}
                />
              </Dropdown.Item>
              <Dropdown.Item onClick={() => updateFilters(FilterUpdate.SHOW_POIS)} className='asset-filter-option'>
                <IonLabel style={{ padding: '0 10px' }}> Show All Pois </IonLabel>
                <IonCheckbox
                  name='Pois'
                  checked={filters.showPOIs}
                  onClick={(e) => {
                    if (e.stopPropagation) e.stopPropagation()
                    handleAllPois()
                  }}
                />
              </Dropdown.Item>
              <Dropdown.Item onClick={() => updateFilters(FilterUpdate.SHOW_COMMUNITIES)} className='asset-filter-option'>
                <IonLabel style={{ padding: '0 10px' }}> Show All Communities </IonLabel>
                <IonCheckbox
                  name='Comms'
                  checked={filters.showCommunities}
                  onClick={(e) => {
                    if (e.stopPropagation) e.stopPropagation()
                    handleAllComms()
                  }}
                />
              </Dropdown.Item>
              <IonRow style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: 'auto' }}>
                <IonCol>
                  <IonButton onClick={() => setShowMapFilterPopup(false)} style={{ '--background': '#8E151F' }}>
                    Cancel
                  </IonButton>
                </IonCol>
                <IonCol style={{ textAlign: 'right' }}>
                  <IonButton onClick={applyMapFilters} style={{ '--background': '#4197A9' }}>
                    Filter
                  </IonButton>
                </IonCol>
              </IonRow>
            </OverlayContainer>
          </>
        )
      }
      {
        submittedMessage && (
          <>
            <ObscureBackground style={{ zIndex: '10' }} />
            <CenterForm style={{ height: 'auto', width: '30%' }}>
              <FormPopup message={submittedMessage} onClose={() => setSubmittedMessage(null)} />
            </CenterForm>
          </>
        )
      }
      {
        showAddPopup && (
          <>
            <ObscureBackground style={{ zIndex: '40' }} />
            <AddPopup />
          </>
        )
      }
    </>
  )
}

export default AppWrapper
