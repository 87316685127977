import * as turf from '@turf/turf'
import { Map } from 'mapbox-gl'
import * as React from 'react'
import { AssetList, Asset } from '../../../components/riskregister/types/Assets'
import {
  FocusedAssetAction,
  FocusedRiskAction,
  DisplayAsset,
  DisplayAssetAction,
  Filters,
  ViewModes,
  ViewActions,
  EditAssetState,
  EditAssetAction,
} from '../helpers/StateReducers'
import { ControlMeasure, RiskAssessment } from '../../../components/riskregister/types/RiskAssessments'
import { GeoLocation, User } from '../../../components/types/GlobalTypes'

import { BackendAsset, EditMeasures } from './BackendData'
import { AssetInput, AssetInputActions } from '../helpers/AddAssetReducers'
import { Community, CountryAreas, Stakeholder } from '../../../components/types/OptimizedMaps'

export interface RiskRegisterInterface {
  assets: AssetList,
  formatAssets: (counties: turf.FeatureCollection, assets: BackendAsset[]) => AssetList,
  allAssets: AssetList,
  focusedAsset: Asset | null,
  dispatchAsset: React.Dispatch<FocusedAssetAction>,
  focusedRisk: RiskAssessment | null,
  dispatchRisk: React.Dispatch<FocusedRiskAction>,
  loading: boolean,
  assetType: string,
  setAssetType: React.Dispatch<React.SetStateAction<string>>,
  setLoading: React.Dispatch<React.SetStateAction<boolean>>,
  editing: boolean,
  setEditing: React.Dispatch<React.SetStateAction<boolean>>,
  users: User[],
  fetchAppData: () => Promise<void>,
  fetchAppDataV2: () => Promise<void>,
  displayAsset: DisplayAsset,
  dispatchDisplayAsset: React.Dispatch<DisplayAssetAction>,
  filters: Filters,
  toggleAllAssets: (toggleAll: boolean) => void,
  filterMarkersByState: (state: string) => void,
  viewMode: ViewModes,
  dispatchViews: React.Dispatch<ViewActions>,
  returnToSafe: () => void,
  drawAssets: (assetList: AssetList | undefined) => void,
  dispatchAssetInput: React.Dispatch<AssetInputActions>,
  pushAsset: (newAsset: Asset) => void,
  mapRef: React.RefObject<Map>,
  addAssetInput: AssetInput,
  editAssetInput: EditAssetState,
  dispatchEditingAsset: React.Dispatch<EditAssetAction>,
  assetTypes: string[],
  deleteAsset: (asset: Asset) => void,
  deleteAssetList: (assets: Asset[]) => void,
  forgetEditingAsset: () => void,
  submitEditAsset: () => void,
  fetchRiskAssessments: (asset: Asset, callback: (risks: RiskAssessment[]) => void) => void,
  deleteRiskAssessments: (ids: number[]) => void,
  setAllAssets: React.Dispatch<React.SetStateAction<AssetList>>,
  fetchAssetDetails: () => Promise<void>,
  rawMeasures: ControlMeasure[],
  fetchMitigations: (risk: RiskAssessment, asset: Asset) => void,
  riskEvents: { [eventName: string] : string },
  pushRiskAssessment: (risk: RiskAssessment, asset: Asset) => Promise<void>,
  updateRisk: (risk: RiskAssessment, asset: Asset, formattedData: EditMeasures) => void,
  areas: CountryAreas[],
  view: any,
  setView: React.Dispatch<React.SetStateAction<any>>,
  selectedAsset: any,
  setSelectedAsset: React.Dispatch<React.SetStateAction<AssetList>>,
  showSelectedAsset: boolean,
  setShowSelectedAsset: React.Dispatch<React.SetStateAction<boolean>>,
  showAssetForm: boolean,
  setShowAssetForm: React.Dispatch<React.SetStateAction<boolean>>,  
  showFlashpointForm: boolean,
  setShowFlashpointForm: React.Dispatch<React.SetStateAction<boolean>>,
  pushNewAsset: () => void,
  submittedMessage: any,
  setSubmittedMessage: React.Dispatch<React.SetStateAction<any>>,
  incidentTypes: string[],
  stakeholders: Stakeholder[],
  communities: Community[],
  manifestations: string[],
  causes: any[],
}

export interface AddAssetInterface {
  handleClickRef : React.MutableRefObject<(e: any) => void>,
  handleSubmit: () => void,
  forwardForm: () => void,
  updateLocationRef: React.MutableRefObject<(newLocation: GeoLocation) => void>,
  canFillForm: boolean,
  backForm: () => void,
  forgetMarker: () => void,
  assetMarker?: mapboxgl.Marker,
  files?: any,
  setFiles: React.Dispatch<React.SetStateAction<any>>,
  showFilePopup: boolean,
  setShowFilePopup: React.Dispatch<React.SetStateAction<boolean>>,  
  imported: any,
  setImported: React.Dispatch<React.SetStateAction<any>>,
}

export interface EditingAsset extends Asset {
  newLocation: GeoLocation,
}

export enum AppView {
  ASSET_MAP = 'Asset Map',
  ASSET_REGISTER = 'Asset Register',
}
