import * as mapboxgl from 'mapbox-gl'
import React from 'react'
import * as ReactDOM from 'react-dom'
import { FeatureCollection, LineString } from '@turf/turf'
import CountyPopup2 from '../insights/popups/CountyPopup2'
import {
  ASRegion, CountryAreas, County, Incident,
} from '../types/OptimizedMaps'
import { incidentCategories } from '../maps/apps/incident_types.json'
import { getCountrySourceId } from './MapboxUtils'
import Criminality from '../maps/incidents/pins/Criminality.png'
import SocialUnrest from '../maps/incidents/pins/Social Unrest.png'
import Travel from '../maps/incidents/pins/Travel.png'
import ViolentConflict from '../maps/incidents/pins/Violent Conflict.png'
import ViolentCrime from '../maps/incidents/pins/Violent Crime.png'
import { getProperties } from '../../hooks/insights/helpers/MapUtils'
import { BackendAsset } from '../../hooks/risks/types/BackendData'
import AssetPopup from '../insights/popups-2.0/AssetPopup'

const fillASLayerConfig : mapboxgl.FillPaint = {
  'fill-color': [
    'match',
    ['feature-state', 'alert_state'],
    0,
    'rgba(255,255,255, 0)',
    1,
    '#21ed39',
    2,
    '#217aed',
    3,
    '#f5f531',
    4,
    '#f58331',
    5,
    '#f53731',
    'rgba(255,255,255, 0)',
  ],
  'fill-opacity': 0.9,
}

interface MapBoxTile {
  tileID: string,
  layerID: string,
}

const getTileId = (country: string) : { countySource: MapBoxTile, regionSource: MapBoxTile } => ({
  Cameroon: {
    countySource: {
      tileID: 'jorgelmh.0fsqsis8',
      layerID: 'county-4l2m84',
    },
    regionSource: {
      tileID: 'jorgelmh.cyphufgj',
      layerID: 'regions-4b29te',
    },
  },
  Ethiopia: {
    countySource: {
      tileID: 'jorgelmh.1j04udkl',
      layerID: 'geoBoundaries-ETH-ADM2_simpli-1kn6ku',
    },
    regionSource: {
      tileID: 'jorgelmh.a02xwo82',
      layerID: 'regions-0vixsm',
    },
  },
  Pakistan: {
    countySource: {
      tileID: 'jorgelmh.3yes4lly',
      layerID: 'counties-b58qpu',
    },
    regionSource: {
      tileID: 'jorgelmh.afcwmc1p',
      layerID: 'regions-65p5rq',
    },
  },
  SouthSudan: {
    countySource: {
      tileID: 'jorgelmh.5giwyjtk',
      layerID: 'counties-arj9of',
    },
    regionSource: {
      tileID: 'jorgelmh.aolw9lxd',
      layerID: 'regions-apg9fx',
    },
  },
  DRC: {
    countySource: {
      tileID: 'jorgelmh.cs0wwf7z',
      layerID: 'counties-9b5uuu',
    },
    regionSource: {
      tileID: 'jorgelmh.8ffs7xgd',
      layerID: 'regions-4apg72',
    },
  },
  Mozambique: {
    countySource: {
      tileID: 'jorgelmh.cc91f76n',
      layerID: 'geoBoundaries-MOZ-ADM2_simpli-1af5rg',
    },
    regionSource: {
      tileID: 'jorgelmh.c85w5a5o',
      layerID: 'regions-4qvk9t',
    },
  },
  Tanzania: {
    countySource: {
      tileID: 'trubshaw.98xuevvo',
      layerID: 'Tanzania_Simplified_ADM2-dp60dl',
    },
    regionSource: {
      tileID: 'trubshaw.6q02ta81',
      layerID: 'Tanzania_Simplified_ADM1-00q1d9',
    },
  },
  UK: {
    countySource: {
      tileID: 'trubshaw.8ornvj0x',
      layerID: 'UK_ADM2_Simplified-6hocz0',
    },
    regionSource: {
      tileID: 'trubshaw.dmx850pt',
      layerID: 'UK_Simplified-clkonr',
    },
  },
  Canada: {
    countySource: {
      tileID: 'trubshaw.5uf2f5ic',
      layerID: 'Canada-1qld53',
    },
    regionSource: {
      tileID: 'trubshaw.c9972fja',
      layerID: 'canada2-adm1-cw7m3x',
    },
  },
}[country])

/**
 * Create initial mapbox layers
 *
 * @param mapRef -> React reference to mapbox map object
 * @param country -> Country object that contains counties and regions
 */
export const addASLayers = (mapRef: React.RefObject<mapboxgl.Map>, country: CountryAreas) => {
  /* Get Unique string ids for mapbox resources */
  const { countyId, regionId } = getCountrySourceId(country.country)
  const { countySource, regionSource } = getTileId(country.country)

  /* Add polygon AS fillings */
  mapRef.current.addLayer({
    id: `${countyId}-fill`,
    type: 'fill',
    source: countyId,
    'source-layer': countySource.layerID,
    layout: {},
    paint: fillASLayerConfig,
    minzoom: 5,
  })

  mapRef.current.addLayer({
    id: `${regionId}-fill`,
    type: 'fill',
    source: regionId,
    'source-layer': regionSource.layerID,
    layout: {},
    paint: fillASLayerConfig,
    maxzoom: 5,
  })

  /* Add polygon limits */
  mapRef.current.addLayer({
    id: `${countyId}-limits`,
    source: countyId,
    'source-layer': countySource.layerID,
    type: 'line',
    layout: {
      'line-join': 'round',
      'line-cap': 'round',
    },
    paint: {
      'line-color': '#888',
      'line-width': 0.5,
    },
    minzoom: 5,
  })

  mapRef.current.addLayer({
    id: `${regionId}-limits`,
    source: regionId,
    'source-layer': regionSource.layerID,
    type: 'line',
    layout: {
      'line-join': 'round',
      'line-cap': 'round',
    },
    paint: {
      'line-color': '#888',
      'line-width': 0.5,
    },
    maxzoom: 5,
  })

  /* Add polygon names */
  mapRef.current.addLayer({
    id: `${countyId}-names`,
    source: countyId,
    'source-layer': countySource.layerID,
    type: 'symbol',
    paint: {
      'text-opacity': [
        'match',
        ['feature-state', 'alert_state'],
        0,
        0,
        1,
      ],
    },
    layout: {
      'text-field': ['get', 'shapeName'],
      'text-size': 12,
    },
    minzoom: 5,
  })

  mapRef.current.addLayer({
    id: `${regionId}-names`,
    source: regionId,
    'source-layer': regionSource.layerID,
    type: 'symbol',
    paint: {
      'text-opacity': [
        'match',
        ['feature-state', 'alert_state'],
        0,
        0,
        1,
      ],
    },
    layout: {
      'text-field': ['get', 'shapeName'],
      'text-size': 12,
    },
    maxzoom: 5,
  })
}

/**
 * Create initial sources
 *
 * @param mapRef -> React reference to mapbox map object
 * @param countries -> Country objects with their respective counties and regions
 */
export const createSources = (mapRef: React.RefObject<mapboxgl.Map>, countries: CountryAreas[]) => {
  /* Loop through the countries */
  countries.forEach((val) => {
    /* Get source ids */
    const { countySource, regionSource } = getTileId(val.country)
    const { countyId, regionId } = getCountrySourceId(val.country)

    mapRef.current.addSource(countyId, {
      type: 'vector',
      url: `mapbox://${countySource.tileID}`,
      promoteId: 'shapeID',
    })

    mapRef.current.addSource(regionId, {
      type: 'vector',
      url: `mapbox://${regionSource.tileID}`,
      promoteId: 'shapeID',
    })

    /* Create layers */
    addASLayers(mapRef, val)
  })
}

/**
 * Create incidents source and pin layers
 *
 * @param mapRef -> React reference to mapbox object
 */
export const createIncidentsLayer = (mapRef: React.RefObject<mapboxgl.Map>) => {
  const icons = [{ name: 'Criminality', img: Criminality },
    { name: 'Social Unrest', img: SocialUnrest }, { name: 'Travel', img: Travel }, { name: 'Violent Conflict', img: ViolentConflict }, { name: 'Violent Crime', img: ViolentCrime }]
  const conditionalIcons : mapboxgl.Expression = ['match', ['get', 'category'], ['Violent Conflict'], 'Violent Conflict',
    ['Violent Crime'], 'Violent Crime', ['Criminality'], 'Criminality', ['Social Unrest'], 'Social Unrest', ['Travel'], 'Travel', 'Criminality']
  let loadedIcons = 0

  icons.forEach(({ name, img }) => {
    // eslint-disable-next-line no-undef
    const iconImage = new Image()
    iconImage.onload = () => {
      mapRef.current.addImage(name, iconImage)
      // eslint-disable-next-line no-plusplus
      if (++loadedIcons >= icons.length) {
        // Add a symbol layer
        mapRef.current.addLayer({
          id: 'incident-markers',
          type: 'symbol',
          source: 'incidents',
          layout: {
            'icon-image': conditionalIcons,
            'icon-size': 0.15,
            'text-allow-overlap': true,
            'text-ignore-placement': true,
            'icon-allow-overlap': true,
            'icon-ignore-placement': true,
          },
        })
      }
    }

    iconImage.src = img
  })

  /* Add empty source of incidents -> will depend on the filters */
  mapRef.current.addSource('incidents', {
    type: 'geojson',
    data: {
      type: 'FeatureCollection',
      features: [],
    },
  })
}

/**
 * Draw alert state for counties/regions
 *
 * @param mapRef -> React reference to mapbox map object
 * @param countries -> Country objects with counties and regions
 * @param show -> Whether to render alert state color or not
 */
export const renderAlertStates = (mapRef: React.RefObject<mapboxgl.Map>, countries: CountryAreas[], show: boolean) => {
  countries.forEach(({ counties, country, regions }) => {
    const { countyId, regionId } = getCountrySourceId(country)
    const { countySource, regionSource } = getTileId(country)

    /* Join sources and backend objects using shape_id as link */
    counties.forEach(({ shape_id, alert_state }) => {
      mapRef.current.setFeatureState({
        source: countyId,
        sourceLayer: countySource.layerID,
        // YOUR TURN: Replace with unqiue ID row name
        id: shape_id,
      }, {
        alert_state: (show) ? alert_state : 0,
      })
    })

    regions.forEach(({ shape_id, avg_as }) => {
      mapRef.current.setFeatureState({
        source: regionId,
        sourceLayer: regionSource.layerID,
        // YOUR TURN: Replace with unqiue ID row name
        id: shape_id,
      }, {
        alert_state: (show) ? avg_as : 0,
      })
    })
  })
}

/**
 * Create mapbox popup for counties
 *
 * @param e -> Mapbox click event
 * @param mapRef -> React map reference
 * @param onClick -> onClick callback
 * @returns A mapbox popup object
 */
export const displayAlertStatePopup = (
  mapRef: React.RefObject<mapboxgl.Map>,
  county: County | ASRegion,
  coordinates: mapboxgl.LngLat,
  onClick: () => void,
) : mapboxgl.Popup => {
  /* Create React component and render it before adding it to the mapbox popup */
  const placeholder = document.createElement('div')
  const component = (
    <CountyPopup2 data={county} onClick={onClick} />
  )
  ReactDOM.render(component, placeholder)

  return new mapboxgl.Popup()
    .setLngLat(coordinates)
    .setDOMContent(placeholder)
    .addTo(mapRef.current)
}

/**
 * Create and display a mapbox popup
 *
 * @param e -> Click event
 * @param mapRef -> React map reference
 * @returns -> A mapbox popup
 */
export const displayAssetPopup = (e: mapboxgl.MapMouseEvent & {
  features?: mapboxgl.MapboxGeoJSONFeature[];
} & mapboxgl.EventData, mapRef: React.RefObject<mapboxgl.Map>) : mapboxgl.Popup => {
  console.log('displayAssetPopup')
  const { feature, coordinates } = getProperties(e)
  const asset = { ...feature } as BackendAsset

  /* Create React component and render it before adding it to the mapbox popup */
  const placeholder = document.createElement('div')
  const component = (
    <AssetPopup data={asset} />
  )
  ReactDOM.render(component, placeholder)

  return new mapboxgl.Popup()
    .setLngLat(coordinates)
    .setDOMContent(placeholder)
    .addTo(mapRef.current)
}

/**
 * Draw incidents on a mapbox map
 *
 * @param mapRef -> Ref to the map element
 * @param incidents -> List of already filtered incidents
 */
export const drawIncidents = (mapRef: React.RefObject<mapboxgl.Map>, incidents: Incident[]) : void => {
  /* Update source with new set of filtered incidents */
  (mapRef.current.getSource('incidents') as mapboxgl.GeoJSONSource).setData({
    type: 'FeatureCollection',
    features: incidents.map((val) => ({
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [
          val.longitude, val.latitude,
        ],
      },
      properties: {
        category: Object.keys(incidentCategories).find((key) => incidentCategories[key].find((type: string) => type === val.incident_type)),
        ...val,
      },
    })),
  })
}

/**
 * Create layer and draw initial feature collection
 *
 * @param mapRef -> React reference to mapbox map
 * @param geoJSONJourneys -> Feature collection made out of journeys' routes
 */
export const createJourneyLayer = (mapRef: React.RefObject<mapboxgl.Map>, geoJSONJourneys: FeatureCollection<LineString>) => {
  //
  console.log('createJourneyLayer', geoJSONJourneys)
  mapRef.current.addSource('journeys', {
    type: 'geojson',
    data: geoJSONJourneys,
  })
  mapRef.current.addLayer({
    id: 'journeyline-active',
    type: 'line',
    source: 'journeys',
    layout: {
      'line-join': 'round',
      'line-cap': 'round',
    },
    paint: {
      'line-color': '#6AB43F',
      'line-width': [
        'interpolate',
        ['linear'],
        ['zoom'],
        12, 3,
        22, 12,
      ],
    },
  })
}
