/* eslint-disable camelcase */
/* eslint-disable no-param-reassign */
/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react'
import {
  IonContent, IonInput, IonList, IonRow, IonItem, IonCol,
  IonButton, useIonAlert, IonSpinner,
} from '@ionic/react'
import { Icon } from '@iconify/react'
import Select from 'react-select'

import Datetime from 'react-datetime'
import moment from 'moment'

import { SettingField } from '../settings/StyledContainers'
import { SherpaButton, SimpleDropdown } from '../GlobalContainers'

import AddObjective from './AddObjective'

import useApi from '../../hooks/testHooks'
import { useWorkspace } from '../../hooks/useWorkspace'
import { crossReferenceObjectives } from './StatusHelper'

const emptyFields = {
  title: '',
  category: null,
  problem: '',
  goal: '',
  startDate: null,
  endDate: null,
  objectives: [],
  owner: null,
  summary: '',
  support: '',
  assets: [],
  communities: [],
  stakeholders: [],
  team_members: [],
  project_lead: null,
  edit: false,
  metrics: [
    {
      metric: 'default',
      category: 'default',
      theme: 'default',
      target: 0,
      current: 0,
      old: false,
    },
  ],
}

/**
 *  ===========================
 *      Create Project Form
 *  ===========================
 */
const CreateProject = ({
  setCreating, defaultInput = emptyFields, users, title, addProject, edit,
  addObjectives, setLoading, loadingMetrics, irisMetrics, setView, setCreateObjective,
}) => {
  const [input, setInput] = useState(defaultInput)
  const [focussedObj, setFocussedObj] = useState(null)
  /* Application hooks */
  const apiHook = useApi()
  const [addingObj, setAddingObj] = useState(false)
  const [, setDeletedMetrics] = useState([])

  const [stakeholders, setStakeholders] = useState([])
  const [communities, setCommunities] = useState([])
  const [assets, setAssets] = useState([])

  const internal = [
    {
      label: 'Internal',
      options: [
        { label: 'Community', value: 'Community' },
        { label: 'Engagement', value: 'Engagement' },
        { label: 'General', value: 'General' },
        { label: 'Grievance', value: 'Grievance' },
        { label: 'Incident', value: 'Incident' },
        { label: 'Land Access', value: 'Land Access' },
        { label: 'Operations', value: 'Operations' },
        { label: 'Recruitment', value: 'Recruitment' },
        { label: 'Resettlement', value: 'Resettlement' },
        { label: 'Risk Control', value: 'Risk Control' },
      ],
    },
  ]

  const external = [
    {
      label: 'External',
      options: [
        { label: 'Agriculture', value: 'Agriculture' },
        { label: 'Air', value: 'Air' },
        { label: 'Biodiversity and Ecosystems', value: 'Biodiversity and Ecosystems' },
        { label: 'Climate', value: 'Climate' },
        { label: 'Diversity and Inclusion', value: 'Diversity and Inclusion' },
        { label: 'Education', value: 'Education' },
        { label: 'Employment', value: 'Employment' },
        { label: 'Energy', value: 'Energy' },
        { label: 'Financial Services', value: 'Financial Services' },
        { label: 'Health', value: 'Health' },
        { label: 'Infrastructure', value: 'Infrastructure' },
        { label: 'Land', value: 'Land' },
        { label: 'Oceans and Coastal Zones', value: 'Oceans and Coastal Zones' },
        { label: 'Pollution', value: 'Pollution' },
        { label: 'Real Estate', value: 'Real Estate' },
        { label: 'Waste', value: 'Waste' },
        { label: 'Water', value: 'Water' },
      ],
    },
  ]

  const categories = [...internal]

  /* Display Popup message */
  const [ionAlert] = useIonAlert()
  const { workspace } = useWorkspace()

  /* Handle input changes on the forms */
  const handleChange = (e) => {
    setInput({ ...input, [e.target.name]: e.detail.value, edit: true })
  }

  /* Add new index to the array */
  const addObjective = (index, objective) => {
    const copy = input.objectives.slice()
    if (index !== null) {
      copy[index] = { ...copy[index], ...objective }
      setFocussedObj(null)
    } else { copy.push(objective) }
    setInput({ ...input, objectives: copy, edit: true })
    setAddingObj(false)
  }

  const formatObjs = (objectives) => objectives.map((objective) => ({
    ...objective,
    owner: objective.owner.value,
    startDate: objective.startDate.format('YYYY-MM-DD HH:mm'),
    endDate: objective.endDate.format('YYYY-MM-DD HH:mm'),
    tasks: [],
    status: objective.status || 'Planning',
  }))

  const formatCreateRequest = (objectives) => objectives.map(({
    name, startDate, endDate,
    owner, description,
  }) => ({
    title: name,
    deadline: endDate,
    description,
    owner,
    start: startDate,
  }))

  const formatEditRequest = (objectives) => objectives.map(({
    id, name, startDate, endDate,
    owner, description,
  }) => ({
    id,
    title: name,
    deadline: endDate,
    description,
    owner,
    start: startDate,
  }))

  /* Popup messages when the form is uncomplete */
  const returnMessage = (key) => {
    if (key === 'title') return { header: 'Error - Title', message: 'Please fill in the project’s title.' }
    if (key === 'type') return { header: 'Error - Type', message: 'Project type missing' }
    if (key === 'problem') return { header: 'Error - Problem', message: 'Please explain the problem this project aims to solve' }
    if (key === 'goal') return { header: 'Error - Goal', message: 'Please explain the general goal of this project.' }
    if (key === 'name') return { header: 'Error - Objective Name', message: 'One of your project objectives is missing a name.' }
    if (key === 'date') return { header: 'Error - Objective due date', message: 'One of your project objectives is missing a name.' }

    return null
  }

  const getRequestMetrics = () => {
    const newMetrics = input.metrics.filter((metric) => metric.edit && !metric.old).map((metric) => ({ ...metric, old: true, edit: false }))
    const editMetrics = input.metrics.filter((metric) => metric.edit && metric.old).map((metric) => ({ ...metric, edit: false }))

    const oldMetrics = input.metrics.filter((metric) => metric.old)

    return [newMetrics, editMetrics, oldMetrics]
  }

  const formatMetrics = (metrics, project_id) => metrics.map((metric) => ({
    metric_type: 'IRIS Metric',
    category: metric.metric,
    metric_name: metric.category,
    theme: metric.theme,
    target: metric.target,
    current: metric.current,
    unit: 'Percentage',
    is_integer: true,
    project_id,
  }))

  const formatEditMetrics = (metrics) => metrics.map((metric) => ({
    target: metric.target,
    current: metric.current,
    id: metric.id,
  }))

  const handleSubmit = async () => {
    const notFilled = Object.keys(input).find((key) => !input[key])
    if (notFilled && returnMessage(notFilled)) {
      const error = returnMessage(notFilled)
      ionAlert({
        header: error.header,
        message: error.message,
        buttons: [
          { text: 'OK' },
        ],
      })

      return
    }

    if (input.startDate.isAfter(input.endDate.format('YYYY-MM-DD'))) {
      ionAlert({
        header: 'Error',
        message: 'The project end date must occur after the selected start date.',
        buttons: [
          { text: 'OK' },
        ],
      })

      return
    }

    let canSubmit = true

    input.objectives.forEach((objective) => {
      const objNotFilled = Object.keys(objective).find((key) => !objective[key])
      if (objNotFilled && returnMessage(objNotFilled)) {
        const error = returnMessage(objNotFilled)
        ionAlert({
          header: error.header,
          message: error.message,
          buttons: [
            { text: 'Ok' },
          ],
        })
        canSubmit = false
      }

      /* Check dates */
      if (objective.endDate.isBefore(objective.startDate.format('YYYY-MM-DD'))) {
        ionAlert({
          header: 'Date Error',
          message: 'The end date of an objective cant be before the start date',
          buttons: [
            { text: 'Ok' },
          ],
        })
        canSubmit = false
      }
    })

    if (!canSubmit) return

    /*  Format objectives when submitted */
    const objectives = formatObjs(input.objectives)

    setLoading(true)
    /*  Send a request to create the project */
    if (!edit) {
      // TODO: add new data
      const { message, id } = await apiHook.createProject(input.title, input.endDate.format('YYYY-MM-DD HH:mm'),
        workspace.id, input.problem, input.goal, input.startDate.format('YYYY-MM-DD HH:mm'), input.category.value,
        1, input.summary, input.support, input.assets, input.communities, input.stakeholders, input.team_members, input.project_lead.value)
      /* Display error inc ase something crashed in the backend */
      if (id < 0) {
        ionAlert({
          header: 'Server Error',
          message,
          buttons: [
            { text: 'Ok' },
          ],
        })
        setLoading(false)
        return
      }
      const formattedObjectives = formatCreateRequest(objectives)
      if (formattedObjectives.length) {
        const response = await apiHook.createObjective(id, formattedObjectives)

        objectives.forEach((objective, index) => {
          objective.id = response.id[index]
        })
      }
      const externalProject = external[0].options.find(({ value }) => input.category.value === value)

      const newProject = {
        ...input,
        type: (externalProject) ? 'External' : 'Internal',
        category: input.category.value,
        objectives: objectives.filter((obj) => obj.id > 0),
        id,
        project_lead_id: input.project_lead.value,
        owner: input.project_lead.value,
      }

      if (externalProject) {
        const [newMetrics] = getRequestMetrics()

        if (newMetrics.length) {
          const { ids } = await apiHook.addMetrics({ metrics: formatMetrics(newMetrics, id), domain_id: workspace.id })
          newMetrics.forEach((metric, index) => {
            metric.id = ids[index]
          })
        }

        newProject.metrics = [...newMetrics]
      }

      addProject(newProject)
    } else {
      /* Edit project details request */
      const { message } = await apiHook.editProject({
        ...input, deadline: input.endDate.format('YYYY-MM-DD HH:mm'), start: input.startDate.format('YYYY-MM-DD HH:mm'), user_id: input.owner.value,
      })

      if (!message.includes('Changes succesfully made for project')) {
        ionAlert({
          header: 'Server Error',
          message,
          buttons: [
            { text: 'Ok' },
          ],
        })
        setLoading(false)
        return
      }
      const edited = input.objectives.filter((obj) => !obj.new && obj.edit)
      const newObjs = input.objectives.filter((obj) => obj.new)

      if (edited.length) {
        /* Update objective dates based on backend changes */
        const requestParam = formatEditRequest(formatObjs(edited))
        await apiHook.editObjective({ domain_id: workspace.id, objectives: requestParam })
      }

      if (newObjs.length) {
        const requestParam = formatCreateRequest(formatObjs(newObjs))
        await apiHook.createObjective(defaultInput.id, requestParam)
      }

      /* As editing a project can  change some of the objectives then fetch them again */
      const editedProject = await apiHook.getProjectDetails(defaultInput.id)
      const formattedObjectives = crossReferenceObjectives(editedProject)

      const externalProject = external[0].options.find(({ value }) => input.category.value === value)

      const newProject = {
        ...input,
        startDate: input.startDate.format('YYYY-MM-DD HH:mm'),
        type: (externalProject) ? 'External' : 'Internal',
        endDate: input.endDate.format('YYYY-MM-DD HH:mm'),
        owner: input.owner.value,
        objectives: formattedObjectives,
        category: input.category.value,
      }

      if (externalProject) {
        const [newMetrics, editedMetrics, oldMetrics] = getRequestMetrics()

        /* TODO: request to edit the metrics */
        if (editedMetrics.length) {
          await apiHook.editMetric({ edits: formatEditMetrics(editedMetrics), domain_id: workspace.id })
        }

        if (newMetrics.length) {
          const { ids } = await apiHook.addMetrics({ metrics: formatMetrics(newMetrics, defaultInput.id), domain_id: workspace.id })
          newMetrics.forEach((metric, index) => {
            metric.id = ids[index]
          })
        }
        /* TODO: request to add the new metrics */
        newProject.metrics = [...oldMetrics, ...newMetrics]
      }

      addProject(newProject)
    }
    setLoading(false)
    setCreating(false)
  }

  const handleMetric = (e, index) => {
    const copy = input.metrics.slice()
    copy[index] = {
      metric: e.target.value,
      theme: 'default',
      category: 'default',
      edit: true,
    }

    setInput({ ...input, metrics: copy })
  }

  const handleTheme = (e, index) => {
    const copy = input.metrics.slice()
    copy[index] = {
      ...copy[index],
      theme: e.target.value,
      category: 'default',
      edit: true,
    }

    setInput({ ...input, metrics: copy })
  }

  const handleCategory = (e, index) => {
    const copy = input.metrics.slice()
    copy[index] = { ...copy[index], category: e.target.value, edit: true }

    setInput({ ...input, metrics: copy })
  }

  const handleValues = (e, index) => {
    const copy = input.metrics.slice()
    copy[index] = { ...copy[index], [e.target.name]: e.detail.value, edit: true }
    setInput({ ...input, metrics: copy })
  }

  const pushMetric = () => {
    const copy = input.metrics.slice()
    copy.push({
      metric: 'default',
      category: 'default',
      theme: 'default',
      target: 0,
      current: 0,
      old: false,
    })
    setInput({ ...input, edit: true, metrics: copy })
  }

  const deleteMetric = (index) => {
    const copy = input.metrics.slice()
    const removed = copy.splice(index, 1)

    setInput({ ...input, edit: true, metrics: copy })

    /* Store ID for future request */
    if (removed.old) {
      setDeletedMetrics([...deleteMetric, removed.id])
    }
  }

  const handleBack = () => {
    if (input.edit) {
      ionAlert({
        header: 'Cancel project form?',
        message: 'Do you wish to continue? Your progress will be lost. ',
        buttons: [
          {
            text: 'No',
          },
          {
            text: 'Yes',
            handler: () => {
              setCreating(false)
              setView('Home')
              setCreateObjective(false)
            },
          },
        ],
      })
      return
    }
    setView('Home')
    setCreateObjective(false)
    setCreating(false)
  }

  useEffect(() => {
    if (edit && !defaultInput.objectives) {
      apiHook.getProjectDetails(defaultInput.id).then((response) => {
        apiHook.getMetricDetailed({ id: defaultInput.metric_ids }).then(({ metric }) => {
          addObjectives(defaultInput.id, crossReferenceObjectives(response), metric)
        })
      })
    }

    if (defaultInput.objectives) setInput(defaultInput)
  }, [defaultInput])

  useEffect(() => {
    apiHook.projectDropdowns({ domain_id: workspace.id }).then((data) => {
      /* Cross-reference categories */
      setStakeholders(data.stakeholders)
      setAssets(data.assets)
      setCommunities(data.communities)
    })
  }, [])

  return (
    <>
      {
        (addingObj) ? (
          <IonContent style={{ '--background': 'white' }}>
            <AddObjective
              users={users}
              index={focussedObj}
              defaultValues={(focussedObj !== null) ? input.objectives[focussedObj] : null}
              onCancel={() => setAddingObj(false)}
              editObjective={addObjective}
              project={{
                startDate: (input.startDate) ? input.startDate.format('YYYY-MM-DD HH:mm') : null,
                endDate: (input.endDate) ? input.endDate.format('YYYY-MM-DD HH:mm') : null,
              }}
            />
          </IonContent>
        ) : (
          <>
            <IonContent style={{ '--background': 'white', height: '90%' }}>
              <IonRow style={{ marginTop: '15px' }} className='ion-justify-content-start ion-align-items-center'>
                <SherpaButton onClick={handleBack}><Icon icon='dashicons:arrow-left-alt2' /></SherpaButton>
                <SettingField style={{ width: 'auto' }}><h4 style={{ margin: '0' }}>{ title }</h4></SettingField>
              </IonRow>
              <IonList style={{ contain: 'inherit', '--ion-background-color': 'white', padding: '15px 20px' }}>
                <IonRow>
                  <IonCol size={6}>
                    <IonItem lines='none'>
                      <SettingField>
                        <h5 className='blue'>Project Title</h5>
                        <IonInput
                          type='text'
                          placeholder='What is this project called?'
                          name='title'
                          value={input.title}
                          onIonChange={handleChange}
                        />
                      </SettingField>
                    </IonItem>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol size={6}>
                    <IonItem lines='none'>
                      <SettingField>
                        <h5 className='blue'>Category</h5>
                        {
                        (!edit) ? (
                          <Select
                            options={categories}
                            value={input.category}
                            onChange={(selected) => setInput({ ...input, edit: true, category: selected })}
                            placeholder='Choose a type'
                            // eslint-disable-next-line no-undef
                            menuPortalTarget={document.body}
                            className='projects-dropdown'
                          />
                        ) : (
                          <p> { input.category } </p>
                        )
                      }
                      </SettingField>
                    </IonItem>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol size={6}>
                    <SettingField>
                      <h5 className='blue'>Start Date</h5>
                      <Datetime
                        dateFormat='YYYY-MM-DD'
                        inputProps={{
                          placeholder: 'Target Date',
                          readOnly: true,
                          style: { backgroundColor: 'white' },
                        }}
                        isValidDate={(current) => current.isAfter(moment())}
                        name='startDate'
                        value={input.startDate}
                        onChange={(e) => setInput({ ...input, edit: true, startDate: e })}
                      />
                    </SettingField>
                  </IonCol>
                  <IonCol size={6}>
                    <SettingField>
                      <h5 className='blue'>End Date</h5>
                      <Datetime
                        dateFormat='YYYY-MM-DD'
                        inputProps={{
                          placeholder: 'Target Date',
                          readOnly: true,
                          style: { backgroundColor: 'white' },
                        }}
                        isValidDate={(current) => current.isAfter(moment())}
                        name='endDate'
                        value={input.endDate}
                        onChange={(e) => setInput({ ...input, edit: true, endDate: e })}
                      />
                    </SettingField>
                  </IonCol>
                </IonRow>
                <IonItem lines='none'>
                  <SettingField style={{ width: '40%' }}>
                    <h5 className='blue'>Project Lead</h5>
                    <Select
                      placeholder='Select'
                      name='project-lead'
                      className='select-container'
                      value={input.project_lead}
                    // eslint-disable-next-line no-undef
                      menuPortalTarget={document.body}
                      options={users.map((user) => ({ value: user.id, label: `${user.first_name} ${user.final_name}` }))}
                      onChange={(selected) => setInput({ ...input, edit: true, project_lead: selected })}
                    />
                  </SettingField>
                </IonItem>
                <IonItem lines='none'>
                  <SettingField style={{ width: '40%' }}>
                    <h5 className='blue'>Team Members</h5>
                    <Select
                      isMulti
                      placeholder='Select Team Members'
                      name='colors'
                      className='select-container'
                      id='team-members'
                      options={users.map((user) => ({ value: user.id, label: `${user.first_name} ${user.final_name}` }))}
                      value={input.team_members}
                      onChange={(selected) => setInput({ ...input, team_members: selected })}
                      isCreatable={false}
                      menuPortalTarget={document.body}
                    />
                  </SettingField>
                </IonItem>
                <IonItem lines='none'>
                  <SettingField>
                    <h5 className='blue'>Problem to be solved</h5>
                    <IonInput
                      type='text'
                      placeholder='What is the problem/issue that this project relates to?'
                      name='problem'
                      value={input.problem}
                      onIonChange={handleChange}
                    />
                  </SettingField>
                </IonItem>
                <IonItem lines='none'>
                  <SettingField style={{ width: '40%' }}>
                    <h5 className='blue'>Associated Stakeholders</h5>
                    <Select
                      isMulti
                      placeholder='Select Associated Stakeholders'
                      name='colors'
                      className='select-container'
                      id='associated-stakeholders'
                      options={stakeholders.map((stakeholder) => ({ value: stakeholder.id, label: stakeholder.name }))}
                      value={input.stakeholders}
                      onChange={(selected) => setInput({ ...input, stakeholders: selected })}
                      isCreatable={false}
                      menuPortalTarget={document.body}
                    />
                  </SettingField>
                </IonItem>
                <IonItem lines='none'>
                  <SettingField style={{ width: '40%' }}>
                    <h5 className='blue'>Associated Communities</h5>
                    <Select
                      isMulti
                      placeholder='Select Associated Communities'
                      name='colors'
                      className='select-container'
                      id='associated-communities'
                      options={communities.map((community) => ({ value: community.id, label: `${community.name}` }))}
                      value={input.communities}
                      onChange={(selected) => setInput({ ...input, communities: selected })}
                      isCreatable={false}
                      menuPortalTarget={document.body}
                    />
                  </SettingField>
                </IonItem>
                <IonItem lines='none'>
                  <SettingField style={{ width: '40%' }}>
                    <h5 className='blue'>Associated Key Assets</h5>
                    <Select
                      isMulti
                      placeholder='Select Key Assets'
                      name='colors'
                      className='select-container'
                      id='key-assets'
                      options={assets.map((asset) => ({ value: asset.id, label: `${asset.name}` }))}
                      value={input.assets}
                      onChange={(selected) => setInput({ ...input, assets: selected })}
                      isCreatable={false}
                      menuPortalTarget={document.body}
                    />
                  </SettingField>
                </IonItem>
                <IonItem lines='none'>
                  <SettingField style={{ width: '40%' }}>
                    <h5 className='blue'>Project Goal</h5>
                    <IonInput
                      type='text'
                      placeholder='What is the goal this project intends to achieve?'
                      name='goal'
                      value={input.goal}
                      onIonChange={handleChange}
                    />
                  </SettingField>
                </IonItem>
                <IonItem lines='none'>
                  <SettingField>
                    <h5 className='blue'>Summary</h5>
                    <IonInput
                      type='text'
                      placeholder='What is the summary of this project?'
                      name='summary'
                      value={input.summary}
                      onIonChange={handleChange}
                    />
                  </SettingField>
                </IonItem>
                <IonItem lines='none'>
                  <SettingField>
                    <h5 className='blue'>Support</h5>
                    <IonInput
                      type='text'
                      placeholder='What is the support of this project?'
                      name='support'
                      value={input.support}
                      onIonChange={handleChange}
                    />
                  </SettingField>
                </IonItem>
                {
                      input.category && external[0].options.find(({ value }) => input.category.value === value) && (
                        <SettingField>
                          <h5 className='blue'>IRIS Metrics</h5>
                          {
                            (loadingMetrics) ? (
                              <IonRow className='ion-justify-content-center' style={{ padding: '40px' }}>
                                <IonSpinner />
                              </IonRow>
                            ) : (
                              <>
                                {
                                  input.metrics && input.metrics.map(({
                                    metric, theme, category, old,
                                  }, index) => (
                                    <IonRow
                                      key={index}
                                      className='align-items-center ion-text-center'
                                    >
                                      <IonCol size={3}>
                                        {
                                          (edit && old) ? <p>{ metric }</p> : (
                                            <SimpleDropdown
                                              value={input.metrics[index].metric}
                                              onChange={(e) => handleMetric(e, index)}
                                            >
                                              <option value='default' disabled>Select a Metric</option>
                                              {
                                                Object.keys(irisMetrics).map((name) => (
                                                  <option value={name} key={name}>{name}</option>
                                                ))
                                              }
                                            </SimpleDropdown>
                                          )
                                        }
                                      </IonCol>
                                      <IonCol size={2}>
                                        {
                                          (edit && old) ? <p>{ theme }</p> : (
                                            <SimpleDropdown
                                              value={input.metrics[index].theme}
                                              onChange={(e) => handleTheme(e, index)}
                                            >
                                              <option value='default' disabled>Select a theme</option>
                                              {
                                                input.metrics[index].metric !== 'default' && (
                                                  Object.keys(irisMetrics[input.metrics[index].metric]).map((name) => (
                                                    <option value={name} key={name}>{name}</option>
                                                  ))
                                                )
                                              }
                                            </SimpleDropdown>
                                          )
                                        }
                                      </IonCol>
                                      <IonCol size={2}>
                                        {
                                          (edit && old) ? <p>{ category }</p> : (
                                            <SimpleDropdown
                                              value={input.metrics[index].category}
                                              onChange={(e) => handleCategory(e, index)}
                                            >
                                              <option value='default' disabled>Select a theme</option>
                                              {
                                                input.metrics[index].metric !== 'default' && input.metrics[index].theme !== 'default' && (
                                                  irisMetrics[input.metrics[index].metric][input.metrics[index].theme].map((name) => (
                                                    <option value={name} key={name}>{name}</option>
                                                  ))
                                                )
                                              }
                                            </SimpleDropdown>
                                          )
                                        }
                                      </IonCol>
                                      <IonCol size={2}>
                                        <IonItem>
                                          <SettingField>
                                            <h5>Current</h5>
                                            <IonInput
                                              value={input.metrics[index].current}
                                              name='current'
                                              placeholder='Perc.'
                                              type='number'
                                              onIonChange={(e) => handleValues(e, index)}
                                            />
                                          </SettingField>
                                        </IonItem>
                                      </IonCol>
                                      <IonCol size={2}>
                                        <IonItem>
                                          <SettingField>
                                            <h5 className='blue'>Target</h5>
                                            <IonInput
                                              value={input.metrics[index].target}
                                              name='target'
                                              placeholder='Perc.'
                                              type='number'
                                              onIonChange={(e) => handleValues(e, index)}
                                            />
                                          </SettingField>
                                        </IonItem>
                                      </IonCol>
                                      <IonCol size={1}>
                                        <SherpaButton onClick={() => deleteMetric(index)}> <Icon icon='bi:trash' /> </SherpaButton>
                                      </IonCol>
                                    </IonRow>
                                  ))
                                }
                                <SherpaButton onClick={pushMetric} style={{ fontSize: '16px', fontWeight: 'bold' }}>+ Add Metrics</SherpaButton>
                              </>
                            )
                          }
                        </SettingField>
                      )
                    }
              </IonList>
            </IonContent>
            <IonRow style={{ width: '100%', padding: '0 10px' }} className='ion-justify-content-end'>
              <IonButton style={{ '--background': '#8E151F' }} onClick={handleBack}>{ edit ? 'cancel' : 'cancel' }</IonButton>
              <IonButton style={{ '--background': '#326771' }} onClick={handleSubmit}>{ edit ? 'save' : 'create' }</IonButton>
            </IonRow>
          </>
        )
        }
    </>
  )
}

export default CreateProject
