import React, {
  useState, FC, useEffect, useRef,
} from 'react'
import { Feature, Polygon, Position } from '@turf/turf'
import mapboxgl from 'mapbox-gl'
import CreatableSelect from 'react-select/creatable'
import { IonButton, IonRow, useIonAlert } from '@ionic/react'
import { useTerrainMapping } from '../../../hooks/terrain-mapping/useTerrainMapping'
import { ObscureBackground, OverlayContainer } from '../../GlobalContainers'
import { FormContainer } from '../forms/MapForm'
import { SimpleButton } from '../StyledContainers'
import { createMB } from '../apps/AppMap'
import axios from '../../../utils/axios'
import { useWorkspace } from '../../../hooks/useWorkspace'
import { SelectedActionKind } from '../../../hooks/analyst/utils/Reducers'
import ImportComm from '../../incident-management/views/popups/ImportComm'

export enum FlowView {
  DRAW_SHAPE = 'shape',
  AREA_DETAILS = 'details',
}

const CommunityArea : FC = () => {
  const [currentView, setCurrentView] = useState<FlowView>(FlowView.DRAW_SHAPE)
  const [markers, setMarkers] = useState<mapboxgl.Marker[]>([])
  const [coordinates, setCoordinates] = useState<Position[]>([])
  const [layerDrawn, setLayerDrawn] = useState<boolean>(false)
  const { workspace } = useWorkspace()
  const [ionAlert] = useIonAlert()
  const {
    setLoading, setShowCommunityForm, mapRef, setSubmittedMessage,
    pushNewCommunity, stakeholders, showFilePopup, setShowFilePopup,
    setImported,
  } = useTerrainMapping()

  const handleCancel = () => {
    if (markers.length > 0) { markers.map((val) => val.remove()) }
    setShowCommunityForm(false)
  }

  const handleNext = () => {
    setCurrentView(FlowView.AREA_DETAILS)
  }

  const handleSubmitImages = (files: FileList) => {
    const formData = new FormData()
    Array.from(files).forEach((val) => {
      formData.append('files[]', val)
    })
    setLoading(true)
    axios.post('/api/v1/asset/upload_asset', formData)
      .then(({ data }) => {
        setImported(data.coords)
        setShowFilePopup(false)
        setLoading(false)
      })
  }

  const handleSubmit = (
    name: string, description: string, selected: { value: number, label: string }[], history: string,
    facts: string, social_profile: string, econ_profile: string, land_tenure: string, ldr: string, commType: string,
    parent: { value: number, label: string }, gov: { value: number, label: string }[], head: { value: number, label: string },
    imported: any,
  ) => {
    let feature = {
      type: 'Feature',
      properties: {},
      geometry: {
        type: 'Polygon',
        coordinates: [coordinates],
      },
    }

    if (imported) {
      feature = imported[0]
    }

    setLoading(true)
    axios.post('/api/v2/community/create_community', {
      domain_id: workspace.id,
      description,
      name,
      geoData: feature,
      stakeholders: selected?.map(({ value }) => value),
      history,
      facts,
      social_profile,
      econ_profile,
      land_tenure,
      ldr,
      type: commType,
      parent: parent?.value,
      governing: gov?.map(({ value }) => value),
      head: head?.value,
    }).then(({ data }) => {
      if (data.message === 'you have not created a community.') {
        ionAlert({
          header: 'Server Error',
          message: data.message,
          buttons: [{ text: 'Ok' }],
        })
        return
      }

      const filteredStakeholders = stakeholders.filter(({ id }) => selected.find(({ value }) => id === value))

      pushNewCommunity({
        id: data.community.id, name, description, geoData: feature,
      }, filteredStakeholders)

      markers.map((val) => val.remove())
      setSubmittedMessage('Area created successfully')
    }).catch(() => {
      ionAlert({
        header: 'Server Error',
        message: 'Unknown server error',
        buttons: [{ text: 'Ok' }],
      })
    }).finally(() => {
      setShowCommunityForm(false)
      setLoading(false)
    })
  }

  useEffect(() => {
    createMB(mapRef, [])
    return () => {
      mapRef.current.removeLayer('new-restriction-box')
      if (mapRef.current.getLayer('new-layer')) mapRef.current.removeLayer('new-layer')
      mapRef.current.removeSource('new-restriction-box')
    }
  }, [])

  if (currentView === FlowView.DRAW_SHAPE) {
    return (
      <DrawShapeView
        markers={markers}
        setMarkers={setMarkers}
        coordinates={coordinates}
        setCoordinates={setCoordinates}
        onCancel={handleCancel}
        onNext={handleNext}
        layerDrawn={layerDrawn}
        setLayerDrawn={setLayerDrawn}
      />
    )
  }

  return (
    <>
      {
        showFilePopup ? (
          <>
            <ObscureBackground />
            <ImportComm
              onClose={() => setShowFilePopup(false)}
              onSubmit={handleSubmitImages}
            />
          </>
        ) : (
          <AreaDetails
            onSubmit={handleSubmit}
            onBack={handleCancel}
          />
        )
      }
    </>
  )
}

interface DrawProps {
  markers: mapboxgl.Marker[],
  setMarkers: React.Dispatch<React.SetStateAction<mapboxgl.Marker[]>>,
  coordinates: Position[],
  setCoordinates: React.Dispatch<React.SetStateAction<Position[]>>,
  onCancel: () => void,
  onNext: () => void,
  layerDrawn: boolean,
  setLayerDrawn: React.Dispatch<React.SetStateAction<boolean>>,
  editing?: boolean,
}

export const DrawShapeView : FC<DrawProps> = ({
  onNext, onCancel, coordinates, setCoordinates, setMarkers, markers,
  setLayerDrawn, layerDrawn, editing,
}) => {
  const { mapRef, setShowFilePopup } = useTerrainMapping()
  const [alert] = useIonAlert()

  const handleUndoPin = () => {
    if (coordinates.length === 0) { return }
    const newCoordinates = coordinates.slice()
    newCoordinates.splice(newCoordinates.length - 1, 1)

    if (layerDrawn) {
      newCoordinates.splice(newCoordinates.length - 1, 1)
      mapRef.current.removeLayer('new-layer')
      setLayerDrawn(false)
    }

    setCoordinates(newCoordinates);

    /* Redraw layer */
    (mapRef.current.getSource('new-restriction-box') as mapboxgl.GeoJSONSource).setData({
      type: 'Feature',
      properties: {},
      geometry: {
        type: 'LineString',
        coordinates: newCoordinates,
      },
    })

    /* Remove marker from map */
    const marker = markers.pop()
    marker.remove()
    setMarkers([...markers])
  }

  const updateLayer = (positions: Position[]) => {
    /* Update layer */
    (mapRef.current.getSource('new-restriction-box') as mapboxgl.GeoJSONSource).setData({
      type: 'Feature',
      properties: {},
      geometry: {
        type: (layerDrawn) ? 'Polygon' : 'LineString',
        coordinates: ((layerDrawn) ? [positions] : positions as any),
      },
    })
  }

  const setCurrentPoint = ([lon, lat] : Position, index: number) => {
    if (!coordinates.length) return

    const positions = coordinates.slice()

    /* Change last markers position */
    markers[index].setLngLat([lon, lat])

    /* Update location */
    positions.splice(index, 1, [lon, lat])
    if (index === 0 && layerDrawn) positions.splice(coordinates.length - 1, 1, [lon, lat])

    setCoordinates(positions)
    updateLayer(positions)
  }

  /* Use reference to keep the state updated */
  const setCurrentPointRef = useRef(setCurrentPoint)
  setCurrentPointRef.current = setCurrentPoint

  const drawLayer = () => {
    if (layerDrawn) { return }
    if (coordinates.length < 3) {
      alert({
        header: 'Error',
        message: 'You need to draw a polygon with at least 3 vertices',
        buttons: [
          { text: 'Ok' },
        ],
      })

      return
    }

    const newCoordinates = [...coordinates, coordinates[0]];

    (mapRef.current.getSource('new-restriction-box') as mapboxgl.GeoJSONSource).setData({
      type: 'Feature',
      properties: {},
      geometry: {
        type: 'LineString',
        coordinates: newCoordinates,
      },
    })

    mapRef.current.addLayer({
      id: 'new-layer',
      type: 'fill',
      source: 'new-restriction-box',
      layout: {},
      paint: {
        'fill-color': 'rgb(65, 151, 169)', // blue color fill
        'fill-opacity': 0.5,
      },
    })

    setCoordinates(newCoordinates)
    setLayerDrawn(true)
  }

  /* Use of reference to keep the state updated */
  const drawLayerRef = useRef(drawLayer)
  drawLayerRef.current = drawLayer

  const addNewLocation = (position: mapboxgl.LngLat) : Position[] => {
    const marker = (markers.length === 0) ? new mapboxgl.Marker({ draggable: true, color: 'rgb(109, 0, 235)' }) : new mapboxgl.Marker({ draggable: true })
    marker.setLngLat(position).addTo(mapRef.current)

    /* Add Draggable listener */
    marker.on('drag', () => {
      setCurrentPointRef.current([marker.getLngLat().lng, marker.getLngLat().lat], coordinates.length)
    })

    /* If first marker add listener to close polygon */
    if (coordinates.length === 0) {
      marker.getElement().onclick = (event) => {
        event.stopPropagation()
        drawLayerRef.current()
      }
    }

    const updated = [...coordinates, [position.lng, position.lat]]
    setCoordinates(updated)
    setMarkers([...markers, marker])

    return updated
  }

  const handleClick = (e: mapboxgl.MapMouseEvent & mapboxgl.EventData) => {
    if (layerDrawn) { return }
    mapRef.current.easeTo({ center: [e.lngLat.lng, e.lngLat.lat], zoom: (mapRef.current.getZoom() < 9) ? 9 : mapRef.current.getZoom() })
    const newCoordinates = addNewLocation(e.lngLat);

    (mapRef.current.getSource('new-restriction-box') as mapboxgl.GeoJSONSource).setData({
      type: 'Feature',
      properties: {},
      geometry: {
        type: 'LineString',
        coordinates: newCoordinates,
      },
    })
  }

  const handleClickRef = useRef(handleClick)
  handleClickRef.current = handleClick

  useEffect(() => {
    const onClick = (e: mapboxgl.MapMouseEvent & mapboxgl.EventData) => {
      handleClickRef.current(e)
    }

    mapRef.current.on('click', onClick)
    return () => {
      mapRef.current.off('click', onClick)
    }
  }, [])

  return (
    <FormContainer className='add-community-popup' style={{ height: 'auto', backgroundColor: 'white' }}>
      <IonRow className='ion-align-items-center ion-justify-content-between'>
        <h5>
          {
            editing ? (
              <>Edit Neighbourhood/Community</>
            ) : (
              <>Add Neighbourhood/Community</>
            )
          }
        </h5>
        <SimpleButton onClick={onCancel}>Close X</SimpleButton>
      </IonRow>
      {
        editing ? (
          <>
            <p>Click a pin to select it. You can drag the pin to change the shape of the area on the map.</p>
            <p>Use the controls below to add or remove pins.</p>
            <p>Click ‘Next’ to edit the area’s details.</p>
          </>
        ) : (
          <>
            <p>Click on the map to begin drawing out the area you wish to cover.</p>.
            <p>Continue to click on the map to add subsequent points and create a shape.</p>
            <p>To close the shape, click on the first point you placed.</p>
            <p>You can undo the last point by clicking ‘Undo Pin’.</p>
            <p>Once finished, click ‘Next’ to continue.</p>
            <p>
              <button type='button' onClick={() => {onNext(), setShowFilePopup(true)}} style={{ background: 'none', border: 'none', color: 'blue', cursor: 'pointer' }}>
                Import Community
              </button>
            </p>
          </>
        )
      }
      <IonRow style={{ marginTop: '30px' }} className='ion-justify-content-between ion-align-items-center'>
        <IonButton onClick={handleUndoPin} style={{ '--background': '#4197A9' }}>Undo Pin</IonButton>
        <IonRow>
          <IonButton onClick={onCancel} style={{ '--background': '#8E151F' }}>Cancel</IonButton>
          <IonButton onClick={onNext} style={{ '--background': '#326771' }}>Next</IonButton>
        </IonRow>
      </IonRow>
    </FormContainer>
  )
}

DrawShapeView.defaultProps = {
  editing: false,
}

interface DetailsProps {
  onSubmit: (name: string, description: string, stakeholders: { value: number, label: string }[],
    history: string, facts: string, social_profile: string, econ_profile: string, land_tenure: string,
    ldr: string, type: string, selectedParent: any, selectedGoverningBody: any, selectedCommHead: any,
    imported: any,
  ) => void,
  onBack: () => void,
  defaultName?: string,
  defaultDescription?: string,
  defaultStakeholders?: { value: number, label :string }[],
  defaultComm: any,
}

export const AreaDetails : FC<DetailsProps> = ({
  onSubmit, onBack, defaultName, defaultDescription, defaultStakeholders, defaultComm,
}) => {
  const [name, setName] = useState<string>(defaultName)
  const [description, setDescription] = useState<string>(defaultDescription)

  const [history, setHistory] = useState<string>(defaultComm?.history)
  const [facts, setFacts] = useState<string>(defaultComm?.facts)
  const [socialProfile, setSocialProfile] = useState<string>(defaultComm?.social_profile)
  const [econProfile, setEconProfile] = useState<string>(defaultComm?.econ_profile)
  const [landTenure, setLandTenure] = useState<string>(defaultComm?.land_tenure)
  const [ldr, setLdr] = useState<string>(defaultComm?.ldr)
  const [commType, setCommType] = useState<string>(defaultComm?.community_type)

  const { stakeholders, communities, imported } = useTerrainMapping()

  const [selectedStakeholders, setSelectedStakeholders] = useState<{ value: number, label :string }[]>(defaultStakeholders)
  const [selectedParent, setSelectedParent] = useState<{ value: number, label: string } | undefined>(() => {
    const community = communities.find((comm) => comm.id === defaultComm?.parent_id)
    return community ? { label: community.name, value: community.id } : undefined
  })
  const [selectedCommHead, setSelectedCommHead] = useState<{ value: number, label: string } | undefined>(() => {
    const stakeholder = stakeholders.find((s) => s.id === defaultComm?.head_id)
    return stakeholder ? { label: stakeholder.name, value: stakeholder.id } : undefined
  })
  const [selectedGoverningBody, setSelectedGoverningBody] = useState<{ value: number, label: string }[]>(() =>
    defaultComm?.governors.map((governor) => {
      const stakeholder = stakeholders.find((s) => s.id === governor?.governor_id)
      return stakeholder ? { label: stakeholder?.name, value: stakeholder?.id } : undefined
    }).filter(Boolean))

  const [ionAlert] = useIonAlert()

  const handleSubmit = () => {
    if (name === '') {
      ionAlert({
        header: 'Error',
        message: 'New community must have a new name',
        buttons: [{ text: 'ok' }],
      })

      return
    }

    if (description === '') {
      ionAlert({
        header: 'Error',
        message: 'No description provided',
        buttons: [{ text: 'ok' }],
      })

      return
    }

    onSubmit(name, description, selectedStakeholders, history, facts, socialProfile,
      econProfile, landTenure, ldr, commType, selectedParent, selectedGoverningBody, selectedCommHead, imported)
  }

  return (
    <OverlayContainer style={{ zIndex: 25, overflow: 'auto', maxHeight: '500px' }} className='terrain-add-popup'>
      <div className='risk-assessment-list'>
        <h5>Add Neighbourhood/Community</h5>
        <div className='terrain-form-field'>
          <h6>Name</h6>
          <input type='text' value={name} onChange={(e) => setName(e.target.value)} />
        </div>
        <div className='terrain-form-field'>
          <h6>Community Type</h6>
          <select
            value={commType}
            onChange={(e) => setCommType(e.target.value)}
            style={{ padding: '5px 10px' }}
          >
            <option value=''>Select a type</option>
            <option value='ADM4'>Admin Level 4</option>
            <option value='ADM5'>Admin Level 5</option>
            <option value='Rural/Urban/Suburban'>Rural/Urban/Suburban</option>
            <option value='Cultural/Ethnic'>Cultural/Ethnic</option>
            <option value='Geographic'>Geographic</option>
            <option value='Informal Settlemen'>Informal Settlement</option>
            <option value='Creation and Edit'>Creation and Edit</option>
            <option value='Other'>Other</option>
          </select>
        </div>
        <div className='terrain-form-field'>
          <h6>Community Head</h6>
          <CreatableSelect
            placeholder='Select Community Head'
            name='colors'
            className='select-container'
            id='journey-passengers'
              // eslint-disable-next-line no-undef
            menuPortalTarget={document.body}
            options={stakeholders.filter(({ type }) => type === 'Individual').map(({ id, name }) => ({ label: name, value: id }))}
            value={selectedCommHead}
            onChange={(selected) => setSelectedCommHead(selected)}
          />
        </div>
        <div className='terrain-form-field'>
          <h6>Governing Body</h6>
          <CreatableSelect
            isMulti
            placeholder='Select Governing Stakeholders'
            name='colors'
            className='select-container'
            id='journey-passengers'
              // eslint-disable-next-line no-undef
            menuPortalTarget={document.body}
            options={stakeholders.filter(({ type }) => type === 'Individual').map(({ id, name }) => ({ label: name, value: id }))}
            value={selectedGoverningBody}
            onChange={(selected) => setSelectedGoverningBody(selected)}
          />
        </div>
        <div className='terrain-form-field'>
          <h6>Associated Stakeholders</h6>
          <CreatableSelect
            isMulti
            placeholder='Select Stakeholders'
            name='colors'
            className='select-container'
            id='journey-passengers'
              // eslint-disable-next-line no-undef
            menuPortalTarget={document.body}
            options={stakeholders.map((val) => ({ value: val.id, label: val.name }))}
            value={selectedStakeholders}
            onChange={(selected) => setSelectedStakeholders(selected)}
          />
        </div>
        <div className='terrain-form-field form-desc'>
          <h6>Description</h6>
          <textarea value={description} onChange={(e) => setDescription(e.target.value)} />
        </div>
        <div className='terrain-form-field'>
          <h6>Parent Community</h6>
          <CreatableSelect
            placeholder='Select Parent Community'
            name='colors'
            className='select-container'
            id='journey-passengers'
              // eslint-disable-next-line no-undef
            menuPortalTarget={document.body}
            options={communities.map((val) => ({ value: val.id, label: val.name }))}
            value={selectedParent}
            onChange={(selected) => setSelectedParent(selected)}
          />
        </div>
        <div className='terrain-form-field form-desc'>
          <h6>Key Facts</h6>
          <textarea
            value={facts}
            onChange={(e) => setFacts(e.target.value)}
            placeholder='Type of information: physical geography, population size and make-up,
            numbers and size of towns, nature and size of self-distinguishing groups (ethnic, tribal, etc),
            sites of special significance (environmental, religious, etc), mined areas, climate, social indicators (poverty, literacy, health, etc), religion(s), language(s).'
          />
        </div>
        <div className='terrain-form-field form-desc'>
          <h6>History</h6>
          <textarea
            value={history}
            onChange={(e) => setHistory(e.target.value)}
            placeholder='Type of information: Distinguishing historical background, political status of the region within the country, 
            political evolution, past conflicts, disputes with central government, background to any previous industry investment, inward/outward migration, relations with neighbouring regions.'
          />
        </div>
        <div className='terrain-form-field form-desc'>
          <h6>Social Profile</h6>
          <textarea
            value={socialProfile}
            onChange={(e) => setSocialProfile(e.target.value)}
            placeholder='Type of information: Local governance structures, local elections, 
            village/community structures, presence of armed forces, security situation, human rights situation, 
            status of local media/judiciary/civil society, existence and status of minorities, status of women, 
            importance of religion, presence of IDPs, return of refugees.'
          />
        </div>
        <div className='terrain-form-field form-desc'>
          <h6>Economical Profile</h6>
          <textarea
            value={econProfile}
            onChange={(e) => setEconProfile(e.target.value)}
            placeholder='Type of information: Industries, agricultural base, natural resources, (un)employment, rural/urban mix, 
            development projects, SMEs, business associations, trade with other regions.'
          />
        </div>
        <div className='terrain-form-field form-desc'>
          <h6>Land Tenure</h6>
          <textarea
            value={landTenure}
            onChange={(e) => setLandTenure(e.target.value)}
            placeholder='Type of information: legal framework governing land tenure; different types of land tenure arrangements such as customary, 
            communal, state-owned, private, or a combination of these; 
            land ownership patterns; the extent of land registration and formal documentation; 
            how land is utilized and accessed in the community; gender dynamics related to land tenure; ongoing or past land tenure reforms external factors affecting land tenure in the community.'
          />
        </div>
        <div className='terrain-form-field form-desc'>
          <h6>Local Dispute Resolution</h6>
          <textarea
            value={ldr}
            onChange={(e) => setLdr(e.target.value)}
            placeholder='Type of information: mechanisms employed for dispute resolution, such as customary systems, courts, or alternative dispute resolution methods.'
          />
        </div>
        <IonRow className='ion-justify-content-end'>
          <IonButton onClick={onBack} style={{ '--background': '#8E151F' }}>Cancel</IonButton>
          <IonButton onClick={handleSubmit} style={{ '--background': '#0C9500' }}>Submit</IonButton>
        </IonRow>
      </div>
    </OverlayContainer>
  )
}

AreaDetails.defaultProps = {
  defaultName: '',
  defaultStakeholders: [],
  defaultDescription: '',
}

export default CommunityArea
