/* eslint-disable */
import React from 'react'
import {
  IonButton,
} from '@ionic/react'

import { useHistory } from 'react-router-dom'
import testFunctionality from '../hooks/testHooks'
import { saveAs } from 'file-saver'
import { useWorkspace } from '../hooks/useWorkspace'
import { useNotification } from '../hooks/useNotification'

//  This code exists purely for a preliminary test of the reporting functionality.
// It is not intended for production, nor for any form of Beta-Testing.

const IncidentReportSubmitterTest = ({ returnTo }) => {
  const test = testFunctionality()
  const history = useHistory()
  const workspace = useWorkspace()
  const notification = useNotification()

  // the datetime value for 'reported' MUST be given in the format 'YYYY-MM-DD HH:MM:SS', for instance, '2021-07-20 09:00:55'
  const handleIncidentClicked = () => {
    test.tryIncident('small problem', '', '0.5', '0.7', '2021-07-20 09:00:55', 'Armed clash', '3', 1,1,'Edinburgh')
      .then((data) => {
        alert(data.message)
      })
  }

  const handleInsightClicked = () => {
    test.tryInsight('small problem', '', '0.5', '0.7', '2021-07-20 09:00:55', [5,5,5,5], [5,5,5,5,5,5,5,5], '30', 1)
      .then((data) => {
        alert(data.message)
      })
  }

  const addBoxClicked = () => {
    test.addBox('24', '120', 'false', 'false', 'false', 'false', 'false', 'false', '{"type":"Feature","properties":{},"geometry":{"type":"LineString","coordinates":[[29.756972475452898,7.462599956684528],[29.84678959783153,7.101280190990636],[29.377744625407075,6.9774745278263595],[29.25798846223597,7.393328432725539],[29.756972475452898,7.462599956684528]]}}', '2021-08-20 09:00:55', '2021-09-20 10:00:55', '4', 'BEWARE!!!', 1, [1],'Green',[1])
      .then((data) => {
        alert(data.message)
      })
  }

  const retrieveAllReports = () => {
    test.getIncidents(1,'last_week')
      .then((data) => {
        alert(data)
        console.log(data)
      })
  }
  
  const incidentsByCountyClicked = () => {
    test.getIncidentsByCounty()
      .then((data) => {
        alert(data)
      })
  }

  const retrieveAllCounties = () => {
    test.getCounties()
      .then((data) => {
        alert(data)
        console.log(data)
      })
  }

  const tester = () => {
    test.tester({
      user_id: 1,
      notification_id: 14, 
    })
      .then((data) => {
        alert(data)
        console.log(data)
      })
  }

  const anonymiseUsers = () => {
    const id = prompt('Enter the id of the user you want to anonymise:')
    test.anonymiseUsers({
      id: id,
    })
      .then((data) => {
        alert(data)
        console.log(data)
      })
  }

  const getEfficientCounties = () => {
    test.getEfficientCounties({
      domain_id: 1,
    })
      .then((data) => {
        alert(data)
        console.log(data)
      })
  }

  const getAllCountries = () => {
    test.getAllCountries({
      domain_id: 1,
    })
      .then((data) => {
        alert(data)
        console.log(data)
      })
  }


  const getAllRegions = () => {
    test.getAllRegions({
      domain_id: 1,
    })
      .then((data) => {
        alert(data)
        console.log(data)
      })
  }


  const getAllCounties = () => {
    test.getAllCounties({
      domain_id: 1,
    })
      .then((data) => {
        alert(data)
        console.log(data)
      })
  }


  const MetricByArchangel = () => {
    test.MetricByArchangel({
      user_id: 1,
      notification_id: 14, 
    })
      .then((data) => {
        alert(data)
        console.log(data)
      })
  }

  const systemSafeties = () => {
    const currentDate = new Date().toISOString().slice(0, 10) // Get the current date in ISO format (YYYY-MM-DD)
    const filename = `safeties_${currentDate}.xlsx`
    test.systemSafeties({
      domain_id: 1,
    })
      .then((data) => {
        downloader(data, filename)
      })
  }

  const systemPulsepoints = () => {
    const currentDate = new Date().toISOString().slice(0, 10) // Get the current date in ISO format (YYYY-MM-DD)
    const filename = `pulsepoints_${currentDate}.xlsx`
    test.systemPulsepoints({
      domain_id: 1,
    })
      .then((data) => {
        downloader(data, filename)
      })
  }


  function s2ab2(s) {
    var buf = new ArrayBuffer(s.length);
    var view = new Uint8Array(buf);
    for (var i=0; i!=s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
    return buf;
  }

  function downloader2(data, filename) {
    console.log(data)
    console.log(data instanceof File)

    var blob = new Blob([s2ab2(atob(data))], {
      type: "application/pdf"
    });


    //if using send_data, create file to download with blob
    const url = window.URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.download = filename
    link.href = URL.createObjectURL(blob)

    //document.body.appendChild(link)
    link.click()
    URL.revokeObjectURL(link.href)
  }

  const downloadSecurityChange = () => {
    test.downloadSecurityChange({
      domain_id: 1,
      notification_id: 14, 
    })
      .then((data) => {
        alert(data)
        downloader2(data, "Security Change.pdf")

      })
  }

  const securityPopup = () => {
    test.securityPopup({
      domain_id: 1,
      notification_id: 14, 
    })
      .then((data) => {
        //alert(data)
        console.log(data)
      })
  }

  const dashboardInsights = () => {
    test.dashboardInsights({
      domain_id: 1,
    })
      .then((data) => {
        //alert(data)
        console.log(data)
      })
  }

  const dashboardJourneys = () => {
    test.dashboardJourneys({
      domain_id: 1,
    })
      .then((data) => {
        //alert(data)
        console.log(data)
      })
  }

  const dashboardEngagements = () => {
    test.dashboardEngagements({
      domain_id: 1,
    })
      .then((data) => {
        //alert(data)
        console.log(data)
      })
  }

  const weeklyReport = () => {
    test.weeklyReport({
      domain_id: 1,
      index: 1
    })
      .then((data) => {
        //alert(data)
        console.log(data)
      })
  }

  const weeklyReportPDF = () => {
    test.weeklyReportPDF({
      domain_id: 1,
      index: 1
    })
      .then((data) => {
        //alert(data)
        console.log(data)
        downloader2(data, "testing.pdf")
      })
  }

  const situationReport = () => {
    test.situationReport({
      domain_id: 1,
      county_id: 1, 
    })
      .then((data) => {
        //alert(data)
        console.log(data)
      })
  }

  const setEmergencyTeams = () => {
    test.setEmergencyTeams({
      domain_id: 1,
      non_critical: [{ user_id: 1, role: "Non Crit Lead"}],
      emergency_response: [{ user_id: 1, role: "Team Leader"}],
      incident_management: [{ user_id: 1, role: "Inci Lead"}],
      crisis_management: [{ user_id: 1, role: "Crisis Lead"}, { user_id: 2, role: "Crisis Follower"}]
    })
      .then((data) => {
        alert(data)
        console.log(data)
      })
  }

  const getEmergencyTeams = () => {
    test.getEmergencyTeams({
      domain_id: 1,
    })
      .then((data) => {
        alert(data)
        console.log(data)
      })
  }  

  const retrieveAllBoxes = () => {
    test.getMovebox(1)
      .then((data) => {
        alert(data)
        console.log(data)
      })
  }

  const addRouteClicked = () => {
    test.tryPath('Example', 'Glasgow', 'Dundee', '{"type":"Feature","properties":{},"geometry":{"type":"LineString","coordinates":[[29.023302,7.735726],[29.025989,7.737046],[29.029012,7.738639],[29.031823,7.740093],[29.037603,7.743067],[29.041535,7.745115],[29.044325,7.74655],[29.049099,7.749016],[29.051043,7.750017],[29.052975,7.751029],[29.053659,7.75147],[29.054149,7.751773],[29.055869,7.753003],[29.056639,7.75366],[29.057561,7.754471],[29.058272,7.755165],[29.058763,7.75567],[29.059152,7.756103],[29.060394,7.757567],[29.060608,7.757853],[29.060394,7.757567],[29.059152,7.756103],[29.058763,7.75567],[29.058272,7.755165],[29.057561,7.754471],[29.056639,7.75366],[29.055869,7.753003],[29.054149,7.751773],[29.053659,7.75147],[29.052975,7.751029],[29.051043,7.750017],[29.049099,7.749016],[29.044325,7.74655],[29.041535,7.745115],[29.037603,7.743067],[29.031823,7.740093],[29.029012,7.738639],[29.025989,7.737046],[29.023302,7.735726]]}}', 1)
      .then((data) => {
        alert(data.message)
      })
  }

  const retrieveAllRoutes = () => {
    test.getPaths()
      .then((data) => {
        alert(data)
        console.log(data)
      })
  }

  const addTaskClicked = () => {
    test.assignTask('Test','','false','2021-12-30 09:00:55','Insight','0',['1'])
      .then((data) => {
        alert(data.message)
        console.log(data)
      })
  }

  const retrieveAllUsers = () => {
    const id = prompt('Enter the ID of your domain:')
    test.getUsers(Number(id))
      .then((data) => {
        alert(data)
        console.log(data)
      })
  }

  const updateStatusClicked = () => {
    test.updateStatus('8','Complete')
      .then((data) => {
        alert(data.message)
      })
  }

  const archiveClicked = () => {
    test.archiveTask('8')
      .then((data) => {
        alert(data.message)
      })
  }

  const retrieveSomeTasks = () => {
    test.getSomeTasks()
      .then((data) => {
        alert(data)
        console.log(data)
      })
  }
  
  const retrieveArchivedTasks = () => {
    test.getArchivedTasks()
      .then((data) => {
        alert(data)
        console.log(data)
      })
  }

  const editTaskClicked = () => {
    test.editTask({}
      [{ 
        id: 2,
        description: 'Placeholder Text.',
      }]
    )
      .then((data) => {
        alert(data)
        console.log(data)
      })
  }
  
  const getActiveJourneys = () => {
    test.getJourneys()
      .then((data) => {
        alert(data)
        console.log(data)
      })
  }

  const requestJourneyClicked = () => {
    test.requestJourney('', 6, 'Retrieve A Pizza.', 'Buzz Lightyear', '000000000', 'Sherrif Woody', '000000000', 'TARDIS', 'Dear TimeLords, IOU one TARDIS, signed- The Doctor', '000000000', 'Semaphore towers', '2022-07-30 09:00:55', 'Juba', '2022-07-29 09:00:55', 'Morobo', 'Tom,Dick,Harry', 'The Ritz', 'London', '000000000', 'Premier Inn', 'Norwich', '000000000', [1,2,3])
      .then((data) => {
        alert(data.message)
        console.log(data)
      })
  }

  const progressJourneyClicked = () => {
    test.progressJourney(7,'Because I felt like it, bite me.')
      .then((data) => {
        alert(data.message)
        console.log(data)
      })
  }

  const denyJourneyClicked = () => {
    test.denyJourney(7,'Risk of bad weather.')
      .then((data) => {
        alert(data.message)
        console.log(data)
      })
  }

  const commentJourneyClicked = () => {
    test.commentJourney(18,'Stopped at Roadblock')
      .then((data) => {
        alert(data.message)
        console.log(data)
      })
  }

  const getRolesClicked = () => {
    test.getRolesbyUser(-1)
      .then((data) => {
        alert(data.message)
        console.log(data)
      })
  }

  const editUsers = () => {
    test.editUser({
      first_name: 'Matthew',
      final_name: 'Innes',
      email: 'minnes@trunshaw.co'
    })
      .then((data) => {
        alert(data.message)
        console.log(data)
      })
  }

  const changePassword = () => {
    test.changePassword({
      password: '8password8',
      password1: '7tester7',
      password2: '7tester7'
    })
      .then((data) => {
        alert(data.message)
        console.log(data)
      })
  }

  const createMessage = () => {
    test.createMessage({
      message: "Testing if the message streaming works with a hook",
      room_id: 1
    })
      .then((data) => {
        alert(data.message)
        console.log(data)
      })
  }

  const createRoom = () => {
    test.createRoom({
      recipients: [1,1]
    })
      .then((data) => {
        alert(data.message)
        console.log(data)
      })
  }

  const createBreakout = () => {
    test.createBreakout({
      recipients: [1,2,3],
      domain_id: 1,
      incident_id: 2,
      name: "Breakout Test",
      
    })
      .then((data) => {
        alert(data.message)
        console.log(data)
      })
  }

  
  const getIncidentsForDomain = () => {
    test.getIncidentsForDomain({
      domain_id: 1,
    })
      .then((data) => {
        alert(data.message)
        console.log(data)
      })
  }

  const getInsightsForDomain = () => {
    test.getInsightsForDomain({
      domain_id: 1,
    })
      .then((data) => {
        alert(data.message)
        console.log(data)
      })
  }


  const ReportsIncidentPopup = () => {
    test.ReportsIncidentPopup({
      user_id: 1,
      county_id: 1,
    })
      .then((data) => {
        alert(data.message)
        console.log(data)
      })
  }
  
  const getRooms = () => {
    test.getRooms()
      .then((data) => {
        alert(data)
        console.log(data)
      })
  }

  const getBreakouts = () => {
    test.getBreakouts()
      .then((data) => {
        alert(data)
        console.log(data)
      })
  }

  const editBreakout = () => {
    test.editBreakout({
      room_id: 32,
      add: [4],
      remove: [2],
      name: "Edited Breakout"
    })
      .then((data) => {
        alert(data)
        console.log(data)
      })
  }

  const leaveBreakout = () => {
    test.leaveBreakout({
      room_id: 28,
    })
      .then((data) => {
        alert(data)
        console.log(data)
      })
  }

  const leaveChat = () => {
    test.leaveChat({
      room_id: 30,
    })
      .then((data) => {
        alert(data)
        console.log(data)
      })
  }

  const changeHost = () => {
    test.changeHost({
      room_id: 3,
      new_host: 3,
    })
      .then((data) => {
        alert(data)
        console.log(data)
      })
  }

  const getMessages = () => {
    test.getMessages({
      room_id: 1
    })
      .then((data) => {
        alert(data)
        console.log(data)
      })
  }

  const tryWorkspaceClicked = () => {
    const id = prompt('Enter the ID of your domain:')
    test.tryWorkspace(Number(id),'New Domain','South Sudan')
      .then((data) => {
        alert(data.message)
        console.log(data)
      })
  }

  const inviteUsersClicked = () => {
    const id = prompt('Enter the ID of your domain:')
    test.inviteToWorkspace(Number(id),['minnes@trubshaw.co','jmendez@trubshaw.co'])
      .then((data) => {
        alert(data.message)
        console.log(data)
      })
  }

  const processInvitationClicked = () => {
    const id = prompt('Enter the ID of your domain:')
    test.processWorkspaceInvite(Number(id),true)
      .then((data) => {
        alert(data.message)
        console.log(data)
      })
  }
  
  const promoteUsersClicked = () => {
    const id = prompt('Enter the ID of your domain:')
    test.promoteInWorkspace(Number(id),[7,8])
      .then((data) => {
        alert(data.message)
        console.log(data)
      })
  }

  const demoteUsersClicked = () => {
    const id = prompt('Enter the ID of your domain:')
    test.demoteInWorkspace(Number(id),[7,8])
      .then((data) => {
        alert(data.message)
        console.log(data)
      })
  }

  const killWorkspaceClicked = () => {
    const id = prompt('Enter the ID of your domain or archangel to kill:')
    test.killWorkspace(Number(id))
      .then((data) => {
        alert(data.message)
        console.log(data)
      })
  }

  const getWorkspacesClicked = () => {
    test.seeWorkspace()
      .then((data) => {
        alert(data.message)
        console.log(data)
      })
  }

  const editWorkspace = () => {
    const id = prompt('Enter the ID of your domain:')
    test.editWorkspace({
      domain_id: Number(id),
      name: 'Testing SOME Edit',
      country: 'AL'
    })
      .then((data) => {
        alert(data.message)
        console.log(data)
      })
  }

  const incarnateSelfClicked = () => {
    const id = prompt('Enter the ID of your domain:')
    test.incarnateIntoWorkspace(Number(id))
      .then((data) => {
        alert(data.message)
        console.log(data)
      })
  }

  const retrieveOneWorkspace = () => {
    const id = prompt('Enter the ID of your domain:')
    test.getWorkspace(Number(id))
      .then((data) => {
        alert(data.message)
        console.log(data)
      })
  }

  const recursiveMoveBox = () => {
    const id = prompt('Enter the ID of your domain:')
    test.getMovebox(Number(id))
      .then((data) => {
        alert(data.message)
        console.log(data)
      })
  }

  const editJourney = () => {
    test.editJourney({
      journey_id: 2,
      purpose: 'Testing Journey Edit',
      leader_name: 'TestJLeader',
      leader_phone: '07777777',
      driver_name: 'TestJDriver',
      driver_phone: '088888888',
      vehicle_type: 'Tester AMG',
      vehicle_reg: 'TT33GG55',
      emergency_sat: '06666666',
      emergency_other: '0555555',
      departure_time: '2021-08-30 16:00:00',
      departure_location: 'Here',
      arrival_time: '2021-08-30 17:00:00',
      arrival_location: 'There',
      passengers: 'passenger1passenger2passenger3',
      driver_hotel: 'Hotel Trivago',
      driver_contact: '044444444',
      driver_location: 'There',
      passenger_hotel: 'Hotel Transylvania',
      passenger_contact: '022222222',
      passenger_location: 'There',
      routes_remove: [1],
      routes_add: [1],
      routes_keep: [2]
    })
      .then((data) => {
        alert(data.message)
        console.log(data)
      })
  }

  const editIncident = () => {
    test.editIncident({
      domain_id: 1,
      incidents: [{ 
        id: 2,
        name: 'Incident Edit1',
        description: 'Testing to see whether the incident edit works',
        latitude: 0.5,
        longitude: 0.5,
        incident_type: "RTA",
        fatalities: 2
      }, { 
        id: 1,
        name: 'Incident Edit2',
        description: 'Testing to see whether the incident edit works',
        latitude: 0.5,
        longitude: 0.5,
        incident_type: "RTA",
        fatalities: 2
      }]
    })
      .then((data) => {
        alert(data.message)
        console.log(data)
      })
  }

  //TEST EDIT ALERT STATES
  const editAlertState = () => {
    test.editAlertState({
      domain_id: 1,
      keyphrases: [1,2],
      new_alert_state: 3,
      alerts: [{
        county_id: 1,
      }, {
        county_id: 2,
      }]
    })
      .then((data) => {
        alert(data.message)
        console.log(data)
      })
  }

  const editMovementBox = () => {
    test.editMovementBox({
      domain_id: 1,
      movement_box_id: 1,
      advance_notice_hours: 12,
      check_in_minutes: 60,
      senior_manager_approval: true,
      executive_manager_approval: true,
      security_escort: true,
      anti_vampire_rules: true,
      convoy: true,
      forbidden: true,
      from: "2022-08-20 09:00:55",
      to: "2022-08-20 09:00:55", 
      check_in_days: 7,
      user_list: [1,2],
    })
      .then((data) => {
        alert(data.message)
        console.log(data)
      })
  }

  const archiveMovementBox = () => {
    test.archiveMovementBox({
      domain_id: 1,
      movement_box_id: 1,
    })
      .then((data) => {
        alert(data.message)
        console.log(data)
      })
  }

  const trackerResponse = () => {
    test.trackerResponse({
      response: 'accepted',
      tracker_id: 13, //taskId part of noti
    })
      .then((data) => {
        alert(data.message)
        console.log(data)
      })
  }

  const getLocationHistory = () => {
    test.getLocationHistory({
     // tracker_id: 1, //taskId part of noti
    })
      .then((data) => {
        alert(data.message)
        console.log(data)
      })
  }

  const trackerHit = () => {
    test.trackerHit({
      response: 'accepted',
      box_id: 10, //taskId part of noti
      latitude: 30,
      longitude: 8,

    })
      .then((data) => {
        alert(data.message)
        console.log(data)
      })
  }


  
  const get_avg_alert = () => {
    test.get_avg_alert(1, 100)
    .then((data) => {
      alert(data.message)
      console.log(data)
    })
}

  const banishUsersClicked = () => {
    const id = prompt('Enter the ID of your domain:')
    test.banishUsers(Number(id),[2])
      .then((data) => {
        alert(data.message)
        console.log(data)
      })
  }

  const anointUserClicked = () => {
    const id = prompt('Enter the ID of your role:')
    test.anointUser(Number(id))
      .then((data) => {
        alert(data.message)
        console.log(data)
      })
  }

//for resetting passwords
  const resetPasswordClicked = () => {
    const id = prompt('Enter the email of the user you want:')
    test.resetPassword(id)
      .then((data) => {
        alert(data.message)
        console.log(data)
      })
  }

  const deleteIncident = () => {
    test.deleteIncident({
      domain_id: 1,
      incidents: [2,3],
    })
    .then((data) => {
      alert(data.message)
      console.log(data)
    })
  }

  const projectDropdowns = () => {
    test.projectDropdowns({
      domain_id: 1,
    })
    .then((data) => {
      alert(data.message)
      console.log(data)
    })
  }


  const addProjectClicked = () => {
    const id = prompt('Enter the ID of your domain:')
    test.createProject('Building a gigantic chair-crushing machine.', '2023-08-30 17:00:00', Number(id), 'Too many chairs.', 'Fewer chairs.', '2021-08-30 17:00:00', 'Internal', 1)
      .then((data) => {
        alert(data.message)
        console.log(data)
      })
  }

  const getProjectsClicked = () => {
    const id = prompt('Enter the ID of your domain:')
    test.getProjects(Number(id))
      .then((data) => {
        alert(data.message)
        console.log(data)
      })
  }

  const addMetricsClicked = () => {
    test.addMetrics(
      {
        domain_id: 1,
        metrics:
        [{
          metric_type: "User Metric",
          category: "Tester",
          current: 50,
          target: 20,
          project_id: 1,
          unit: "some unit thingy",
          is_integer: true,
          metric_name: "Student Fails Per Second",
          theme: "theme"
        },
        {
          metric_type: "IRIS Metric",
          category: "Agriculture",
          current: 50,
          target: 20,
          project_id: 1,
          unit: "some unit thingy",
          is_integer: true,
          metric_name: "Crop Type",
          theme: "Sustainable Agriculture"
        }]
      })
      .then((data) => {
        alert(data.message)
        console.log(data.ids)
      })
  }

  const archiveUserMetric = () => {
    test.archiveUserMetric({
      id: 1,
      domain_id: 1,
    })
      .then((data) => {
        alert(data.message)
      })
  }

  const archiveMetric = () => {
    test.archiveMetric({
      id: 1,
      domain_id: 1,
    })
      .then((data) => {
        alert(data.message)
      })
  }

  const editMetric = () => {
    test.editMetric({
      domain_id: 1,
      edits:
      [{
        id: 1,
        current: 77,
        target: 77
      },
      {
        id: 2,
        current: 77,
        target: 77
      }]
    })
    .then((data) => {
      alert(data.message)
      console.log(data)
    })
  }

  const projectDetailsClicked = () => {
    const id = prompt('Enter the ID of your project:')
    test.getProjectDetails(Number(id))
      .then((data) => {
        alert(data.message)
        console.log(data)
      })
  }

  const makeObjectiveClicked = () => {
    const id = prompt('Enter the ID of your project:')
    test.createObjective(
      Number(id),
      [{ 
        title: 'Buy materials for a chair-crusher.',
        owner: 1,
        description: 'Not enough chair-crushing materials. We must have enough materials to CRUSH ALL THE CHAIRS!!!',
        start: '2021-09-30 17:00:00',
        deadline: '2023-07-30 17:00:00',     
      }, { 
        title: 'Assemble the chair-crusher',
        owner: 1,
        description: 'We must construct the device!!!',
        start: '2021-09-30 17:00:00',
        deadline: '2023-07-30 17:00:00',
      }]
  )
      .then((data) => {
        alert(data.message)
        console.log(data)
      })
  }

  const abortObjectiveClicked = () => {
    const id = prompt('Enter the ID of your objective:')
    test.abort_objective(Number(id))
      .then((data) => {
        alert(data.message)
        console.log(data)
      })
  }

  const editUserMetric = () => {
    test.editUserMetric({
      domain_id: 1,
      id: 1,
      definition: "editing definition",
      guide: "editing guide",
      unit: "editing unit",
    })
      .then((data) => {
        alert(data.message)
        console.log(data)
      })
  }

  
  const progressObjectiveClicked = () => {
    const id = prompt('Enter the ID of your objective:')
    test.progress_objective(Number(id))
      .then((data) => {
        alert(data.message)
        console.log(data)
      })
  }

  const createUserMetric = () => {
    test.createUserMetric({
      domain_id: 1,
      category: "Tester",
      metric_name: "Student Fails Per Second",
      unit: "pogs per second",
      definition: "something",
      guide: "Hitchhikers guide to the galaxy"
    })
      .then((data) => {
        alert(data.message)
      })
  }

  const createAsset = () => {
    test.createAsset({
      domain_id: 1,
      description: "Home of the best play of the games",
      name: "Pog University",
      longitude: 0.5,
      latitude: 0.5,
      asset_type: "People",
    })
      .then((data) => {
        alert(data.message)
      })
  }

  const createAssetRisk = () => {
    test.createAssetRisk({
      domain_id: 1,
      asset_id: 1, 
      risk_id: 1, 
      vulnerability: 1, 
      intent: 1, 
      capability: 1, 
      impact: [1,1,1,1], 
      description: "description",
      risk_event: "Risk Name", 
      vulnerability: "Low", 
      intent: "Medium", 
      capability: "High", 
      impact: ["Low","Medium", "High","Very Low"], 
      description: "description",
      residual_vulnerability: "Low",
      residual_impact: ["Very Low", "Low", "Medium", "Extreme"],
      existing: ["Solid metal gate"],
      new: ["Solid metal gate"],
      control_measure: ["New Control Measure"],
      add_measures: [
        {control_measure: "On Creation Control Measure", category: "Testing"}, 
        {control_measure: "On Here Control Measure", category: "Memes"},
      ]
    })
      .then((data) => {
        alert(data.message)
      })
  }

  const addControlMeasure = () => {
    test.addControlMeasure({
      control_measures: [
        {control_measure: "New Control Measure", category: "Testing"}, 
        {control_measure: "Some Control Measure", category: "Memes"},
      ],
      domain_id: 1,
    })
      .then((data) => {
        alert(data.message)
      })
  }

  const getFormMeasures = () => {
    test.getFormMeasures({
      domain_id: 1,
    })
      .then((data) => {
        alert(data.message)
        console.log(data)
      })
  }

  const deprecateControlMeasure = () => {
    test.deprecateControlMeasure({
      measures: [1,2],
      domain_id: 1,
    })
      .then((data) => {
        alert(data.message)
      })
  }

  const editAssetRisk = () => {
    test.editAssetRisk({
      domain_id: 1,
      id: 1, 
      vulnerability: "Extreme", 
      intent: "Extreme", 
      capability: "Extreme", 
      impact: ["Extreme","Extreme", "Extreme","Extreme"], 
      description: "description edited",
      residual_vulnerability: "Extreme",
      residual_impact: ["Extreme", "Extreme", "Extreme", "Extreme"],
      existing_add: ["Solid metal gate"],
      new_add: ["Solid metal gate"],
      existing_remove: ["Solid metal gate"],
      new_remove: ["Solid metal gate"],
      add_measures: [
        {control_measure: "Cheese and biscuits.", category: "Testing"}, 
        {control_measure: "Bread and Butter.", category: "Memes"},
      ]
    })
      .then((data) => {
        alert(data.message)
      })
  }

  const createRisk = () => {
    test.createRisk({
      domain_id: 1,
      name: "Risk Name", 
      description: "description"
    })
      .then((data) => {
        alert(data.message)
      })
  }

  const createMitigation = () => {
    test.createMitigation({
      domain_id: 1,
      asset_risk_id: 1,
      title: " Mitigation Title", 
      description: "description",
      status: "Existing", 
      control_measure: "Solid metal gate"
    })
      .then((data) => {
        alert(data.message)
      })
  }

  const removeMitigation = () => {
    test.removeMitigation({
      id: 1,
    })
      .then((data) => {
        alert(data.message)
      })
  }

  const editAsset = () => {
    test.editAsset({
      domain_id: 1,
      description: "Home of the best memes",
      name: "Meme University",
      longitude: 0.7,
      latitude: 0.7,
      asset_id: 1,
      asset_type: "People"
    })
      .then((data) => {
        alert(data.message)
      })
  }

  const getMetricDetailed = () => {
    test.getMetricDetailed({
      id: [1,2]
    })
      .then((data) => {
        alert(data.message)
        console.log(data)
      })
  }

  const regressObjectiveClicked = () => {
    const id = prompt('Enter the ID of your objective:')
    test.regress_objective(Number(id))
      .then((data) => {
        alert(data.message)
        console.log(data)
      })
  }

  const abortProjectClicked = () => {
    const id = prompt('Enter the ID of your project:')
    test.abort_objective(Number(id))
      .then((data) => {
        alert(data.message)
        console.log(data)
      })
  }

  const getMetrics = () => {
    test.getMetrics({
      domain_id: 1,
      project_id: 1,
    })
      .then((data) => {
        alert(data.message)
        console.log(data)
      })
  }

  const help = () => {
    test.help({
      message: "Testing help center message",
    })
      .then((data) => {
        alert(data.message)
        console.log(data)
      })
  }

  
  const progressProjectClicked = () => {
    const id = prompt('Enter the ID of your project:')
    test.progress_project(Number(id))
      .then((data) => {
        alert(data.message)
        console.log(data)
      })
  }

  const getIrisMetrics = () => {
    test.getIrisMetrics()
      .then((data) => {
        alert(data.message)
        console.log(data)
      })
  }

  const regressProjectClicked = () => {
    const id = prompt('Enter the ID of your project:')
    test.regress_objective(Number(id))
      .then((data) => {
        alert(data.message)
        console.log(data)
      })
  }

  const getUserMetrics = () => {
    test.getUserMetrics({
      domain_id: 1
    })
      .then((data) => {
        alert(data.message)
        console.log(data)
      })
  }

  const addTaskToObjective = () => {
    const id = prompt('Enter the ID of your objective:')
    test.createObjective(
      Number(id),
      [{ 
        title: 'Get quotes from metalworking companies',
        description: 'Ask various suppliers of high-quality metalworks for quotes on the time/price to supply chair-crushing materials.',
        due: '2023-01-30 17:00:00',
        priority: false,
        assignees: [1],
      }]
  )
      .then((data) => {
        alert(data.message)
        console.log(data)
      })
  }

  const editProjectClicked = () => {
    test.editProject({
      id: 1,
      title: 'blank',
      problem: 'blank',
      goal: 'blank',
      start: '2020-07-30 17:00:00',
      deadline: '2023-07-30 15:00:00',
    })
      .then((data) => {
        alert(data)
        console.log(data)
      })
  }

  function s2ab(s) {
    var buf = new ArrayBuffer(s.length);
    var view = new Uint8Array(buf);
    for (var i=0; i!=s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
    return buf;
  }

  function downloader(data, filename) {
    console.log(data)
    console.log(data instanceof File)

    var blob = new Blob([s2ab(atob(data))], {
      type: "application/xlsx"
    });


    //if using send_data, create file to download with blob
    const url = window.URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.download = filename
    link.href = URL.createObjectURL(blob)

    //document.body.appendChild(link)
    link.click()
    URL.revokeObjectURL(link.href)
  }

  const downloadAssets = () => {
    test.downloadAssets({
      domain_id: 1,
    })
      .then((data) => {
        alert(data)
        downloader(data, "Asset Register.xlsx")

      })
  }

  const downloadRisks = () => {
    test.downloadRisks({
      domain_id: 1,
      asset_id: 4,
    })
      .then((data) => {
        alert(data)
        downloader(data, "Risk Register.xlsx")

      })
  }

  const editObjectiveClicked = () => {
    test.editObjective({
      objectives: [{ 
        id: 2,
        title: 'blank',
        owner: 1,
        description: 'blank',
        start: '2020-07-30 17:30:00',
        deadline: '2023-07-30 14:30:00',     
      }, { 
        id: 1,
        title: 'blank',
      }]
    })
      .then((data) => {
        alert(data.message)
        console.log(data)
      })
  }

  const deleteObjectives = () => {
    test.deleteObjectives({
      domain_id: 1,
      ids: [1,2]
    })
      .then((data) => {
        alert(data.message)
        console.log(data)
      })
  }

  const getAllAssets = () => {
    const id = prompt('Enter the ID of your domain:')
    test.getAssets(Number(id))
      .then((data) => {
        alert(data.message)
        console.log(data)
      })
  }

  const getAllAssetsByCounty = () => {
    test.getAssetsByCounty({
      domain_id: 1
    })
      .then((data) => {
        alert(data.message)
        console.log(data)
      })
  }

  const getSpecificAsset = () => {
    const id = prompt('Enter the ID of your asset:')
    test.getAsset(Number(id))
      .then((data) => {
        alert(data.message)
        console.log(data)
      })
  }

  const getMitigations = () => {
    const id = prompt('Enter the ID of your assetRisk:')
    test.getMitigations(Number(id))
      .then((data) => {
        alert(data.message)
        console.log(data)
      })
  }

  const getRiskTypes = () => {
    const id = prompt('Enter the ID of your domain:')
    test.riskTypes(Number(id))
      .then((data) => {
        alert(data.message)
        console.log(data)
      })
  }

  const getAssetTypes = () => {
    test.assetTypes()
      .then((data) => {
        alert(data.message)
        console.log(data)
      })
  }

  const archiveAssetsClicked = () => {
    test.archiveAssets([1,2,3])
      .then((data) => {
        alert(data.message)
      })
  }

  const archiveAssetRisksClicked = () => {
    test.archiveAssetRisks([1,2,3])
      .then((data) => {
        alert(data.message)
      })
  }

  const getAllAssetDetails = () => {
    const id = prompt('Enter the ID of your asset:')
    test.getAssetDetails(Number(id))
      .then((data) => {
        alert(data.message)
        console.log(data)
      })
  }

  const getInterestsClicked = () => {
    const id = prompt('Enter the ID of your domain:')
    test.getCountiesOfInterest(Number(id))
      .then((data) => {
        alert(data.message)
        console.log(data)
      })
  }

  const declareInterestsClicked = () => {
    const id = prompt('Enter the ID of your domain:')
    test.declareInterestInCounties([1,2],Number(id))
      .then((data) => {
        alert(data.message)
        console.log(data)
      })
  }

  const denounceInterestsClicked = () => {
    const id = prompt('Enter the ID of your domain:')
    test.denounceInterestInCounties([1,2],Number(id))
      .then((data) => {
        alert(data.message)
        console.log(data)
      })
  }

const incidentsPerArchangel = () => {
  test.incidentsPerArchangel(
  )
  .then((data) => {
    alert(data.message)
    console.log(data)
  })
}

const insightsPerArchangel = () => {
  test.insightsPerArchangel()
  .then((data) => {
    alert(data.message)
    console.log(data)
  })
}

const totalArchangels = () => {
  test.totalArchangels()
  .then((data) => {
    alert(data.message)
    console.log(data)
  })
}

const totalUsers = () => {
  test.totalUsers()
  .then((data) => {
    alert(data.message)
    console.log(data)
  })
}

  const archivePathClicked = () => {
    const id = prompt('Enter the ID of your path:')
    test.archivePath(Number(id))
      .then((data) => {
        alert(data.message)
        console.log(data)
      })
  }

  const getDomainSafeties = () => {
    const id = prompt('Enter the ID of your domain:')
    test.getAllSafeties(Number(id))
      .then((data) => {
        alert(data.message)
        console.log(data)
      })
  }

  const promptDomainSafeties = () => {
    const id = prompt('Enter the ID of your domain:')
    test.promptAllSafeties(Number(id))
      .then((data) => {
        alert(data.message)
        console.log(data)
      })
  }

  const submitSafetyCheck = () => {
    const id = prompt('Enter your ID:')
    test.submitOneSafety({
      domain_id: 1,
      user_id: 1,
      longitude: 7,
      latitude: 7,
      level: "Green",
    })
      .then((data) => {
        alert(data.message)
      })
  }
  
  const promptSafetyCheck = () => {
    const user_id = prompt('Enter a user ID:')
    const domain_id = prompt('Enter a domain ID:')
    test.promptOneSafety(user_id, domain_id)
      .then((data) => {
        alert(data.message)
      })
  }

  const getLunarMemories = () => {
    test.getLunar()
      .then((data) => {
        alert(data.message)
        console.log(data)
      })
  }

  const getAllKeyphrases = () => {
    test.getKeyphrases()
    .then((data) => {
      alert(data.message)
      console.log(data)
    })
  }

  const getAllAnalyses = () => {
    test.getAnalyses()
    .then((data) => {
      alert(data.message)
      console.log(data)
    })
  }

  const submitSomeAnalyses = () => {
    test.editAlertState({
      keyphrases: [1,2],
      reccomendation: 3,
      alerts: [{
        county_id: 1,
      }, {
        county_id: 2,
      }]
    })
      .then((data) => {
        alert(data.message)
        console.log(data)
      })
    }

  const getMyAnalyses = () => {
    test.getMyAnalyses()
    .then((data) => {
      alert(data.message)
      console.log(data)
    })
  }

  const createInternalIncident = () => {
    test.createInternalIncident({
      domain_id: 1, description: 'Testing', taken: 'Testing', required: 'Testing', reported: '2023-08-30 17:00:00',
      people: 1, property: 1, operations: 1, incident_type: 'Internal', user_id:1, archived: false, involved: false,
      longitude: 4.859363, latitude: 31.571251, comments: "this is a comment", title: 'Incident title',
      primary_title: 'primary title', primary_category: 'Incident category', primary_type: 'Kidnapping',
      actors: [], causes: [], secondary_incidents: [], internal_actors: [{id:1, type: 'test'}]
    })
      .then((data) => {
        alert(data.message)
        console.log(data)
      })
  }

  const fetchInternalIncidents = () => {
    test.fetchInternalIncidents({
      domain_id: 1
    })
      .then((data) => {
        alert(data.message)
        console.log(data)
      })
  }

  const fetchInternalIncident = () => {
    test.fetchInternalIncident({
      domain_id: 1,
      incident_id: 17,
    })
      .then((data) => {
        alert(data.message)
        console.log(data)
      })
  }

  const setResponseLevels = () => {
    test.setResponseLevels({
      domain_id: 1,

      non_critical: ["Armed Attack", "Theft", "Assault", ],
      emergency_response: ["Armed Robbery"],
      incident_management: ["Cattle Raid", "Hijacking"],
      crisis_management: ["Peaceful Protest", "Kidnapping"]
    })
      .then((data) => {
        alert(data.message)
        console.log(data)
      })
  }

  const getResponseLevels = () => {
    test.getResponseLevels({
      domain_id: 1,
    })
      .then((data) => {
        alert(data.message)
        console.log(data)
      })
  }

  
  const createTemplateSop = () => {
    test.createTemplateSop({
      domain_id: 1,
      template_type: 'default',
      sop_type: 'Kidnapping',
      inherit: false,
    })
      .then((data) => {
        alert(data.message)
        console.log(data)
      })
  }

  const getTemplateSops = () => {
    test.getTemplateSops({
      domain_id: 1,
    })
      .then((data) => {
        alert(data.message)
        console.log(data)
      })
  }

  const editProcedures = () => {
    test.editProcedures({
      domain_id: 1,
      template_id: 9,
      new: [{action: 'Do something', level: 'Emergency Response', responsibility: 'Team Leader', procedure_type: 'Immediate'},
            {action: 'Check on the logistics planning', level: 'Emergency Response', responsibility: 'Logistics Operator', procedure_type: 'Immediate'}],
      edit: [],
    })
      .then((data) => {
        alert(data.message)
        console.log(data)
      })
  }

  const deleteProcedure = () => {
    test.deleteProcedure({
      domain_id: 1,
      procedure_id: 1,
    })
      .then((data) => {
        alert(data.message)
        console.log(data)
      })
  }

  const getProcedures = () => {
    test.getProcedures({
      domain_id: 1,
      template_id: 9,
    })
      .then((data) => {
        alert(data.message)
        console.log(data)
      })
  }

  const createSops = () => {
    test.createSops({
      domain_id: 1,
      sop_types: ['Kidnapping']
    })
      .then((data) => {
        alert(data.message)
        console.log(data)
      })
  }

  const getSops = () => {
    test.getSops({
      domain_id: 1,
    })
      .then((data) => {
        alert(data.message)
        console.log(data)
      })
  }

  const deleteSop = () => {
    test.deleteSop({
      domain_id: 3,
      sop_id: 3,
    })
      .then((data) => {
        alert(data.message)
        console.log(data)
      })
  }

  const getUserList = () => {
    test.getUserList({
      domain_id: 1,
      box_id: 10,
    })
      .then((data) => {
        alert(data.message)
        console.log(data)
      })
  }

  const createFlashpoint = () => {
    test.createFlashpoint({
      domain_id: 1,
      title: "Flashpoint Create Test",
      description: "Testing if the create flashpoint hook is working",
      triggers: "the triggers for this flashpoint",
      status: "testing status",
      primary_manifestation: "testing primary manifestation",
      ongoing: false,
      comments: "any extra additon comments here",
      primary_stakeholders: [1],
      secondary_stakeholders: [1],
      tertiary_stakeholders: [1],
      manifestations: ['manifest 1', 'manifest 2'],
      issues: [1,2],
      community: [1],
    })
      .then((data) => {
        alert(data.message)
        console.log(data)
      })
  }

  const editFlashpoint = () => {
    test.editFlashpoint({
      flashpoint_id: 6,
      domain_id: 1,
      title: "edited",
      description: "Testing if the edit flashpoint hook is working",
      triggers: "the edit for this flashpoint",
      status: "edit status",
      primary_manifestation: "testing edit manifestation",
      ongoing: true,
      comments: "any extra edit comments here",
      //
      primary_stakeholders_remove: [1],
      primary_stakeholders_add: [2],

      secondary_stakeholders_remove: [1],
      secondary_stakeholders_add: [2],

      tertiary_stakeholders_remove: [1],
      tertiary_stakeholders_remove: [2],

      manifestations_remove: ['manifest 1', 'manifest 2'],
      manifestations_add: ['edit 1', 'edit 2'],

      issues_remove: [1,2],
      issues_add: [3,4],

      community_remove: [1],
      community_add: [2],

    })
      .then((data) => {
        alert(data.message)
        console.log(data)
      })
  }

  const getFlashpoints = () => {
    test.getFlashpoints({
      domain_id: 1,
    })
      .then((data) => {
        alert(data.message)
        console.log(data)
      })
  }


  const createIssue = () => {
    test.createIssue({
      domain_id: 1,
      title: 'testing',
      received: '2015-07-07 10:10:10',
      description: 'Testing',
      issues: [1,2],
      stakeholders: [1],
      community: [1],
    })
      .then((data) => {
        alert(data.message)
        console.log(data)
      })
  }

  const editIssue = () => {
    test.editIssue({
      domain_id: 1,
      issue_id: 6,
      title: 'edited',
      received: '2015-07-07 10:10:10',
      description: 'editing',

      issues_add: [3,4],
      issues_remove: [1,2],

      stakeholders_add: [2],
      stakeholders_remove: [1],

      community_add: [2],
      community_remove: [1],
    })
      .then((data) => {
        alert(data.message)
        console.log(data)
      })
  }

  const getIssues = () => {
    test.getIssues({
      domain_id: 1,
    })
      .then((data) => {
        alert(data.message)
        console.log(data)
      })
  }

  const createCommitment = () => {
    test.createCommitment({
      domain_id: 1,
      title: 'Test Commitment',
      type: 'Testing',
      date: '2021-07-20 09:00:55',
      description: 'Test Commitment description',
      owner: 1,
      issues: [1,2],
      community: [1],
      stakeholders: [1],
      supports: [1],
    })
      .then((data) => {
        alert(data.message)
        console.log(data)
      })
  }

  const editCommitment = () => {
    test.editCommitment({
      domain_id: 1,
      commitment_id: 9,
      title: 'edit Commitment',
      type: 'editing',
      date: '2021-07-20 09:00:55',
      description: 'Test edit description',
      owner: 2,
      issues_add: [3,4],
      issues_remove: [1,2],
      community_remove: [1],
      community_add: [2],
      stakeholders_remove: [1],
      stakeholders_add: [2],
      supports: [1],
    })
      .then((data) => {
        alert(data.message)
        console.log(data)
      })
  }

  const getCommitments = () => {
    test.getCommitments({
      domain_id: 1,

    })
      .then((data) => {
        alert(data.message)
        console.log(data)
      })
  }

  const createPoi = () => {
    test.createPoi({
      domain_id: 1,
      category: 'Test',
      name: 'Testing',
      description: 'Test Poi description',
      type: 'tester type',
      longitude: 7,
      latitude: 8,
      geoData: '{"type":"Feature","properties":{},"geometry":{"type":"LineString","coordinates":[[29.023302,7.735726],[29.025989,7.737046],[29.029012,7.738639],[29.031823,7.740093],[29.037603,7.743067],[29.041535,7.745115],[29.044325,7.74655],[29.049099,7.749016],[29.051043,7.750017],[29.052975,7.751029],[29.053659,7.75147],[29.054149,7.751773],[29.055869,7.753003],[29.056639,7.75366],[29.057561,7.754471],[29.058272,7.755165],[29.058763,7.75567],[29.059152,7.756103],[29.060394,7.757567],[29.060608,7.757853],[29.060394,7.757567],[29.059152,7.756103],[29.058763,7.75567],[29.058272,7.755165],[29.057561,7.754471],[29.056639,7.75366],[29.055869,7.753003],[29.054149,7.751773],[29.053659,7.75147],[29.052975,7.751029],[29.051043,7.750017],[29.049099,7.749016],[29.044325,7.74655],[29.041535,7.745115],[29.037603,7.743067],[29.031823,7.740093],[29.029012,7.738639],[29.025989,7.737046],[29.023302,7.735726]]}}',
    })
      .then((data) => {
        alert(data.message)
        console.log(data)
      })
  }

  const getPois = () => {
    test.getPois({
      domain_id: 1,
    })
      .then((data) => {
        alert(data.message)
        console.log(data)
      })
  }

  const createStakeholder = () => {
    test.createStakeholder({
      domain_id: 1,
      name: 'Afzal Miah',
      alias_name: 'testing alias',
      type: 'testing type',
      community_id: 1,
      comments: 'commenting here',
      quadrant: 'Top right quadrant',
      interests: ['tester'],
      organisations: [1],
      groups: [],
      phone_number: '++447938661110'
    })
      .then((data) => {
        alert(data.message)
        console.log(data)
      })
  }

  const getStakeholders = () => {
    test.getStakeholders({
      domain_id: 1,
    })
      .then((data) => {
        alert(data.message)
        console.log(data)
      })
  }

  const createEngagementNote = () => {
    test.createEngagementNote({
      domain_id: 1,
      subject: 'test subject',
      type: 'testing',
      date: '2015-07-09 09:00:06',
      longitude: 7.77,
      latitude: 7.77,
      community_id: 1,
      aim: 'tester aim',
      reps: [1,2],
      tones: ['tone', 'tone 2'],
      stakeholders: [1],
      supports: ['supprts 1', 'support 2']
    })
      .then((data) => {
        alert(data.message)
        console.log(data)
      })
  }

  const getEngagementNotes = () => {
    test.getEngagementNotes({
      domain_id: 1,
    })
      .then((data) => {
        alert(data.message)
        console.log(data)
      })
  }

  const createCommunity = () => {
    test.createCommunity({
      domain_id: 1,
      geoData: '{"type":"Feature","properties":{},"geometry":{"type":"LineString","coordinates":[[29.756972475452898,7.462599956684528],[29.84678959783153,7.101280190990636],[29.377744625407075,6.9774745278263595],[29.25798846223597,7.393328432725539],[29.756972475452898,7.462599956684528]]}}',
      name: 'Tester Community',
      description: 'Test Description',
    })
      .then((data) => {
        alert(data.message)
        console.log(data)
      })
  }

  const getCommunities = () => {
    test.getCommunities({
      domain_id: 1,
    })
      .then((data) => {
        alert(data.message)
        console.log(data)
      })
  }

  const userStats = () => {
    const currentDate = new Date().toISOString().slice(0, 10) // Get the current date in ISO format (YYYY-MM-DD)
    const filename = `Users_${currentDate}.xlsx`
    test.userStats({
      domain_id: 1,
    })
      .then((data) => {
        downloader(data, filename)
      })
  }

  const createCampaign = () => {
    test.createCampaign({
      domain_id: 1,
      title: 'Campaign test',
      campaign_type: 'bulletin',
      date: '2015-08-09 12:00:00',
      message: 'other bulletin desc or survey description',
      answer_type: 'yes/no or free text',
      option_1: 'eggs',
      option_2: 'bacon',
      option_3: 'oj',
      option_4: 'coffee',
      recipient_lists: [1,2],
      new_lists: [{name: 'newlist1', stakeholders: [1,2]}, {name: 'newlist2', stakeholders: [3,4]}],  //ids od stakeholder for new list
    })
      .then((data) => {
        alert(data.message)
        console.log(data)
      })
  }

  const getCampaigns = () => {
    test.getCampaigns({
      domain_id: 1,
    })
      .then((data) => {
        alert(data.message)
        console.log(data)
      })
  }

  const getCampaignStats = () => {
    test.getCampaignStats({
      domain_id: 1,
      campaign_id: 22,
    })
      .then((data) => {
        alert(data.message)
        console.log(data)
      })
  }

  const getRecipientsLists = () => {
    test.getRecipientsLists({
      domain_id: 1,
    })
      .then((data) => {
        alert(data.message)
        console.log(data)
      })
  }

  const getLatestDMs = () => {
    test.getLatestDMs({
      domain_id: 1,
    })
      .then((data) => {
        alert(data.message)
        console.log(data)
      })
  }

  const getDMs = () => {
    test.getDMs({
      domain_id: 1,
      stakeholder: 1,
    })
      .then((data) => {
        alert(data.message)
        console.log(data)
      })
  }

  const sendDM = () => {
    test.sendDM({
      domain_id: 1,
      stakeholder: 1,
      message: 'testing message'
    })
      .then((data) => {
        alert(data.message)
        console.log(data)
      })
  }

  const getNotis = () => {
    notification.getAllNotifications({

    })
      .then((data) => {
        alert(data.message)
        console.log(data)
      })
  }

  const submitPulsepoint = () => {
    test.submitPulsepoint({
      domain_id: 1,
      user_id: 1,
      longitude: -0.1270,
      latitude: 51.5194,
      level: "Blue",
      date: '2024-09-05 09:00:55',
    })
      .then((data) => {
        console.log(data)
      })
  }

  
  const createIssuePulsepoint = () => {
    test.createIssue({
      domain_id: 1,
      title: 'testing',
      received: '2015-07-07 10:10:10',
      description: 'Testing',
      issues: [1,2],
      stakeholders: [1],
      community: [1],
      lng: 7,
      lat: 8,
      type: 'tester'
    })
      .then((data) => {
        alert(data.message)
        console.log(data)
      })
  }

  const getPulsepoints = () => {
    test.getPulsepoints({
      domain_id: 1,
    })
      .then((data) => {
        console.log(data)
      })
  }

  const getTimesheets = () => {
    test.getTimesheets({
      domain_id: 1,
    })
      .then((data) => {
        console.log(data)
      })
  }

  const createTimesheet = () => {
    test.submitTimesheet({
      domain_id: 1,
      user_id: 1,
      start: '2015-07-07 10:10:10',
      end: '2015-07-07 10:10:10',
      asset_id: 1,
      workers: [1,2]
    })
      .then((data) => {
        alert(data.message)
        console.log(data)
      })
  }

  const extractStakeholders = () => {
    const currentDate = new Date().toISOString().slice(0, 10) // Get the current date in ISO format (YYYY-MM-DD)
    const filename = `stakeholders_${currentDate}.xlsx`
    const id = prompt('Enter the id of the archangel you want:')
    test.extractStakeholders({
      domain_id: id,
    })
      .then((data) => {
        downloader(data, filename)
      })
  }

  const extractNotes = () => {
    const currentDate = new Date().toISOString().slice(0, 10) // Get the current date in ISO format (YYYY-MM-DD)
    const filename = `notes_${currentDate}.xlsx`
    const id = prompt('Enter the id of the archangel you want:')
    test.extractNotes({
      domain_id: id,
    })
      .then((data) => {
        downloader(data, filename)
      })
  }

  const extractIssues = () => {
    const currentDate = new Date().toISOString().slice(0, 10) // Get the current date in ISO format (YYYY-MM-DD)
    const filename = `issues_${currentDate}.xlsx`
    const id = prompt('Enter the id of the archangel you want:')
    test.extractIssues({
      domain_id: id,
    })
      .then((data) => {
        downloader(data, filename)
      })
  }

  const extractPois = () => {
    const currentDate = new Date().toISOString().slice(0, 10) // Get the current date in ISO format (YYYY-MM-DD)
    const filename = `pois_${currentDate}.xlsx`
    const id = prompt('Enter the id of the archangel you want:')
    test.extractPois({
      domain_id: id,
    })
      .then((data) => {
        downloader(data, filename)
      })
  }

  const extractFlashpoints = () => {
    const currentDate = new Date().toISOString().slice(0, 10) // Get the current date in ISO format (YYYY-MM-DD)
    const filename = `flashpoints_${currentDate}.xlsx`
    const id = prompt('Enter the id of the archangel you want:')
    test.extractFlashpoints({
      domain_id: id,
    })
      .then((data) => {
        downloader(data, filename)
      })
  }

  const extractCommitments = () => {
    const currentDate = new Date().toISOString().slice(0, 10) // Get the current date in ISO format (YYYY-MM-DD)
    const filename = `commitments_${currentDate}.xlsx`
    const id = prompt('Enter the id of the archangel you want:')
    test.extractCommitments({
      domain_id: id,
    })
      .then((data) => {
        downloader(data, filename)
      })
  }

  const extractCommunities = () => {
    const currentDate = new Date().toISOString().slice(0, 10) // Get the current date in ISO format (YYYY-MM-DD)
    const filename = `communities_${currentDate}.xlsx`
    const id = prompt('Enter the id of the archangel you want:')
    test.extractCommunities({
      domain_id: id,
    })
      .then((data) => {
        downloader(data, filename)
      })
  }

  const extractDomains = () => {
    const currentDate = new Date().toISOString().slice(0, 10) // Get the current date in ISO format (YYYY-MM-DD)
    const filename = `domains_${currentDate}.xlsx`
    test.extractDomains()
      .then((data) => {
        downloader(data, filename)
      })
  }



  return (
    <>
      <IonButton onClick={retrieveAllReports} title="All" expand="block">Click to get all reports</IonButton>
      <IonButton onClick={extractDomains} title="Report" expand="block">Click to extract all domains</IonButton>
      <IonButton onClick={killWorkspaceClicked} title="All" expand="block">Click to kill a Workspace</IonButton>
      <IonButton onClick={extractCommunities} title="Report" expand="block">Click to extract communities</IonButton>
      <IonButton onClick={extractCommitments} title="Report" expand="block">Click to extract Commitment forms</IonButton>
      <IonButton onClick={extractFlashpoints} title="Report" expand="block">Click to extract flashpoints</IonButton>
      <IonButton onClick={extractPois} title="Report" expand="block">Click to extract Pois</IonButton>
      <IonButton onClick={extractIssues} title="Report" expand="block">Click to extract Issues</IonButton>
      <IonButton onClick={extractNotes} title="Report" expand="block">Click to extract Notes</IonButton>
      <IonButton onClick={extractStakeholders} title="Report" expand="block">Click to extract Stakeholders</IonButton>
      <IonButton onClick={createTimesheet} title="Report" expand="block">Click to create timesheets</IonButton>
      <IonButton onClick={getTimesheets} title="Report" expand="block">Click to get timesheets</IonButton>
      <IonButton onClick={createIssuePulsepoint} title="Report" expand="block">Click to create issue pulsepoint</IonButton>
      <IonButton onClick={getPulsepoints} title="Report" expand="block">Click to get pulsepoints</IonButton>
      <IonButton onClick={submitPulsepoint} title="Report" expand="block">Click to submit pulsepoint</IonButton>
      <IonButton onClick={anonymiseUsers} title="Report" expand="block">Click to get anonymise users</IonButton>
      <IonButton onClick={getAllCounties} title="Report" expand="block">Click to get all counties</IonButton>
      <IonButton onClick={getAllCountries} title="Report" expand="block">Click to get all countries</IonButton>
      <IonButton onClick={getAllRegions} title="Report" expand="block">Click to get all regions</IonButton>
      <IonButton onClick={getEfficientCounties} title="Report" expand="block">Click to get efficient counties</IonButton>
      <IonButton onClick={getNotis} title="Report" expand="block">Click to get notis</IonButton>
      <IonButton onClick={systemSafeties} title="Report" expand="block">Click to get safeties in last 30 days</IonButton>
      <IonButton onClick={systemPulsepoints} title="Report" expand="block">Click to get pulsepoints in last 30 days</IonButton>
      <IonButton onClick={projectDropdowns} title="Report" expand="block">Click to get project dropdowns</IonButton>
      <IonButton onClick={userStats} title="Report" expand="block">Click to get user stats </IonButton>
      <IonButton onClick={getCampaignStats} title="Report" expand="block">Click to get campaign stats </IonButton>
      <IonButton onClick={sendDM} title="Report" expand="block">Click to send DM </IonButton>
      <IonButton onClick={getLatestDMs} title="Report" expand="block">Click to get latest Dms </IonButton>
      <IonButton onClick={getDMs} title="Report" expand="block">Click to get dms </IonButton>
      <IonButton onClick={getRecipientsLists} title="Report" expand="block">Click to get recipeints list </IonButton>
      <IonButton onClick={getCampaigns} title="Report" expand="block">Click to get Campaigns </IonButton>
      <IonButton onClick={editCommitment} title="Report" expand="block">Click to edit commitment </IonButton>
      <IonButton onClick={editIssue} title="Report" expand="block">Click to edit Issue </IonButton>
      <IonButton onClick={editFlashpoint} title="Report" expand="block">Click to edit Flashpoint </IonButton>
      <IonButton onClick={getStakeholders} title="Report" expand="block">Click to get Stakeholders </IonButton>
      <IonButton onClick={getCommitments} title="Report" expand="block">Click to get Commitments </IonButton>
      <IonButton onClick={getIssues} title="Report" expand="block">Click to get Issues </IonButton>
      <IonButton onClick={getPois} title="Report" expand="block">Click to get Pois </IonButton>
      <IonButton onClick={getCommunities} title="Report" expand="block">Click to get Communities </IonButton>
      <IonButton onClick={getFlashpoints} title="Report" expand="block">Click to get Flashpoints </IonButton>
      <IonButton onClick={getEngagementNotes} title="Report" expand="block">Click to get engagement notes</IonButton>
      <IonButton onClick={createCommunity} title="Report" expand="block">Click to create an community</IonButton>
      <IonButton onClick={createEngagementNote} title="Report" expand="block">Click to create an engagement note</IonButton>
      <IonButton onClick={createStakeholder} title="Report" expand="block">Click to create an stakeholder</IonButton>
      <IonButton onClick={createPoi} title="Report" expand="block">Click to create an poi</IonButton>
      <IonButton onClick={createCommitment} title="Report" expand="block">Click to create an commitment</IonButton>
      <IonButton onClick={createIssue} title="Report" expand="block">Click to create an issue</IonButton>
      <IonButton onClick={createFlashpoint} title="Report" expand="block">Click to create a flashpoint</IonButton>
      <IonButton onClick={getLocationHistory} title="Report" expand="block">Click to get getLocationHistory</IonButton>
      <IonButton onClick={tester} title="Report" expand="block">Click to test whatevr</IonButton>
      <IonButton onClick={MetricByArchangel} title="Report" expand="block">Click to get metric by archangel</IonButton>
      <IonButton onClick={trackerResponse} title="Report" expand="block">Click to test tracker accept</IonButton>
      <IonButton onClick={trackerHit} title="Report" expand="block">Click to test tracker hit</IonButton>
      <IonButton onClick={getUserList} title="Report" expand="block">Click to test user list fetch on box</IonButton>
      <IonButton onClick={createTemplateSop} title="Report" expand="block">Click to create temaplate for sop</IonButton>
      <IonButton onClick={getTemplateSops} title="Report" expand="block">Click to get temaplates for sops</IonButton>
      <IonButton onClick={editProcedures} title="Report" expand="block">Click to add/edit procedures</IonButton>
      <IonButton onClick={deleteProcedure} title="Report" expand="block">Click to delete procedure</IonButton>
      <IonButton onClick={getProcedures} title="Report" expand="block">Click to get procedures</IonButton>
      <IonButton onClick={createSops} title="Report" expand="block">Click to add/create Sop</IonButton>
      <IonButton onClick={getSops} title="Report" expand="block">Click to get Sops</IonButton>
      <IonButton onClick={createInternalIncident} title="Report" expand="block">Click to create internal incident</IonButton>
      <IonButton onClick={setResponseLevels} title="Report" expand="block">Click to set response levels</IonButton>
      <IonButton onClick={getResponseLevels} title="Report" expand="block">Click to get response levels</IonButton>
      <IonButton onClick={setEmergencyTeams} title="Report" expand="block">Click to set emergency teams</IonButton>
      <IonButton onClick={getEmergencyTeams} title="Report" expand="block">Click to get emergency teams</IonButton>
      <IonButton onClick={fetchInternalIncidents} title="Report" expand="block">Click to fetch internal_incidents</IonButton>
      <IonButton onClick={fetchInternalIncident} title="Report" expand="block">Click to fetch internal_incidents</IonButton>
      <IonButton onClick={handleIncidentClicked} title="Report" expand="block">Click to test Incident Report Logging</IonButton>
      <IonButton onClick={handleInsightClicked} title="Report" expand="block">Click to test Insight Report Logging</IonButton>
      <IonButton onClick={retrieveAllReports} title="All" expand="block">Click to get all reports</IonButton>
      <IonButton onClick={incidentsByCountyClicked} title="Report" expand="block">Click to retrieve Incident Reports by county</IonButton>
      <IonButton onClick={retrieveAllCounties} title="All" expand="block">Click to get all Counties</IonButton>
      <IonButton onClick={retrieveAllBoxes} title="All" expand="block">Click to get all Movement boxes</IonButton>
      <IonButton onClick={retrieveAllBoxes} title="All" expand="block">Click to get all Movement boxes that are accessible by a specific domain</IonButton>
      <IonButton onClick={addBoxClicked} title="All" expand="block">Click to add a Movement box</IonButton>
      <IonButton onClick={retrieveAllRoutes} title="All" expand="block">Click to get all Routes</IonButton>
      <IonButton onClick={addRouteClicked} title="All" expand="block">Click to add a Route</IonButton>
      <IonButton onClick={retrieveAllUsers} title="All" expand="block">Click to get all Users</IonButton>
      <IonButton onClick={addTaskClicked} title="All" expand="block">Click to add a Task</IonButton>
      <IonButton onClick={updateStatusClicked} title="All" expand="block">Click to change a Task status</IonButton>
      <IonButton onClick={archiveClicked} title="All" expand="block">Click to archive a Task</IonButton>
      <IonButton onClick={retrieveSomeTasks} title="All" expand="block">Click to get specific Tasks</IonButton>
      <IonButton onClick={retrieveArchivedTasks} title="All" expand="block">Click to get specific Archived Tasks</IonButton>
      <IonButton onClick={editTaskClicked} title="All" expand="block">Click to edit a Task</IonButton>
      <IonButton onClick={getActiveJourneys} title="All" expand="block">Click to get all active Journeys</IonButton>
      <IonButton onClick={requestJourneyClicked} title="All" expand="block">Click to request a Journey</IonButton>
      <IonButton onClick={progressJourneyClicked} title="All" expand="block">Click to progress a Task</IonButton>
      <IonButton onClick={denyJourneyClicked} title="All" expand="block">Click to deny a Task</IonButton>
      <IonButton onClick={commentJourneyClicked} title="All" expand="block">Click to comment on a Task</IonButton>
      <IonButton onClick={getRolesClicked} title="All" expand="block">Click to get the Workspace Role data for a User</IonButton>
      <IonButton onClick={tryWorkspaceClicked} title="All" expand="block">Click to build a new Workspace</IonButton>
      <IonButton onClick={editUsers} title='All' expand='block'>Click to edit user's data</IonButton>
      <IonButton onClick={changePassword} title='All' expand='block'>Click to change the user's password</IonButton>
      <IonButton onClick={editWorkspace} title='All' expand='block'>Click to edit the workspace</IonButton>
      <IonButton onClick={inviteUsersClicked} title="All" expand="block">Click to invite Users into a Workspace</IonButton>
      <IonButton onClick={processInvitationClicked} title="All" expand="block">Click to process an invitation to a Workspace</IonButton>
      <IonButton onClick={promoteUsersClicked} title="All" expand="block">Click to promote Users within a Workspace</IonButton>
      <IonButton onClick={demoteUsersClicked} title="All" expand="block">Click to demote Users within a Workspace</IonButton>
      <IonButton onClick={banishUsersClicked} title="All" expand="block">Click to banish Users from a Workspace</IonButton>
      <IonButton onClick={anointUserClicked} title="All" expand="block">Click to anoint a specific role from a Workspace</IonButton>
      <IonButton onClick={incarnateSelfClicked} title="All" expand="block">Click to incarnate yourself within a Workspace</IonButton>
      <IonButton onClick={getWorkspacesClicked} title="All" expand="block">Click to get all of the current User's Workspace information</IonButton>
      <IonButton onClick={retrieveOneWorkspace} title="All" expand="block">Click to get one Workspace's information</IonButton>
      <IonButton onClick={editJourney} title="All" expand="block">Click to edit Journey</IonButton>
      <IonButton onClick={editIncident} title="All" expand="block">Click to edit Incident</IonButton>
      <IonButton onClick={editAlertState} title="All" expand="block">Click to edit Alert State</IonButton>
      <IonButton onClick={editMovementBox} title="All" expand="block">Click to edit a movement box</IonButton>
      <IonButton onClick={archiveMovementBox} title="All" expand="block">Click to archive a movement box</IonButton>
      <IonButton onClick={get_avg_alert} title="All" expand="block">Click to get the average alert states</IonButton>
      <IonButton onClick={resetPasswordClicked} title="All" expand="block">Click to reset the password of a User</IonButton>
      <IonButton onClick={incidentsPerArchangel} title="All" expand="block">Click to obtain incidents per archangel</IonButton>
      <IonButton onClick={insightsPerArchangel} title="All" expand="block">Click to obtain insights per archangel</IonButton>
      <IonButton onClick={totalArchangels} title="All" expand="block">Click to obtain total archangels</IonButton>
      <IonButton onClick={totalUsers} title="All" expand="block">Click to obtain total users</IonButton>
      <IonButton onClick={addMetricsClicked} title="All" expand="block">Click to add a Metric</IonButton>
      <IonButton onClick={archiveUserMetric} title="All" expand="block">Click to archive a user metric</IonButton>
      <IonButton onClick={archiveMetric} title="All" expand="block">Click to archive a metric</IonButton>
      <IonButton onClick={editMetric} title="All" expand="block">Click to edit a metric</IonButton>
      <IonButton onClick={editUserMetric} title="All" expand="block">Click to edit a user metric</IonButton>
      <IonButton onClick={createUserMetric} title="All" expand="block">Click to create a user metric</IonButton>
      <IonButton onClick={getMetricDetailed} title="All" expand="block">Click to get detailed metric info</IonButton>
      <IonButton onClick={getMetrics} title="All" expand="block">Click to retrieve project metrics</IonButton>
      <IonButton onClick={getIrisMetrics} title="All" expand="block">Click to retrieve Iris metrics</IonButton>
      <IonButton onClick={getUserMetrics} title="All" expand="block">Click to retrieve all user metrics</IonButton>
      <IonButton onClick={deleteIncident} title="All" expand="block">Click to delete an Incident</IonButton>
      <IonButton onClick={addProjectClicked} title="All" expand="block">Click to make a new Project</IonButton>
      <IonButton onClick={getProjectsClicked} title="All" expand="block">Click to retrieve active Projects</IonButton>
      <IonButton onClick={projectDetailsClicked} title="All" expand="block">Click to retrieve a specific Project's details</IonButton>
      <IonButton onClick={makeObjectiveClicked} title="All" expand="block">Click to make a new Objective for a specfic Project</IonButton>
      <IonButton onClick={abortObjectiveClicked} title="All" expand="block">Click to abort a specific Objective</IonButton>
      <IonButton onClick={progressObjectiveClicked} title="All" expand="block">Click to progress a specific Objective</IonButton>
      <IonButton onClick={regressObjectiveClicked} title="All" expand="block">Click to regress a specific Objective</IonButton>
      <IonButton onClick={abortProjectClicked} title="All" expand="block">Click to abort a specific Project</IonButton>
      <IonButton onClick={progressProjectClicked} title="All" expand="block">Click to progress a specific Project</IonButton>
      <IonButton onClick={regressProjectClicked} title="All" expand="block">Click to regress a specific Project</IonButton>
      <IonButton onClick={addTaskToObjective} title="All" expand="block">Click to add one task to a specific Objective</IonButton>
      <IonButton onClick={createMessage} title="All" expand="block">Click to create a message</IonButton>
      <IonButton onClick={createRoom} title="All" expand="block">Click to create a Room</IonButton>
      <IonButton onClick={getRooms} title="All" expand="block">Click to get Rooms for current user</IonButton>
      <IonButton onClick={getMessages} title="All" expand="block">Click to get Messages for given room</IonButton>
      <IonButton onClick={editProjectClicked} title="All" expand="block">Click to edit a Project.</IonButton>
      <IonButton onClick={editObjectiveClicked} title="All" expand="block">Click to edit some Objectives.</IonButton>
      <IonButton onClick={createAsset} title="All" expand="block">Click to create an Asset</IonButton>
      <IonButton onClick={editAsset} title="All" expand="block">Click to edit an Asset</IonButton>
      <IonButton onClick={createAssetRisk} title="All" expand="block">Click to create an AssetRisk</IonButton>
      <IonButton onClick={createRisk} title="All" expand="block">Click to create a Risk</IonButton>
      <IonButton onClick={createMitigation} title="All" expand="block">Click to create a Mitigation</IonButton>
      <IonButton onClick={editAssetRisk} title="All" expand="block">Click to edit AssetRisk</IonButton>
      <IonButton onClick={addControlMeasure} title="All" expand="block">Click to add a Control Measure</IonButton>
      <IonButton onClick={deprecateControlMeasure} title="All" expand="block">Click to remove a Control Measure(s)</IonButton>
      <IonButton onClick={removeMitigation} title="All" expand="block">Click to remove a Mitigation</IonButton>
      <IonButton onClick={getFormMeasures} title="All" expand="block">Click to get Form Measures</IonButton>
      <IonButton onClick={getAllAssets} title="All" expand="block">Click to get all the assets for your Domain.</IonButton>
      <IonButton onClick={getAllAssetsByCounty} title="All" expand="block">Click to get all the assets by county.</IonButton>
      <IonButton onClick={getSpecificAsset} title="All" expand="block">Click to get one Asset.</IonButton>
      <IonButton onClick={getMitigations} title="All" expand="block">Click to get all mitigations for a given assetRisk.</IonButton>
      <IonButton onClick={getRiskTypes} title="All" expand="block">Click to get all types of risk visible from a given domain.</IonButton>
      <IonButton onClick={getAssetTypes} title="All" expand="block">Click to get all types asset.</IonButton>
      <IonButton onClick={archiveAssetsClicked} title="All" expand="block">click to archive some Assets.</IonButton>
      <IonButton onClick={archiveAssetRisksClicked} title="All" expand="block">Click to archive some Assets.</IonButton>
      <IonButton onClick={getAllAssetDetails} title="All" expand="block">Click to get all details for one Asset.</IonButton>
      <IonButton onClick={getInterestsClicked} title="All" expand="block">Click to get all Interests for one Domain.</IonButton>
      <IonButton onClick={declareInterestsClicked} title="All" expand="block">Click to Declare specific Interests to a Domain.</IonButton>
      <IonButton onClick={denounceInterestsClicked} title="All" expand="block">Click to Denounce specific Interests for one Domain.</IonButton>
      <IonButton onClick={archivePathClicked} title="All" expand="block">Click to archive a Path</IonButton>
      <IonButton onClick={help} title="All" expand="block">Click to submit help.</IonButton>
      <IonButton onClick={downloadAssets} title="All" expand="block">Click to download assets.</IonButton>
      <IonButton onClick={downloadRisks} title="All" expand="block">Click to download Risks.</IonButton>
      <IonButton onClick={getDomainSafeties} title="All" expand="block">Click to get all the most recent safety checks for one Domain.</IonButton>
      <IonButton onClick={promptDomainSafeties} title="All" expand="block">Click prompt safety checks for everyone on a single DOomain.</IonButton>
      <IonButton onClick={submitSafetyCheck} title="All" expand="block">Click submit a single safety check.</IonButton>
      <IonButton onClick={promptSafetyCheck} title="All" expand="block">Click to prompt a single safety Check.</IonButton>
      <IonButton onClick={downloadSecurityChange} title="All" expand="block">Click to download security change pdf.</IonButton>
      <IonButton onClick={securityPopup} title="All" expand="block">Click to show security popup data.</IonButton>
      <IonButton onClick={createBreakout} title="All" expand="block">Click to create a Breakout Room</IonButton>
      <IonButton onClick={getBreakouts} title="All" expand="block">Click to get Breakout Rooms</IonButton>
      <IonButton onClick={editBreakout} title="All" expand="block">Click to edit Breakout Room</IonButton>
      <IonButton onClick={leaveBreakout} title="All" expand="block">Click to leave Breakout Room</IonButton>
      <IonButton onClick={changeHost} title="All" expand="block">Click to chnage Breakout Room host</IonButton>
      <IonButton onClick={leaveChat} title="All" expand="block">Click to leave Chat</IonButton>
      <IonButton onClick={deleteObjectives} title="All" expand="block">Click to delete objectives.</IonButton>
      <IonButton onClick={getLunarMemories} title="All" expand="block">Get user monthly data.</IonButton>
      <IonButton onClick={situationReport} title="All" expand="block">Get situation report data.</IonButton>
      <IonButton onClick={getAllKeyphrases} title="All" expand="block">Click to get all Keyphrases.</IonButton>
      <IonButton onClick={getAllAnalyses} title="All" expand="block">Click to get all Analyses which haven't yet impaced a StateChange.</IonButton>
      <IonButton onClick={submitSomeAnalyses} title="All" expand="block">Click to submit one or more analyses.</IonButton>
      <IonButton onClick={getMyAnalyses} title="All" expand="block">Click to get all of YOUR Analyses which haven't yet impaced a StateChange.</IonButton>
      <IonButton onClick={getIncidentsForDomain} title="All" expand="block">Get domains incidents</IonButton>
      <IonButton onClick={ReportsIncidentPopup} title="All" expand="block">Get reports incident popup data</IonButton>
      <IonButton onClick={getInsightsForDomain} title="All" expand="block">Get domains insights</IonButton>
      <IonButton onClick={dashboardInsights} title="All" expand="block">Get dashboard insights</IonButton>
      
      <IonButton onClick={dashboardJourneys} title="All" expand="block">Get journeys dashboard insights</IonButton>
      <IonButton onClick={dashboardEngagements} title="All" expand="block">Get engagement dashboard insights</IonButton>
      <IonButton onClick={weeklyReport} title="All" expand="block">Get weekly Report</IonButton>
      <IonButton onClick={weeklyReportPDF} title="All" expand="block">Get weekly Report PDF</IonButton>

    </>
  )
}

export default IncidentReportSubmitterTest
/* eslint-enable */
