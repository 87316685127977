import React, { useEffect, useRef, useState } from 'react'
import mapboxgl from '!mapbox-gl'
import styled from 'styled-components'
import 'mapbox-gl/dist/mapbox-gl.css'
import { useWorkspace } from '../../hooks/useWorkspace'
import { centerMapAt, zoomInMap } from './MapCenter'
import { useTerrainMapping } from '../../hooks/terrain-mapping/useTerrainMapping'
import { createCommunityLayer, createPOILayers, displayCommunityPopup } from '../../hooks/terrain-mapping/helpers/MapUtils'
import { FocusedActionKind } from '../../hooks/terrain-mapping/helpers/StateReducers'

const MapContainer = styled.div`
  width: 100%;
  position: relative;
	height: 97%;
  z-index: 2;
  border-radius: 5px;
	box-shadow: 0px 4px 4px 0px #00000040;
`

/**
 *  ==============================
 *       CREATES A RAW MAP
 *  ==============================
 * 
 *  Raw map for each app view -> recieves a form
 */
 const Map = ({ children, mapRef, listeners, style={}, zoom = 7.14, className = 'map-container' }) => {

	/* State variables */
	const mapContainer = useRef(null)
	const [lng, setLng] = useState(30.1624)
	const [lat, setLat] = useState(7.1442)
	const { workspace, mapView } = useWorkspace()
	const { poiList, communities, flashpoints, stakeholders, engagementNotes, issues, dispatchFocused, } = useTerrainMapping()

	const handleCommunityClick = (clickedArea, event) => {

		if (!event.features || event.features.length === 0) { return }

		const id = clickedArea.id
		const area = communities.find((val) => val.id === id)
		if (!area) { return }

		const hasFlashpoints = flashpoints.some(({ relevant_areas }) => relevant_areas.find((val) => val.id === id))
		const hasStakeholders = stakeholders.some((val) => val.areas.find((community) => community.id === id))
		console.log(engagementNotes)
		const hasEngagements = engagementNotes.some(({ community }) => community && community.id === id)
		const hasIssues = issues.some((issue) => issue.communities.find((val) => id === val.id))
		displayCommunityPopup(
		  area,
		  mapRef,
		  event.lngLat,
		  hasStakeholders,
		  hasFlashpoints,
		  hasEngagements,
		  hasIssues,
		  () => dispatchFocused({ type: FocusedActionKind.SELECT_COMMUNITY, community: area }),
		)		
	  }
	  const handleCommunityClickRef = useRef(null)
	  handleCommunityClickRef.current = handleCommunityClick

	useEffect(() => {

		if (mapRef.current) return
		// I will keep this in for now, but will delete the key once we are environment variables up and running
		mapboxgl.accessToken = 'pk.eyJ1IjoidHJ1YnNoYXciLCJhIjoiY2pqaW40cTB5M3Y1NDNrbnpuazI0anVpbSJ9.3xeZWmfW_MwzRTHUR9hIYQ'
		
		const zoom = zoomInMap(workspace)
		const coordinates = centerMapAt(workspace)

		mapRef.current = new mapboxgl.Map({
			container: mapContainer.current,
			style: 'mapbox://styles/mapbox/streets-v11',
			center: coordinates,
			zoom: zoom
		});

		mapRef.current.addControl(new mapboxgl.FullscreenControl(), 'bottom-left');
		mapRef.current.addControl(new mapboxgl.GeolocateControl({
			positionOptions: {
				enableHighAccuracy: true
			},
			trackUserLocation: true
		}), 'bottom-left');
		mapRef.current.addControl(new mapboxgl.NavigationControl(), 'bottom-left')

		/* Set up parent's functionality on this map */
		listeners()
	})

	useEffect(() => {
		if (mapRef.current && mapView ==='satellite') {
	  
		  mapRef.current.setStyle('mapbox://styles/mapbox/satellite-streets-v11');
	  
		  mapRef.current.once('style.load', () => {
			const polygonFeatures = [];

			// Loop through the communities and add their polygons to the FeatureCollection
			communities?.forEach((community) => {
			if (community?.geoData && community.geoData?.geometry?.type === 'Polygon') {
				polygonFeatures.push({
				type: 'Feature',
				properties: {},
				geometry: community?.geoData?.geometry,
				id: community.id
				})
			}
			})
			createPOILayers(mapRef, poiList)
			createCommunityLayer(mapRef, polygonFeatures)
			mapRef.current.on('click', 'community-area-layers', (e) => {
				if (e.originalEvent.defaultPrevented) { return }
			  
				const clickedArea = e.features && e.features[0]
				if (!clickedArea) { return; }
			  
				handleCommunityClick(clickedArea, e)
			  })			  
			console.log('commun', communities)
			console.log('pois', poiList)
			console.log('Map style changed to satellite-streets-v11');
			listeners()
		  })
		}
		if (mapRef.current && mapView ==='map') {
		
			mapRef.current.setStyle('mapbox://styles/mapbox/streets-v11');
		
			mapRef.current.once('style.load', () => {
			  const polygonFeatures = [];
  
			  // Loop through the communities and add their polygons to the FeatureCollection
			  communities?.forEach((community) => {
			  if (community?.geoData && community?.geoData?.geometry?.type === 'Polygon') {
				  polygonFeatures.push({
				  type: 'Feature',
				  properties: {},
				  geometry: community?.geoData?.geometry,
				  id: community.id
				  })
			  }
			  })
			  createPOILayers(mapRef, poiList)
			  createCommunityLayer(mapRef, polygonFeatures)
			  mapRef.current.on('click', 'community-area-layers', (e) => {
				if (e.originalEvent.defaultPrevented) { return }
			  
				const clickedArea = e.features && e.features[0]
				if (!clickedArea) { return; }
			  
				handleCommunityClick(clickedArea, e)
			  })
			  console.log('commun', communities)
			  console.log('pois', poiList)
			  console.log('Map style changed to satellite-streets-v11');
			  listeners()
			})
		  }
	  }, [mapView])

	return (
		<>
			<MapContainer style={style} ref={mapContainer} className={className}>
				{
					children
				}
			</MapContainer>
		</>
	)
}

export default Map
