import React from 'react'
import { IncidentCause, User } from '../../../components/types/GlobalTypes'
import {
  Commitment,
  Community, CountryAreas, EngagementNote, Flashpoint, Stakeholder, StakeholderGroup, TerrainIssue, TerrainPOI,
} from '../../../components/types/OptimizedMaps'
import { Filters, FilterUpdate, FocusedElemAction, FocusedElemState } from '../helpers/StateReducers'

export enum AppView {
  TERRAIN_MAP = 'Terrain Map',
  REGISTERS = 'Registers',
  NOTES = 'Notes',
  ENGAGEMENT_PLAN = 'Engagement Plan',
}

export enum TerrainMapElem {
  COMMUNITY = 'Community',
  POINT_OF_INTEREST = 'Point of Interest',
  FLASHPOINT = 'Flashpoint',
  STAKEHOLDER = 'Stakeholder',
  ENGAGEMENT_NOTE = 'Engagement Note',
  ISSUE = 'Issue',
  COMMITMENT_FORM = 'Commitment Form',
}

export interface HookInterface {
  view: AppView,
  loading: boolean,
  setLoading: React.Dispatch<React.SetStateAction<boolean>>,
  handleViewChange: (newView: AppView) => void,
  mapRef: React.RefObject<mapboxgl.Map> | null,
  fetchAppData: () => void,
  areas: CountryAreas[],
  submittedMessage: null | string,
  setSubmittedMessage: React.Dispatch<React.SetStateAction<null | string>>,
  showAddPopup: boolean,
  setShowAddPopup: React.Dispatch<React.SetStateAction<boolean>>,
  handleAddToMap: (option: TerrainMapElem) => void,
  setShowCommunityForm: React.Dispatch<React.SetStateAction<boolean>>,
  showCommunityForm: boolean,
  pushNewCommunity: (newArea: Community, records: Stakeholder[]) => void,
  showPOIForm: boolean,
  setShowPOIForm: React.Dispatch<React.SetStateAction<boolean>>,
  POITypes: string[],
  pushNewPOI: (newPOI: TerrainPOI) => void,
  showFlashpointForm: boolean,
  setShowFlashpointForm: React.Dispatch<React.SetStateAction<boolean>>,
  causes: IncidentCause[],
  communities: Community[],
  flashpoints: Flashpoint[],
  pushNewFlashpoint: (newFlashpoint: Flashpoint) => void,
  registerDisplay: TerrainMapElem,
  setRegisterDisplay: React.Dispatch<React.SetStateAction<TerrainMapElem>>,
  focusedElems: FocusedElemState,
  dispatchFocused: React.Dispatch<FocusedElemAction>,
  removeFlashpoint: (flashpoint: Flashpoint) => void,
  poiList: TerrainPOI[],
  showStakeholderForm: boolean,
  setShowStakeholderForm: React.Dispatch<React.SetStateAction<boolean>>,
  stakeholders: Stakeholder[],
  pushNewStakeholder: (newStakeholder: Stakeholder) => void,
  updatePOI: (updated: TerrainPOI) => void,
  deletePOI: (poi: TerrainPOI) => void,
  users: User[],
  showEngagementForm: boolean,
  setShowEngagementForm: React.Dispatch<React.SetStateAction<boolean>>,
  engagementNotes: EngagementNote[],
  pushNewEngagementNote: (newNote: EngagementNote) => void,
  showIssueForm: boolean,
  setShowIssueForm: React.Dispatch<React.SetStateAction<boolean>>,
  issues: TerrainIssue[],
  pushNewIssue: (newIssue: TerrainIssue) => void,
  showCommitmentForm: boolean,
  setShowCommitmentForm: React.Dispatch<React.SetStateAction<boolean>>,
  pushNewCommitment: (newCommitment: Commitment) => void,
  commitments: Commitment[],
  stakeholderGroups: StakeholderGroup[],
  editing: boolean,
  setEditing: React.Dispatch<React.SetStateAction<boolean>>,
  updateCommunity: (edited: Community, records: Stakeholder[]) => void,
  saving: boolean,
  setSaving: React.Dispatch<React.SetStateAction<boolean>>,
  updateEngagementNote: (updated: EngagementNote) => void,
  updateFlashpoint: (flashpoint: Flashpoint) => void,
  updateIssue: (updated: TerrainIssue) => void,
  updateCommitment: (commitment: Commitment) => void,
  updateStakeholder: (updated: Stakeholder) => void,
  manifestations: string[],
  showFilePopup: boolean,
  setShowFilePopup: React.Dispatch<React.SetStateAction<boolean>>,
  files: FileList | null,
  setFiles: React.Dispatch<FileList>,
  imported: any | null,
  setImported: React.Dispatch<any>,
  stakeholderFilters: any | null,
  setStakeholderFilters: React.Dispatch<any>,
  mapFilters: any | null,
  setMapFilters: React.Dispatch<any>,
  filteredStakeholders: any[] | null,
  setFilteredStakeholders: React.Dispatch<any[]>,
  assets: any[] | null,
  showFilterPopup: boolean,
  setShowFilterPopup: React.Dispatch<boolean>,
  showMapFilterPopup: boolean,
  setShowMapFilterPopup: React.Dispatch<boolean>,
  updateFilters: (option: FilterUpdate) => void,
  filters: Filters,
  setFilters: React.Dispatch<React.SetStateAction<Filters>>,
  fromCommunityAdd: any,
  setFromCommunityAdd: React.Dispatch<React.SetStateAction<any>>,
}
