import * as React from 'react'
import InsightsMap from '../maps/apps/InsightsMap2'
import MapControls from './MapControls'
import SitRepHandler from '../popups/SitRepHandler'
import { useSituationReport } from '../../hooks/insights/useSituationReport'
import { useInsightsData } from '../../hooks/insights/useInsightsData'
import { ObscureBackground } from '../GlobalContainers'
import TeamRequest from './safety-checks/TeamRequest'
import FormPopup from '../maps/forms/FormPopup'
import { CenterForm } from '../riskregister/StyledContainers'
import LoadingModal from '../modals/LoadingModal'
import FSitRepRequest from './popups-2.0/FSitRepRequest'

const AppWrapper : React.FC = () => {
  const [isShowing] = useSituationReport()
  const {
    showSafetyChecks, submittedMessage, setSubmittedMessage, loading, showFieldSitrepRequest,
  } = useInsightsData()

  return (
    <>
      <LoadingModal isOpen={loading} />
      <MapControls />
      <InsightsMap />
      {
        isShowing && (
          <SitRepHandler />
        )
      }
      {
        showFieldSitrepRequest && (
          <>
            <ObscureBackground style={{ zIndex: '10' }} />
            <FSitRepRequest />
          </>
        )
      }
      {
        showSafetyChecks && (
          <>
            <ObscureBackground style={{ zIndex: '10' }} />
            <TeamRequest />
          </>
        )
      }
      {
        submittedMessage && (
          <>
            <ObscureBackground style={{ zIndex: '10' }} />
            <CenterForm style={{ height: 'auto', width: '30%' }}>
              <FormPopup message={submittedMessage} onClose={() => setSubmittedMessage(null)} />
            </CenterForm>
          </>
        )
      }
    </>
  )
}

export default AppWrapper
